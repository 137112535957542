#free-credits-page {
  .page-header {
    background-color: #eef7ee;
    padding: 0 41px 0 41px;

    h1 {
      padding-top: 66px;
      margin-bottom: 46px;
      .type-regular();
      font-size: 26px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: normal;
      color: #000000;
      position: relative;

      img {
        position: absolute;
        width: 151px;
        height: 114px;
        right: 0px;
        top: 34px;
      }
    }

    .ant-menu-horizontal {
      background-color: transparent;
      border-bottom: none;

      .ant-menu-item {
        width: 93px;
        margin-right: 38px;
        .type-regular();
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;
        padding: 0px;
        text-align: left;
        padding-bottom: 25px;
        border-bottom: none;
        text-align: center;
        &:before {
          position: absolute;
          content: '';
          width: 31px;
          height: 2px;
          background-color: transparent;
          bottom: 0;
          left: 50%;
          transition: background-color 0.3s ease;
        }

        &-selected {
          font-weight: bold;
          a {
            color: #000;
          }

          &:before {
            position: absolute;
            content: '';
            width: 31px;
            height: 2px;
            background-color: orange;
            bottom: 0;
            left: 29px;
          }
        }
      }
    }
  }
}

#free-credits-reviews-page {
  margin-bottom: 40px;

  .review-header {
    h2 {
      padding-top: 71px;
      margin-bottom: 28px;
      position: relative;
      font-style: italic;
      font-size: 24px;
      line-height: 1.25;

      small {
        font-size: 18px;
        font-style: normal;
      }

      img {
        position: absolute;
        width: 164px;
        height: 37px;
        top: 74px;
        right: 0;
      }
    }
  }

  .review {
    padding-bottom: 58px;
    border-bottom: 1px solid #e8e8e8;

    &.g2-crowd {
      h2 {
        img {
          width: 145px;
          height: 80px;
          top: 60px;
        }
      }
    }

    &.approved {
      .success-icon {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
      }

      .review-link,
      .review-status {
        margin-left: calc(60px + 20px);
      }
    }
  }

  .review-content {
    h3 {
      font-size: 13px;
      margin-bottom: 9px;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      color: #000000;
      width: 484px;
    }

    .review-url {
      position: relative;

      p {
        height: 84px;
        margin-bottom: 10px;
      }

      .ant-btn {
        padding-top: 5px;
        .type-firme-iblack();
        position: absolute;
        right: 4px;
        text-transform: uppercase;
        font-size: 13px;
        color: #000;
        height: 40px;
        width: 145px;
        border-radius: 2px;
        top: 25px;
      }
    }

    .review-form {
      p {
        height: 29px;
        margin-bottom: 23px;
      }
    }
    .review-input {
      height: 51px;
      border-radius: 2px;
      border: solid 1px #a9b0b9;
      position: relative;
      margin-bottom: 26px;

      input.ant-input {
        padding-left: 15px;
        border: none;
        height: 49px;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          color: #4e4e4e;
        }
      }

      .ant-btn {
        .type-firme-iblack();
        position: absolute;
        right: 4px;
        text-transform: uppercase;
        font-size: 13px;
        color: #000;
        height: 40px;
        width: 181px;
        border-radius: 2px;
        top: 4px;
      }
    }

    .review-link,
    .review-status {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      color: #000000;
    }
    .review-status {
      height: 14px;
      &.pending {
        color: #ffb917;
      }
      &.rejected {
        color: #ff1717;
      }

      &.approved {
        color: #00b628;
      }
    }
  }
}

#refer-brand-page {
  .tab-header {
    h1 {
      position: relative;
      padding-top: 71px;
      .type-regular();
      font-size: 24px;
      font-weight: 800;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.25;
      letter-spacing: normal;
      color: #000000;

      small {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;
      }

      img {
        position: absolute;
        right: 0px;
        top: 23px;

        width: 101px;
        height: 100.8px;
      }
    }
  }
  .tab-content {
    padding-top: 36px;

    h2 {
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: normal;
      color: #000000;
    }

    p {
      .type-regular();
      width: 564px;
      height: 84px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 17px;
    }

    .ant-form-item-label {
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 14px;
    }

    .ant-input {
      width: 471px;
      height: 50px;
      border-color: #a9b0b9;
      border-radius: 2px;
      padding-left: 15px;
      &::placeholder {
        color: #4e4e4e;
      }
    }

    .ant-btn {
      .type-firme-iblack();
      right: 4px;
      text-transform: uppercase;
      font-size: 13px;
      color: #000;
      height: 40px;
      width: 181px;
      border-radius: 2px;
      line-height: 1;
    }

    .referrals {
      margin-top: 40px;
      .referral {
        margin-bottom: 27px;
        &-icon {
          display: inline-block;
          vertical-align: top;
          margin-right: 15px;
          img {
            width: 60px;
            height: 60px;
          }
        }
        &-details {
          display: inline-block;
          vertical-align: top;
          padding-top: 2px;
          &-brand {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.33;
            letter-spacing: normal;
            color: #000000;
          }

          &-status {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #00b628;

            &.pending {
              color: #ffb917;
            }
            &.rejected {
              color: #ff1717;
            }

            &.approved {
              color: #00b628;
            }
          }
        }
      }
    }
  }
}
