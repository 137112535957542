#mobile-view-page {
  color: #fff;
  .image {
    margin-top: 93px;
    margin-bottom: 36px;
  }
  h1 {
    color: #fff;
    font-size: 22px;
    .text-center();

    .sub-header {
      margin-top: 13px;
      font-size: 16px;
      .type-sfpro-regular();
      font-weight: normal;
      color: #999;
    }
  }
}

#mobile-view-session-page {
  #mobile-view-page();
  background: url('../../images/Sand@2x.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
}
