
.blur-bg {
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 0px;
        position: fixed;
        overflow: auto;
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.8) !important;
}
