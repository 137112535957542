.post-card.ant-card {
  .ant-card-cover {
    > * {
      height: 100%;
    }
  }
  .ant-card-body {
    .ant-card-meta-title {
      .size-16px();
      .text-black();
      .type-sbold();
      .size-16px();
      .m-0();
    }
  }
}

.trend-post-card {
  .ant-card-body {
    height: 126px;
  }
}

.post-card-video,
.post-card-image {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;

  .post-type-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    .post-type-label {
      display: inline-block;
      width: 59px;
      height: 15px;
      padding-top: 2px;
      text-transform: uppercase;
      font-family: FirmeBlackItalic;
      font-size: 8px;
      font-weight: 900;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: 0.4px;
      text-align: center;
      color: #000000;

      &.type-image {
        background: #ffd982;
      }

      &.type-video {
        background: #4df1ff;
      }

      &.type-story {
        background: #66bdff;
      }
    }
  }

  &-actions {
    position: absolute;
    align-self: center;
    width: 100%;
    text-align: center;
    z-index: 4;

    &-button {
      display: inline-block;
      font-size: 80px;
      color: #fff;
      opacity: 1;
    }
  }

  &:hover {
    .post-card-video-actions {
      &-button {
        opacity: 1;
      }
    }
  }
}

.post-card-image {
  position: relative;
  a,
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    width: 100%;
    height: 100%;
  }

  .post-gallery-actions {
    button {
      position: absolute;
      top: 144px;
      width: 32px;
      height: 32px;
      padding: 0px;
      margin: 0px;
      font-size: 32px;
      border: none;

      &.action-next {
        right: 10px;
      }

      &.action-previous {
        left: 10px;
      }

      .anticon {
        display: block;
      }
    }
  }
}
