.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: @color-black !important;
  .box-border {
    width: 36px;
    height: 36px;
    margin-right: 9px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
    border: solid 1px #3c3c3c;
    background-color: #000000;
    line-height: 1.9;
  }
  .sub-menu-item {
    span {
      vertical-align: middle;
    }

    .icon-posts {
      background-color: #151515;

      svg {
        margin: 6px 15px 15px 6px !important;
      }
    }
  }
}

.ant-layout {
  height: 100vh;
}
