.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.19) !important;
}

.trend-modal {
  .ant-modal {
    &-body {
      padding: 26px;
    }

    &-footer {
      padding: 24px 26px;
      border: 0;
    }
  }

  .ant-radio-wrapper {
    .mb-2();
    color: @color-black;
  }

  .ant-radio {
    .mr-1();
  }

  textarea {
    padding: 16px;
    border: 1px solid @color-gray-lighter !important;
    .leading-18px();
    .size-12px();
    color: @color-pigeon-dark !important;
  }

  .label {
    font-size: 18px;
    color: @color-black;
    font-weight: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    font-family: truenoextrabold;

    .highlighted {
      color: @color-apple-blossom;
    }
  }
}

.pending-review-modal {
  .ant-modal-content {
    width: 460px;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 0;

    .ant-modal-body {
      min-height: 686px;
      margin: 0px;
      padding: 0px;
      padding-bottom: 21px;
    }

    .review-photo {
      text-align: center;
      padding-top: 40px;
      position: relative;

      img {
        display: inline-block;
        width: 200px;
        height: 200px;
        box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.44);
        background-color: #d8d8d8;
        object-fit: cover;
      }

      .instagram-logo {
        position: absolute;
        top: 16px;
        right: 111px;
        background-image: url('../../images/instagram-logo@3x.png');
        background-size: cover;
        height: 43px;
        width: 43px;
      }

      .emoji-heart {
        position: absolute;
        top: 138px;
        left: 107px;
        background-size: cover;
        background-image: url('../../images/heart-eyes-emoji@3x.png');
        height: 44px;
        width: 44px;
      }
      .link {
        position: absolute;
        top: 217px;
        left: 175px;
        width: 110px;
        height: 45px;

        span {
          margin-top: 15px;
          font-family: 'FirmeBlackItalic';
          font-size: 12px;
          font-stretch: normal;
          line-height: 1.08;
          letter-spacing: 0.6px;
          text-align: center;
          color: #000000;
        }
      }
    }
    .review-message {
      margin-top: 45px;
      text-align: center;
      font-family: 'TruenoExtraBold';
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #000000;

      p {
        display: inline-block;
        width: 279px;
      }
    }

    .review-form {
      margin-bottom: 24.8px;

      .review-input {
        .ant-form-item-control {
          display: flex;
          justify-content: center;
          .ant-form-explain {
            text-align: center;
            position: absolute;
            top: -10px;
            font-size: 10px;
          }
        }

        &.ant-form-item {
          margin-bottom: 17.8px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-label {
          display: block;
          text-align: center;
          font-family: 'TruenoSemiBold';
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.77;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
          padding-bottom: 6px;
        }

        &-control {
          text-align: center;
          .ant-rate {
            .ant-rate-star {
              .anticon {
                width: 38.2px;
                height: 38.2px;
                font-size: 38.2px;
              }

              &.ant-rate-star-full {
                .anticon {
                  svg {
                    path {
                      fill: #ffb917;
                      stroke: #ffb917;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .review-additional-comments {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control {
        display: flex;
        justify-content: center;
        .ant-form-explain {
          text-align: center;
          position: absolute;
          top: -17px;
          font-size: 10px;
        }
      }

      textarea {
        padding: 13px 18px;
        resize: none;
        width: 371px;
        height: 99px;
        object-fit: contain;
        border-radius: 2px;
        border: solid 1px #d6d6d6;
        background-color: #ffffff;
        font-family: 'TruenoRegular';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #5b6572;
      }
    }

    .review-footer {
      text-align: center;
      button {
        border-radius: 4px;
        background-color: #ffb917;
        border: none;
        font-weight: 900;
        width: 177.5px;
        height: 45px;
        font-family: 'FirmeBlackItalic';
        font-size: 12px;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.08;
        letter-spacing: 0.6px;
        text-align: center;
        color: #000000;
        text-shadow: none;
      }
    }
  }
}

.yotpo-modal {
  .ant-modal-body {
    padding: 42px 45px 38px;
  }
  .ant-modal-content {
    width: 423px;
    min-height: 504px;
    border-radius: 0;
  }

  &.sent {
    .ant-modal-body {
      padding: 63px 45px 43px;
    }
    .ant-modal-content {
      min-height: 423px;
    }
    .logos {
      margin-bottom: 39px;
    }

    .ant-form-item.submit {
      margin-bottom: 0;
    }
  }

  &.transfer {
    .yotpo-modal.sent();
    .ant-modal-body {
      padding-top: 81px;
    }
    .logos {
      img {
        width: 288px;
        height: 108px;
      }
    }
  }

  .logos {
    .type-regular();
    font-size: 20px;
    vertical-align: middle;
    & > span {
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }
    .content-sent-image {
      height: 119px;
      width: 199px;
    }

    .yotpo-image {
      vertical-align: middle;
      width: 40px;
      height: 40px;
      margin-left: 18px;
    }

    .trend-image {
      vertical-align: middle;
      width: 95px;
      height: 18px;
      margin-right: 18px;
    }
    margin-bottom: 23px;
  }

  h1 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 39px;
  }

  .label {
    font-size: 12px;
    color: #000;
    .type-bold();
    line-height: 1;
    margin-bottom: 11px;
  }

  .ant-input {
    height: 52px;
    color: #000;
    border: 0;
    border-radius: 3px;
    background-color: #f8f9fb;
    padding: 15px 16px;

    ::placeholder {
      color: #bababa;
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selection {
      background-color: #f4f4f4;
      border: 0;
      height: 52px;
      border-radius: 3px;

      .ant-select-selection-selected-value {
        margin-top: 17px;
        line-height: normal;
      }
      .ant-select-selection__placeholder {
        margin-top: 2px;
        line-height: normal;
        color: #000;
      }
    }
    .ant-select-search--inline .ant-select-search__field {
      margin-top: 10px;
    }
    .ant-select-selection-selected-value {
      color: #000;
    }

    .ant-select-arrow {
      right: 22px;
    }

    &.ant-select-open:not(.ant-select-loading) {
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #000;
        border-top: 0;
      }
    }

    &:not(.ant-select-loading):after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #000;
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 21.5px;
      right: 23.4px;
    }
  }

  .ant-form-item.submit {
    margin-bottom: 38px;
  }
  .ant-alert {
    margin-top: 10px;
  }
  .ant-alert-description {
    text-align: center;
  }

  .submit {
    .ant-btn {
      height: 52px;
      color: #fff;
      background-color: #1776ec;
      border: 0;
      .type-bold();
      &:hover {
        background-color: #5495e6;
        border: 0;
        color: #fff;
      }
    }
  }

  .help {
    margin-bottom: 0;
    a {
      .type-bold();
      color: #1776ec;
      font-size: 12px;
    }
  }
  .error {
    .type-bold();
    color: #ff0000;
    position: absolute;
    bottom: 23px;
    text-align: center;
    width: 100%;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 24px;
    font-size: 18px;
    padding: 0;
    svg g {
      stroke: #000;
    }
  }
}

.yotpo-product-select {
  &.ant-select-dropdown {
    margin-top: -10px;
    border-radius: 0px !important;
    border: none;
    background-color: #f4f4f4;
  }
  .ant-select-dropdown-menu-item {
    height: 52px;
    font-size: 15px;
    padding-top: 15px;
  }
  &.ant-select-dropdown--empty {
    .ant-select-dropdown-menu-item {
      font-size: 15px !important;
    }
  }
}

.story-modal {
  .ant-modal-close {
    right: -56px;
    color: #000;
    background: #efefef;
  }
}

.pending-trend-review-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-content {
    width: 423px;
    height: 349px;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    .review-photo {
      text-align: center;
      padding-top: 43px;
      padding-bottom: 31px;
      position: relative;

      .Earn_Credits {
        width: 151px;
        height: 114px;
        object-fit: contain;
      }
    }

    .review-message {
      padding-left: 71px;
      padding-right: 72px;
      text-align: center;
      padding-bottom: 35px;

      .message {
        font-family: 'TruenoExtraBold';
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 0;
      }
    }

    .review-footer {
      padding-bottom: 33px;
      padding-left: 26px;

      .no-thank-you {
        width: 177.5px;
        height: 45px;
        object-fit: contain;
        border-radius: 4px;
        border: solid 1px #dbdfe8;
        background-color: #f8faff;

        span {
          font-family: "TruenoBold";
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #000000;
        }
      }

      .earn-credits {
        margin-left: 12.5px;
        width: 181.3px;
        height: 45px;
        object-fit: contain;
        border-radius: 4px;
        background-color: #ffb917;

        span {
          font-family: "TruenoBold";
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #000000;
        }
      }
    }
  }
}