/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }

  .small {
    width: 580px;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  //CSS
  .min-layout-content {
    width: 1080px;
  }

  .small {
    width: 580px;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-padding {
    padding: 15px;
  }

  .small {
    width: 580px;
  }
}

@media (max-width: 992px) {
  .logo {
    display: none;
  }

  .logo-mobile {
    display: inline-block;
  }

  .hidden-mobile {
    display: none !important;
  }

  .no-padding-mobile {
    padding: 0 !important;
  }

  .trend-menu-horizontal.ant-menu-horizontal .ant-menu-item {
    margin-right: 25px;
  }

  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }

  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }

  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }

  .content-credit-text {
    padding: 10px;
  }

  .side-small-title {
    font-size: 6px !important;
  }

  .credit-title {
    .type-regular();
    display: block;
    font-size: 8px;
    font-weight: 600;
    padding-top: 8px;
    font-size: 5px !important;
    line-height: 1.4;

    &-block {
      line-height: 0.7;
    }
  }

  .plan-title {
    font-size: 8px !important;
  }

  .package-title {
    .m-0();
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .hidden-xmobile {
    display: none !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-padding {
    padding: 5px;
  }

  .small {
    width: 100%;
  }
}

.ant-layout-sider {
  .brand-image {
    height: 182px;
    background: #2f2f2f;
    .ant-avatar-image {
      height: 100%;
    }
  }
}

.side-menu {
  .menu-title {
    .type-sfpro-heavy();
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.004em;
    color: #fff;
  }

  .campaign-link {
    .type-sfpro-regular();
    font-weight: 600;
  }
}

#admin-layout {
  .ant-layout-content {
    background: #f6f7fb;
  }
  .page-header {
    width: 100%;
    padding-top: 47px;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;

    h1 {
      font-size: 26px;
      font-family: 'TruenoExtraBold';
    }

    .ant-menu.ant-menu-horizontal {
      border-bottom: 0px;
    }
  }
}
