#onboarding-page {
  .wrapper {
    #sign-up-page.wrapper();
  }
  .header {
    #sign-up-page.header();
  }
  .setup-steps {
    #sign-up-page.setup-steps();
  }
  h1,
  p {
    color: #fff;
    text-align: center;
  }
  h1 {
    @media (max-width: 576px) {
      width: 208px;
      font-size: 18px;
    }
    .type-ebold();
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
    margin-bottom: 28px;
  }
  p {
    .type-regular();
    font-size: 14px;
    text-align: center;
    color: #ffffff;
  }
  .calendar {
    @media (max-width: 576px) {
      width: 100%;
    }
    width: 641px;
    height: 356px;
    object-fit: contain;
    background-color: #131313;
  }
}
