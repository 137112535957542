.m-0  { margin:               0 !important; }
.mt-0 { margin-top:           0 !important}
.mb-0 { margin-bottom:        0 !important }
.ml-0 { margin-left:          0 !important }
.mr-0 { margin-right:         0 !important }

.mt-1 { margin-top:           @unit }
.mb-1 { margin-bottom:        @unit }
.ml-1 { margin-left:          @unit }
.mr-1 { margin-right:         @unit }

.mt-2 { margin-top:           2*@unit }
.mb-2 { margin-bottom:        2*@unit }
.ml-2 { margin-left:          2*@unit }
.mr-2 { margin-right:         2*@unit }

.mt-3 { margin-top:           3*@unit }
.mb-3 { margin-bottom:        3*@unit }
.ml-3 { margin-left:          3*@unit }
.mr-3 { margin-right:         3*@unit }

.mt-4 { margin-top:           4*@unit }
.mb-4 { margin-bottom:        4*@unit }
.ml-4 { margin-left:          4*@unit }
.mr-4 { margin-right:         4*@unit }

.mt-5 { margin-top:           5*@unit }
.mb-5 { margin-bottom:        5*@unit }
.ml-5 { margin-left:          5*@unit }
.mr-5 { margin-right:         5*@unit }

.mt-6 { margin-top:           6*@unit }
.mb-6 { margin-bottom:        6*@unit }
.ml-6 { margin-left:          6*@unit }
.mr-6 { margin-right:         6*@unit }

.mt-7 { margin-top:           7*@unit }
.mb-7 { margin-bottom:        7*@unit }
.ml-7 { margin-left:          7*@unit }
.mr-7 { margin-right:         7*@unit }

.mt-8 { margin-top:           8*@unit }
.mb-8 { margin-bottom:        8*@unit }
.ml-8 { margin-left:          8*@unit }
.mr-8 { margin-right:         8*@unit }

.mt-9 { margin-top:           9*@unit }
.mb-9 { margin-bottom:        9*@unit }
.ml-9 { margin-left:          9*@unit }
.mr-9 { margin-right:         9*@unit }
