#sign-up-page {
  background: url('../../images/Sand@2x.jpg');
  .wrapper {
    @media (max-width: 576px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    h2,
    p {
      width: 100%;
      @media (max-width: 576px) {
        text-align: center;
        width: 262px;
      }
    }

    h2 {
      font-size: 18px;
      height: 30px;
      margin-bottom: 0;
    }
    p {
      line-height: 1.71;
      font-size: 14px;
      min-height: 59px;
    }
  }
  .ant-form-vertical {
    .ant-form-item {
      padding-bottom: 0;
    }
  }
  .setup-steps {
    color: #fff;
    @media (max-width: 576px) {
      width: 100%;
      border-radius: 0;
      border-right: 0;
      border-left: 0;
    }
    width: 379px;
    border-radius: 28.5px;
    background: #131313;
    border: solid 1px #272727;

    .step {
      margin-left: 21px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-family: 'TruenoBold';
      font-size: 12px;
      float: left;
      color: #444444;
      @media (max-width: 576px) {
        width: 50%;
        margin: 0;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      &:first-child {
        @media (max-width: 576px) {
          text-align: right;
        }
      }
      &:last-child {
        @media (max-width: 576px) {
          text-align: left;
        }
      }
      &-label,
      &-symbol {
        display: inline-block;
        text-align: left;
      }
      &-label {
        font-family: 'TruenoSemiBold';
        @media (max-width: 576px) {
          width: 79px;
          font-size: 10px;
          vertical-align: middle;
        }
      }

      &.active {
        .step-symbol {
          background: #ffb917;
          color: #000;
        }

        .step-label {
          color: #fff;
        }
      }
      &-symbol {
        margin-right: 10px;
        box-shadow: -20px 22px 23px 0 rgba(0, 0, 0, 0.19);
        background-color: #272727;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        text-align: center;
        color: #636363;
        line-height: 28px;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 25px;
  }

  .header {
    margin-top: 35px;
    margin-bottom: 29px;
  }
  .back {
    color: #fff;
    font-family: 'TruenoBoldItalic';
    .anticon {
      font-size: 18px;
    }
  }
  .has-error {
    .ant-input {
      background: none;
    }
  }
  p {
    .type-light();
    margin-bottom: 25px;
    line-height: 1.71;
  }
  .trend-label {
    .type-sfpro-regular();
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000;
  }
  input {
    .type-sfpro-regular();
    height: 49px;
    padding-left: 0;
    padding-bottom: 15px;
    padding-top: 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    &::placeholder {
      color: #757575;
    }
  }
  .stripe-form {
    .stripe-element-input {
      font-size: 15px;
      height: 49px;
      padding-bottom: 18px;
      padding-top: 14px;
      font-family: 'TruenoRegular';
      margin-bottom: 25px;
    }
  }
  .signup-form {
    margin-right: 28px;
    @media (max-width: 576px) {
      margin-right: 0px;
    }

    &.ant-col.ant-col-md-8 {
      @media (min-width: 768px) {
        width: 621px;
      }
    }
  }

  .order-summary {
    height: 100%;
    padding: 122px 20px 12px !important;
    width: 300px;
    position: relative;

    @media (max-width: 576px) {
      width: 100%;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-top: 0px !important;
    }
    border: solid 1px #272727;
    background-color: #131313;
    & > h3 {
      margin-top: 32px;
      margin-bottom: 0px;
      font-family: Helvetica, sans-serif !important;
      font-weight: bold;
      padding-bottom: 0px;
      display: none;
      font-size: 13px;

      @media (max-width: 576px) {
        display: block;
        margin-bottom: 208px;
      }
    }

    .add-credits-component {
      padding-bottom: 19px;
      border-bottom: 1px solid #393939;

      @media (max-width: 576px) {
        display: block;
        border-bottom: none;
        padding-bottom: 0;
      }

      &.total {
        margin-top: 33px;
        padding-bottom: 33px;
        margin-bottom: 38px;

        @media (max-width: 576px) {
          padding-bottom: 45px;
          border-bottom: 1px solid #393939;
        }
      }
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.85;
      letter-spacing: normal;
      color: #ffffff;
      font-family: 'galano';
    }

    .credit-total-amount {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.89;
      letter-spacing: normal;
      text-align: right;
      color: #ffffff;
      font-family: Helvetica, sans-serif !important;
    }

    .credit-vip,
    .credit-standard {
      .credit-label,
      .credit-total-label {
        font-family: Helvetica, sans-serif;
        line-height: 1.14;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
        &.orange {
          color: #f6d384;
        }
      }
      .credit-total-label {
        font-family: Helvetica, sans-serif !important;
      }
      .credit-value {
        margin-bottom: 6px;
        font-family: Helvetica, sans-serif !important;
        font-weight: bold;
      }
      .credit-total-amount {
        margin-bottom: 6px;
        font-family: Helvetica, sans-serif !important;
        font-weight: bold;
      }
    }

    .credit-standard {
      .credit-value {
        font-size: 28px;
      }
    }

    .total-credits {
      display: flex;
      justify-content: center;
      text-align: center;
      width: 154px;
      height: 154px;
      background: linear-gradient(to bottom left, #ffffff, #f5cf79);
      position: absolute;
      border-radius: 100%;
      z-index: 1;
      left: 0;
      bottom: 24px;
      padding-top: 46px;

      &:after {
        background-color: #000;
        position: absolute;
        border-radius: 100%;
        width: 142px;
        height: 142px;
        content: '';
        z-index: -1;
        left: 6px;
        top: 6px;
      }

      .value {
        font-size: 50px;
        line-height: 28px;
        margin-bottom: 14px;
        .type-sfpro-bold();
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .confirmation {
    button {
      .type-sfpro-heavy();
      font-style: italic;
      line-height: 18px;
      text-transform: none;
      width: 238px;
      height: 56px;
      font-size: 20px;
      border-radius: 32px;
      margin-bottom: 47px;
    }
  }

  .confirmation-text {
    p {
      width: 298px;
      height: 62px;
      .type-sfpro-regular();
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-align: center;

      color: #000000;
    }
  }
  .due {
    margin-top: 25px;
    margin-bottom: 41px;
    .type-sfpro-heavy();
    font-style: normal;
    font-size: 25px;
    line-height: 18px;
    color: #000;
  }
  .terms-conditions {
    margin-bottom: 41px;
    .ant-checkbox-wrapper + span,
    .ant-checkbox + span {
      color: #000;
      padding: 0 0 0 15px;
      letter-spacing: normal;
    }
  }
  .trend-terms-checkbox {
    padding-bottom: 1px;
    margin-right: 0px !important;
    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background: #fff;
    }
  }
  .registration-message {
    padding-top: 210px;
    color: #fff;
    img.trend {
      height: 16px;
      width: 83px;
      margin-bottom: 23px;
    }

    p {
      .type-sfpro-heavy();
      font-style: normal;
      font-size: 30px;
      line-height: 40px;

      &.sub {
        font-weight: normal;
        font-size: 20px;
        line-height: 31px;
      }
    }

    .quoted-by {
      vertical-align: middle;
      img {
        margin-right: 15px;
        width: 35px;
        height: 25px;
        vertical-align: middle;
      }
      .type-sfpro-bold();
      font-size: 14px;
      line-height: 14px;
    }
  }
}
