.plan-list {
  .plan-header {
    background: #3c3c3c;
    font-family: 'TruenoSemiBold';
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #ffffff;
    padding: 8px 0;
  }
  .ant-radio-group {
    .plan-radio {
      & > span {
        height: 86px;
        display: block;
        padding: 0 !important;

        .ant-row-flex {
          height: 100%;
        }
      }
      &.ant-radio-wrapper:last-child {
        border-bottom: 1px solid #3c3c3c;
        &.ant-radio-wrapper-checked {
          border: 1px solid @primary-color;
        }
      }

      .plan-icon {
        margin-left: 25px;
        margin-right: 11px;
        display: inline-block;
      }
    }
  }
  @media (max-width: 767.98px) {
    .plan-header {
      display: none;
    }
  }
}
.plan-radio {
  min-height: 86px;

  &.current-plan {
    &.ant-radio-wrapper-checked {
      border: 1px solid #3c3c3c !important;
      .ant-radio {
        display: none !important;
      }
    }
  }

  &-popular {
    min-height: 108px;
  }

  &.custom-radio.ant-radio-wrapper {
    border-top: 0;
  }

  &.ant-radio-wrapper {
    white-space: inherit !important;
    border: 1px solid #3c3c3c;
    border-bottom: 0;
    background-color: #0f0f0f;
    margin-right: 0px;
  }

  &.ant-radio-wrapper-checked {
    border: 1px solid @primary-color;
  }

  .ant-radio {
    position: absolute;
    top: 0px;
    right: 24px;
    height: 100%;
    display: flex !important;
    align-items: center;

    &-inner {
      width: 36px !important;
      height: 36px !important;
      background: #3c3c3c !important;
      border-color: #7a7a7a !important;

      &:after {
        width: 36px !important;
        height: 36px !important;
      }
    }

    &-checked {
      &::after {
        content: none !important;
      }

      & .ant-radio-inner {
        background: @primary-color !important;
        border-color: @primary-color !important;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #ffb917;
          background-color: #ffb917;
          visibility: hidden;
          -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          content: '';
        }
      }
    }
  }
  .plan-cost {
    text-align: right;
  }
  .col-type {
    display: none;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 20px !important;
    border-radius: 2px;

    .col-type {
      display: block;
    }

    &.ant-radio-wrapper {
      border: 1px solid #3c3c3c;
    }

    &.ant-radio-wrapper-checked {
      border: 1px solid @primary-color;
    }

    & > span {
      height: auto !important;
    }

    .plan-name,
    .plan-description,
    .plan-initial-credits,
    .plan-duration,
    .plan-popular {
      text-align: center;
      margin-top: 30px;
      line-height: 0.89;

      .col-type {
        margin-top: 5px;
        font-weight: normal !important;
        font-family: 'TruenoRegular';
      }
    }
    .plan-popular {
      display: block;
      padding-top: 20px;
    }
    .plan-name {
      margin-top: 14px;
      font-size: 18px;
      display: block !important;
      text-align: center;
    }
    .plan-duration {
      margin-top: 30px;
    }
    .plan-icon {
      padding-top: 32px;
      display: block !important;
      text-align: center;
      margin: 0 !important;
    }
    .plan-description {
      padding-left: 23px;
      padding-right: 23px;
    }
    .plan-cost {
      text-align: center;
      margin-bottom: 100px;
      margin-top: 23px;
    }

    .ant-radio {
      bottom: 33px;
      width: 100%;
      left: 0;
      top: unset;
      height: fit-content;
      justify-content: center;
    }
  }
}

.StripeElement {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #eee;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 5px;
  margin-bottom: 12px;

  .input {
    height: 50px;
    color: @color-orange;
  }
  font-size: 24px;
  &::placeholder {
    color: @color-black-ter;
  }
}

.text-gradient {
  .uppercase();

  &.silver {
    background: linear-gradient(to right, #b2bacb 0%, #f7f7f7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.gold {
    background: linear-gradient(to right, #ceb785 0%, #f7f7f7 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
