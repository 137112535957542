#manage_credits {
  .title {
    font-family: SFProDisplay;
    letter-spacing: -0.008em;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 42px;
    margin: 75px 0 14px;
  }

  .descriptions {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.008em;
    color: #999999;
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;