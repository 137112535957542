.brand-integrations {
  .ant-btn-link {
    width: 100%;
    display: block;
    white-space: pre-wrap;
  }

  .card {
    padding: 26px 19px 21px;
    background: #fff;

    &-logo {
      margin-bottom: 37px;
      img {
        width: 117px;
        height: 115px;
      }
    }
    &-description {
      .type-regular();
      color: #4a4a4a;
      line-height: 1.58;
      text-align: left;
      min-height: 120px;
      font-size: 12px;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }

    &-actions {
      margin-top: 20px;
      .type-ebold();
      color: #000;
      font-size: 16px;
      .circle {
        .type-bold();
        display: inline-block;
        color: #151515;
        font-size: 20px;
        margin-right: 8px;
        position: relative;
        z-index: 1;
        width: 28px;
        height: 28px;

        &::after {
          z-index: -1;
          top: 0.5px;
          content: '';
          display: block;
          position: absolute;
          width: 28px;
          height: 28px;
          border-radius: 100%;
          background: #ffb917;
        }
      }
    }

    &.active {
      .card {
        &-actions {
          color: #2e84ed;
        }
      }
    }
  }
}
