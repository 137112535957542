#sign-up-v2-page {
  .type-sfpro-regular();

  h1,
  p {
    color: #fff;
  }
  .ant-col-xs-20 {
    &.header {
      @media (max-width: 576px) {
        width: 220px;
      }
    }
  }
  h1 {
    .type-sfpro-heavy();
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 79px;
    font-weight: 800;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 22px;
      margin-bottom: 49px;
    }

    .sub-header {
      .type-sfpro-regular();
      font-weight: 300 !important;
      font-style: normal;
      font-size: 18px;
      line-height: 26px;
      color: #999;
      margin-top: 13px;
      letter-spacing: -0.008em;
    }
  }

  p {
    font-family: 'SFProMedium';
    width: 492px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 60px;
    font-weight: bold;
  }

  .ant-form-item {
    margin-bottom: 24px;
    @media (max-width: 576px) {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }
  .ant-form-item-label label {
    .type-sfpro-bold();
    font-weight: bold;
    font-size: 18px !important;
    color: #fff;
  }

  .trend-input {
    padding-top: 0px;
    padding-left: 0px;
    .type-sfpro-regular();
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 16px;

    &::placeholder {
      color: #989898;
    }
  }

  .has-error {
    .trend-input {
      background-color: #000;
    }
    .ant-form-explain {
      right: 0px;
      top: -30px;
      position: absolute;
    }
  }

  .ant-btn {
    .type-sfpro-iheavy();
    border-radius: 32px;
    padding: 18px 44px 18px;
    margin-bottom: 107px;
    height: auto;
    font-size: 20px;
    line-height: 18px;
    & > span {
      color: #000;
    }

    @media (max-width: 576px) {
      font-size: 22px;
      margin-top: 12px;
    }
  }
  .submit {
    margin-top: 76px;
  }
}
