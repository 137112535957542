#add-credits {
  margin-top: 67px;
  .ant-slider {
    .ant-slider-mark {
      margin-top: 20px;
      .ant-slider-mark-text {
        color: @color-gray-darkest;
        font-size: 10px;
        font-weight: 900;
      }
    }
    .ant-slider-dot {
      visibility: hidden;
    }
    .ant-slider-rail,
    .ant-slider-step,
    .ant-slider-track {
      height: 7px;
    }
    .ant-slider-handle {
      margin-top: -3px;
    }
  }

  .order-summary {
    #sign-up-page.order-summary();
    height: auto;

    .add-credits-component {
      padding-bottom: 16px;
      .total {
        border: none;
        margin-bottom: 0px;
      }
    }

    .confirmation {
      button {
        margin-bottom: 15px;
      }

      .purchase-description {
        font-family: SFProDisplay;
      }
    }

    .credit-standard-section {
      .credit-label,
      .credit-total-label {
        .type-sfpro-regular();
        line-height: 17px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
        &.orange {
          color: #f6d384;
        }
      }
      .credit-total-label {
        .type-sfpro-regular();
      }
      .credit-value {
        margin-bottom: 6px;
        .type-sfpro-regular();
        font-weight: bold;
      }
      .credit-total-amount {
        margin-bottom: 6px;
        .type-sfpro-regular();
        font-weight: bold;
      }
    }

    .order-total {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 22px;
      .type-sfpro-regular();
      margin-top: 23px;
      margin-bottom: 26px;
    }

    .purchase-btn {
      height: 50px;
      font-size: 16px;
      text-transform: none !important;
      .type-sfpro-bold();
      font-style: italic !important;
      font-weight: 800 !important;
      line-height: 18px;
      letter-spacing: -0.004em;
    }
  }
}

.credit-form {
  border-radius: 3px;
  border: solid 1px #272727;
  background-color: #131313;
  padding: 40px 38px !important;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  &.ant-col.ant-col-md-8 {
    width: 619px;
    margin-right: 20px;
  }
  .creator-credits {
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 42px;
    letter-spacing: -0.008em;
    margin-bottom: 9px;
  }
  .sub-credits-text {
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.008em;
  }
  .credits-descriptions {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.008em;
    color: #ffffff;
    margin-bottom: 109px;
  }
}

.credits-slider {
  position: relative;

  .ant-tooltip-inner {
    width: max-content;
    color: #000;
    font-size: 12px;
    border-radius: 11px;
    height: 22px;
    min-height: 22px;
    font-weight: bold;
    padding: 5px 10px;
    line-height: 0.97;
  }

  .discount-percent-tag {
    position: absolute;
    width: 100%;
    top: 0px;

    .tag {
      text-align: center;
      color: #000;
      font-size: 12px;
      padding-top: 33px;
      height: 60px;
      width: 33px;
      top: -27px;
      background-size: 33px 60px;
      position: absolute;
      margin-left: -16.5px;
      .type-sfpro-light();

      .percent {
        font-weight: bold;
        font-size: 12px;
        line-height: 10px;
      }
      .percent-label {
        margin-top: 2px;
        font-size: 8px;
        line-height: normal;
        font-weight: bold;
      }
    }
  }

  &.standard {
    .ant-slider-handle {
      background-image: url('../../images/gold-slider@2x.png');
    }

    .discount-percent-tag .tag {
      background-image: url('../../images/gold-tag@2x.png');
    }

    .ant-tooltip-placement-top {
      .ant-tooltip-inner {
        background: #f5cf79;
      }

      .ant-tooltip-arrow {
        border-right-color: #f5cf79;
        border-bottom-color: #f5cf79;
      }
    }
  }

  .ant-slider {
    &:hover {
      .ant-slider-track {
        background: linear-gradient(to left, #ffffff, #f5cf79, #f5cf79);
      }
    }
  }
  .ant-slider-track {
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    background: linear-gradient(to left, #ffffff, #f5cf79, #f5cf79);
  }

  .ant-slider-dot {
    background-color: #0f0f0f !important;
  }

  .ant-slider-handle {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    border: 0 !important;
    margin-top: -21px !important;
    margin-left: 0 !important;

    &:not(.ant-tooltip-open) {
      border: 0 !important;
      box-shadow: none;
    }
  }

  .ant-slider-rail {
    height: 7px;
    border: 1px solid #3c3c3c;
    border-radius: 35px;
    background-color: #0f0f0f;
  }
  .discount-container {
    margin: 0 6px;
    position: relative;
  }

  .discount-range {
    width: '100%';
    margin-top: 20px;

    .discount {
      width: 20%;
      height: 5px;
      float: left;
      border-left: 1px solid #393939;
      border-bottom: 1px solid #393939;
      border-right: 1px solid #393939;

      &.in-range {
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
      }
    }
  }
}
