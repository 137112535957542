#admin-influencer-approval {
  .ant-table {
    background: #fff;
    .ant-table-row {
      td {
        padding: 13px;
      }
      td:first-child {
        padding-left: 42px;
      }
      td:last-child {
        padding-right: 42px;
      }
    }
  }

  .ant-avatar {
    width: 50px;
    height: 50px;
    margin-right: 14px;
  }

  .influencer-details a {
    color: #000;
    font-size: 12px;
    font-family: 'TruenoSemiBold';
  }

  .actions {
    button {
      color: #000;
      margin-left: 9px;
      font-family: 'FirmeBlackItalic';
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  .loadmore {
    padding: 20px;
  }
}

.admin-approve-modal {
  .ant-modal-content {
    border-radius: 0px;
    width: 518px;
    .ant-modal-body {
      padding: 45px 70px;
    }
    p {
      color: #000;
      font-size: 26px;
      font-family: 'TruenoExtraBold';
    }

    button {
      color: #000;
      font-size: 14px;
      font-family: 'TruenoBold';
      height: 45px;
      margin-bottom: 8px;
    }
    .button-options {
      width: 247px;
    }
  }
}

#admin-business-approval {
  .ant-table {
    background: #fff;
    .ant-table-thead {
      &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 38px;
        top: 0px;
        background: #f6f7fb;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
      }
      th {
        background: #f6f7fb;
        padding: 13px !important;
      }

      th:last-child {
        text-align: center !important;
      }
    }
    .ant-table-row {
      
      td {
        padding: 13px;
      }
      
    }
  }

  .actions {
    button {
      color: #000;
      margin-left: 9px;
      font-family: 'FirmeBlackItalic';
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  .loadmore {
    padding: 20px;
  }
}
@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;