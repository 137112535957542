.stats-heading {
  margin: 0;
  font-size: 17px;
  font-style: italic;
  color: @color-black;
  line-height: 1.4;
}

.caps-heading {
  .size-8px();
  .spacing-05px();
  .type-sbold();
  .uppercase();
  color: @color-pigeon-dark;
}

.campaign-image {
  position: relative;
  height: 272px;
  overflow: hidden;
  background: @color-gray-lighter;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .trend-btn {
    position: absolute;
    bottom: 15px;
    right: 0;
    box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.item-campaign {
  height: 359px;
}

.post-requirements {
  .required-posts {
    .post-icons {
      font-size: 20px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .label-value {
    vertical-align: middle;
  }

  &-states {
    &-state {
      margin-top: 13px;
    }

    .circle {
      width: 22px;
      height: 22px;
      border-radius: 100%;
      font-size: 8px;
      display: inline-block;
      border: 1px solid #000;
      padding-top: 4px;
      text-align: center;
      margin-right: 10px;
    }
  }
}

#recent-post {
  .post-item {
    &-details {
      display: inline-block;
      vertical-align: top;
      padding-top: 7px;
      width: calc(100% - 50px);

      &-name {
        text-overflow: ellipsis;
        display: block;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
      }

      &-date {
        padding-top: 5pt;
        display: block;
      }
    }
    &-avatar {
      display: inline-block;
      margin-right: 10px;
      .post-card-image {
        width: 100%;
        height: 100%;
        a {
          display: block;
          width: 100%;
          height: 100%;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.campaign-content {
  .nav-header {
    min-height: 156px;
    padding-top: 38px;

    h1 {
      font-size: 26px;
    }
  }
}

#application-page,
#influencers-page {
  .trend-table {
    .ant-table-thead {
      th {
        text-align: center !important;

        &.age-range {
          width: 70px;
        }
        &.followers {
          width: 90px;
        }
        &.like-count {
          width: 90px;
        }
        &.date-applied {
          width: 120px;
        }
        &.comment-count {
          width: 120px;
        }
        &.actions {
          width: 150px;
        }
        &.engagement-rate {
          width: 150px;
        }
      }

      th:first-child {
        text-align: left !important;
      }

      .ant-table-column-has-sorters:not(.ant-table-column-sort) {
        .ant-table-column-sorter {
          .ant-table-column-sorter-inner {
            &:after {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 0 6px 6px;
              border-color: transparent transparent #5b6572 transparent;
              position: absolute;
              bottom: 14px;
            }

            .ant-table-column-sorter-up {
              &.off {
                display: none;
              }
            }
            .ant-table-column-sorter-down {
              &.off {
                display: none;
              }
            }
          }
        }
      }

      .ant-table-column-sort {
        background-color: #fffcf3 !important;

        .ant-table-column-title {
          font-family: TruenoSemiBold;
          color: #000;
        }

        .ant-table-column-sorter {
          .ant-table-column-sorter-inner {
            .ant-table-column-sorter-up {
              &.on {
                position: absolute;
                display: inline-block;
                color: #000000;
              }
              &.off {
                display: none;
              }
            }
            .ant-table-column-sorter-down {
              &.on {
                position: absolute;
                color: #000000;
                bottom: 16px;
              }
              &.off {
                display: none;
              }
            }
          }
        }
      }
    }

    .ant-table-row {
      td {
        cursor: pointer;

        &.followers {
          padding-right: 12px !important;
        }
        &.age-range {
          padding-right: 19px !important;
        }
        &.like-count {
          padding-right: 12px !important;
        }
        &.comment-count {
          padding-right: 24px !important;
        }
        &.engagement-rate {
          padding-right: 24px !important;
        }
        &.date-applied {
          padding-right: 19px !important;
        }
        &.influencer-details {
          .influencer-info,
          .influencer-image {
            cursor: pointer;
          }
        }
      }

      .ant-table-column-sort {
        font-family: TruenoBold;
        background-color: #fffcf3 !important;
      }
    }
  }

  .empty-application {
    padding-top: 120px;

    .media {
      width: 440px;
      height: 250px;
      margin-bottom: 22px;
    }
    p {
      .type-sfpro-heavy();
      font-size: 26px;
      line-height: 33px;
      text-align: center;
      color: #000000;
    }
  }
}

#influencers-page {
  .trend-table .ant-table-thead th {
    text-align: right !important;
  }

  #post-approval-content .trend-table .ant-table-thead th {
    text-align: left !important;
  }

  #shipments-content .trend-table .ant-table-thead th {
    text-align: left !important;
  }
}

#influencer-drawer,
#influencer-drawer-admin {
  &.ant-drawer:not(.ant-drawer-open) {
    .ant-drawer-content {
      box-shadow: none;
    }
  }

  .ant-drawer-body {
    padding: 0px;
    overflow: hidden;
  }
  .ant-drawer-header-no-title {
    position: relative;
    .ant-drawer-close {
      left: 0;
      color: @color-white;
    }
  }

  .ant-drawer-content {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.55);
    background-image: linear-gradient(to bottom, #2e114a, #12041f);

    .influencer-info {
      &-label {
        display: block;
        height: 10px;
        font-family: 'TruenoSemiBold';
        font-size: 8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2px;
        color: #ffffff;
      }

      &-actions {
        padding: 20px;
        button {
          font-size: 12px;
          height: 43px;
          border-radius: 2px;
          font-family: 'FirmeBlackItalic';
          font-weight: 900;
          font-style: italic;
          font-stretch: normal;
          line-height: 0.92;
          letter-spacing: 0.6px;

          &.action-reject {
            color: @color-white;
            border: solid 1px #46266d;
            background-color: rgba(59, 31, 92, 0.49);

            &:hover {
              background-color: #381d58;
            }
          }

          &.action-approve {
            color: @color-black;
          }
        }
      }

      &-avatar {
        text-align: center;
        margin-top: 35px;
        position: relative;
      }
      &-vip {
        width: 30px;
        height: 30px;
        font-size: 30px;
        position: absolute;
        display: block;
        bottom: 0;
        right: 117px;
      }
      &-name {
        text-align: center;
        font-family: 'TruenoExtraBold';
        color: @color-white;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.83;
        letter-spacing: normal;
        text-align: center;
        padding: 0px;
        padding-top: 8px;
        margin: 0px;
      }
      &-address {
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: center;
        color: @color-white;
        margin-top: -3px;
      }
    }

    .influencer-stats {
      border: solid 1px #46266d;
      background-color: rgba(59, 31, 92, 0.44);
      color: #fff;
      border-right: 0;
      border-left: 0;
      margin-bottom: 37px;

      &-followers {
        padding-top: 16px;
        text-align: center;
        color: @color-white;
        border-bottom: solid 1px #46266d;

        .influencer-stats-label {
          text-align: center;
        }

        .influencer-stats-value {
          padding-top: 10px;
          font-size: 28px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.75;
          letter-spacing: normal;
          padding-bottom: 20px;
        }
      }

      &-label {
        display: block;
        height: 10px;
        font-family: 'TruenoSemiBold';
        font-size: 8px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2px;
        color: #ffffff;
      }

      &-other-details {
        padding: 18px 0 18px;
      }

      &-engagement {
        .type-sbold();
        .size-10px();
        text-transform: uppercase;
        padding: 10px 11px;
        text-align: center;
        background: #3b1f5c;
        border-top: 1px solid #46266d;
        letter-spacing: 2px;
      }

      &-likes,
      &-comments {
        padding-left: 19px;

        .influencer-stats-value {
          font-size: 21px;
          font-family: 'TruenoLight';
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #ffffff;

          &-indicator-icon {
            display: inline-block;
            margin-right: 7px;
          }

          &-indicator-percent {
            display: inline-block;
            margin-left: 7px;
            font-size: 9px;
            color: #836ba1;
            font-family: 'TruenoSemiBold';
          }

          &.down {
            .influencer-stats-value-indicator-percent {
              color: #f54c15;
            }
          }

          &.up {
            .influencer-stats-value-indicator-percent {
              color: #15f590;
            }
          }
        }
      }

      &-comments {
        padding-left: 23px;
        border-left: solid 1px #46266d;
      }
    }

    .influencer-review-stats {
      margin-left: 21px;
      margin-right: 21px;
      margin-bottom: 30px;
      color: #ffffff;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding-bottom: 2px;

      .review-stat {
        margin-bottom: 26px;
        position: relative;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      label.review-stat-label {
        font-family: 'TruenoSemiBold';
        text-transform: uppercase;
        height: 10px;
        font-size: 8px;
        line-height: normal;
        letter-spacing: 2px;
        display: block;
        margin-bottom: 9px;
      }

      .review-stat-value {
        width: 24px;
        height: 21px;
        font-family: 'TruenoLight';
        font-size: 16px;
        line-height: 1.31;
        position: absolute;
        right: 0;
        bottom: 7px;
      }

      .review-stat-rate {
        height: 4px;
      }

      .ant-progress-bg,
      .ant-progress-inner {
        display: block;
        border-radius: 0 !important;
      }
      .ant-progress {
        display: block;
        & > div {
          display: block;
        }
      }
      .ant-progress-outer {
        display: block;
      }

      .ant-progress-inner {
        display: block;
        background: transparent;

        &:before {
          content: '';
          height: 2px;
          border: solid 1px #46266d;
          width: 100%;
          display: block;
          position: absolute;
          top: 1px;
        }
      }
    }

    .influencer-reviews {
      margin-left: 21px;
      margin-right: 21px;
      margin-bottom: 47px;
      color: #ffffff;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;

      .influencer-info-label {
        margin-bottom: 10px;
      }

      .review-comment {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: #ffffff;
        font-family: 'TruenoRegular';
        margin-bottom: 8px;
      }

      .review-reviewer {
        font-size: 10px;
        font-family: 'TruenoBold';
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.9;
        letter-spacing: normal;
      }

      .influencer-review {
        border-bottom: 1px solid #46266d;
        padding-bottom: 24px;
        margin-top: 24px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 25px;
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      .load-more-reviews {
        button.ant-btn {
          height: 28px;
          background-color: rgba(59, 31, 92, 0.44);
          border-color: rgba(59, 31, 92, 0.44);
          font-family: 'TruenoSemiBold';
          font-size: 8px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 3px;
          text-align: center;
          color: #ffffff;

          &:hover {
            background-color: rgba(59, 31, 92, 0.9);
            border-color: rgba(59, 31, 92, 0.9);
          }
        }
      }
    }

    .influencer-posts {
      margin-left: 21px;
      margin-right: 21px;

      .influencer-info-label {
        margin-bottom: 12px;
      }

      &-images {
        a {
          display: inline-block;
          width: 141px;
          height: 141px;
          padding-right: 4px;
          padding-bottom: 4px;

          &:last-child {
            padding-right: 0px;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .influencer-info-bio {
      color: @color-white;
      margin: 31px 21px;

      p {
        white-space: pre-wrap;
        margin-top: 10px;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.82;
        letter-spacing: normal;
        color: #ffffff;
      }
    }

    .influencer-info-work {
      color: @color-white;
      margin: 41px 21px;

      .influencer-info-label {
        margin-bottom: 12px;
      }

      .influencer-info-posts {
        .post-card {
          margin-bottom: 20px;

          &-resource {
            position: relative;
            width: 100%;
            height: 287px;

            .post-card-video,
            .post-card-image {
              width: 100%;
              height: 100%;
            }

            .post-card-image a {
              width: 100%;
              height: 100%;
              display: block;
            }

            video,
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 62px;
              background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
            }
          }

          &-stats {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-left: 16px;
            z-index: 2;
          }

          &-stat {
            float: left;
            width: auto;
            margin-right: 30px;
            margin-bottom: 11px;
            color: #ffffff;

            &-value {
              font-family: 'TruenoBold';
              font-size: 16px;
              line-height: 0.75;
              padding-bottom: 6px;
            }

            &-label {
              font-family: 'TruenoSemiBold';
              font-size: 8px;
              letter-spacing: 0.5px;
            }
          }

          &-actions {
            width: 100%;

            button {
              background-color: #2f154a;
              height: 48px;
              border: none;
              border-radius: 0;
              color: #fff;
              font-family: 'TruenoSemiBold';
              font-size: 8px;
              letter-spacing: 3px;
            }
          }
        }
      }
    }
  }

  .post-gallery-actions {
    button {
      top: 118px;
      &.action-next {
        right: 16px;
      }

      &.action-previous {
        left: 16px;
      }
    }
  }

  .ant-tabs-bar {
    border-bottom: solid 1px #46266d;
    margin-bottom: 0;
  }
  .ant-tabs-ink-bar {
    background-color: #ffb917;
    margin-left: 39px;
    width: 31px !important;
  }

  .ant-tabs-nav {
    width: 100%;
    .ant-tabs-tab {
      padding: 23px 0;
      width: calc(100% / 3);
      margin: 0;
      color: #fff;
      opacity: 0.5;
      font-family: 'TruenoLight';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.86;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;

      &.ant-tabs-tab-active {
        font-family: 'TruenoBold';
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  .campaign-details {
    text-align: left;
    color: #fff;
    margin: 18px 19px;
    padding: 19px 20px;
    border-radius: 1;
    background-color: rgba(59, 31, 92, 0.49);

    &-brand-name {
      font-family: 'TruenoBold';
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 3px;
    }

    &-name {
      font-family: 'TruenoBold';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 18px;
    }

    .details-status {
      position: relative;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      .status {
        margin-left: 15px;
        font-family: 'TruenoRegular';
        line-height: 0.86;
        &-value {
          margin-bottom: 8px;
        }

        &-label {
          text-transform: uppercase;
          font-family: 'TruenoSemiBold';
          font-size: 8px;
          line-height: normal;
          letter-spacing: 2px;
        }
      }
      .icon {
        top: 5px;
        width: 8px;
        height: 8px;
        position: absolute;
        display: inline-block;
        border-radius: 100%;

        &.success {
          background: #15f590;
        }
        &.danger {
          background-color: #ff0000;
        }
        &.warning {
          background-color: #ffb917;
        }
      }
    }
  }
  .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
    .ant-spin-container {
      height: 100%;
      width: 100%;
    }
  }
}

#influencer-drawer-admin {
  .ant-drawer-content .influencer-stats {
    border-top: 0;
  }
  .ant-drawer-content .influencer-info-actions {
    padding: 23px 34px 0 34px;
  }
  .influencer-badge {
    position: absolute;
    top: 22px;
    right: 43px;
    width: 34px;
  }
}

#campaign-posts-list {
  .trend-post-card,
  .trend-post-card-content {
    .ant-card-meta-detail {
      width: calc(100% - 45px);
    }
  }
}

#campaign-posts-page {
  .post-filter-types {
    border-bottom: 1px solid #d6d6d6;
  }
}

#campaign-overview {
  .post-guidelines {
    .guideline {
      margin-bottom: 50px;
      line-height: 2.33;
      letter-spacing: normal;
      color: #000000;
    }
  }

  .styleguide {
    .image {
      padding-bottom: 20px;
      img {
        width: 100%;
        height: 112px;
        object-fit: cover;
      }
    }
  }
}
.example-campaign {
  .video-player {
    width: 446px;
    height: 250px;
    margin-top: 86.5px;
    margin-bottom: 42px;
  }
  .account-approval-status {
    .type-sfpro-bold();
    color: #000;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .approval-status {
    &::after {
      content: '';
      margin-left: 3px;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-bottom: 2px;
    }
    &.pending,
    &.rejected {
      color: #f6ab00;
      &::after {
        background-color: #f6ab00;
      }
    }
    &.approved {
      color: #19d921;
      &::after {
        background-color: #19d921;
      }
    }
  }
}

.product-form {
  .image-uploader {
    .image,
    .add {
      padding-bottom: 20px;
    }
    .actions {
      .remove {
        height: 24px;
        background-color: #3c3c3c;
        color: #fff;
        border-radius: 0;
        font-size: 10px;
        .type-regular();
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }

    .add {
      width: 100%;
      height: 112px;
      position: relative;
      background-color: #151515;
      border: 1px solid #3c3c3c;
      border-radius: 2px;

      input[type='file'] {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        z-index: 2;
        cursor: pointer;
      }

      .button {
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 34px;
        text-align: center;

        .anticon {
          font-size: 28px;
        }
        .label {
          font-size: 10px;
          color: #747474;
          .type-sfpro-regular();
        }
      }
    }
  }
}

#new-campaign-page {
  .ant-steps {
    .type-sfpro-regular();
    width: 459px;
    padding: 15px 30px 16px;
    background: #151515;
    border-radius: 28.5px;
    margin-bottom: 0px;

    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      margin-right: 0px;
    }
    .ant-steps-item-icon {
      margin-right: 8px;
      line-height: 28px;
    }
    .ant-steps-icon {
      width: 28px;
      height: 28px;
      border: 1px solid #3c3c3c;
      font-size: 12px;
      display: inline-block;
      border-radius: 100%;
      line-height: 26px;
      color: #747474;
    }
    .ant-steps-item-title {
      line-height: 31px;
      .type-sfpro-regular();
      font-size: 12px;
      color: #747474;
    }
    .ant-steps-item-process {
      .ant-steps-item-title {
        color: #fff;
        .type-sfpro-bold();
        font-style: normal;
      }
      .ant-steps-icon {
        .type-sfpro-bold();
        color: #000;
        background-color: #ffb917;
        border-color: #ffb917;
      }
    }
    .ant-steps-item-wait {
      .ant-steps-icon {
        .type-sfpro-regular();
      }
    }
  }

  &.first-campaign {
    input.ant-input.trend-input {
      padding-top: 0px;
      .type-sfpro-regular();

      &.large {
        font-size: 20px;
        line-height: 24px;
        &::placeholder {
          font-size: 20px;
        }
      }
      &::placeholder {
        color: #989898;
      }
    }

    .steps {
      color: #fff;
      background: #151515;
      border-radius: 28.5px;
      height: 59px;
      width: 459px;
      padding-top: 15px;
      padding-left: 30px;

      .steps-item {
        display: inline-block;

        &:first-child {
          margin-right: 34px;
        }
        &:nth-child(2) {
          margin-right: 16px;
        }
        &-icon {
          .type-sfpro-regular();
          background: #151515;
          border: 1px solid #3c3c3c;
          color: #3c3c3c;
          display: inline-block;
          width: 28px;
          height: 28px;
          font-size: 12px;
          border-radius: 100%;
          text-align: center;
          line-height: 26px;
          margin-right: 8px;
        }

        &-label {
          .type-sfpro-regular();
          color: #747474;
          font-size: 12px;
        }

        &.active {
          .steps-item-icon {
            color: #000;
            background: #ffb917;
            border-color: #ffb917;
          }
          .steps-item-label {
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
  .payment-form {
    .trend-label {
      .type-sfpro-bold();
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.008em;
      color: #ffffff;
    }
  }
}

#new-campaign-page,
#edit-campaign-page {
  h1 {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: -0.008em;
  }
  .setup-steps {
    #sign-up-page.setup-steps();
    width: 459px;
  }
  @media (min-width: 992px) {
    .ant-col-lg-10 {
      width: 580px;
    }
  }
  .ant-form-item {
    padding-bottom: 0;
  }
  .ant-form-item {
    margin-bottom: 45px;
  }

  .ant-form-item-label {
    margin-bottom: 7px;
    padding-bottom: 0px;
    & > label {
      display: block;
      height: auto;
      color: #fff;
      .type-sfpro-bold();
      font-size: 14px !important;
      line-height: 26px;
      letter-spacing: -0.008em;
      .with-sub-label {
        line-height: 20px;
        .sub-label {
          .type-sfpro-regular();
          font-weight: normal;
          display: block;
          font-size: 12px;
          margin-bottom: 5px;
          color: #fff;
        }
      }
    }
  }

  .ant-input {
    .type-sfpro-regular();
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 14.5px;

    .ant-input-affix-wrapper.trend-input.dark .ant-input-prefix {
      font-size: 15px;
    }
  }

  .ant-input-prefix-touch {
    color: #ffb917 !important;
  }

  .ant-input-prefix {
    .type-sfpro-regular();
    color: #989898;
    left: 0;
    padding-bottom: 13px;
    line-height: 18px;

    & + .ant-input {
      padding-left: 18px;

      &::placeholder {
        color: #989898;
      }
    }
  }
  .ant-select {
    .type-sfpro-regular();
  }

  h2 {
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    .sub-header {
      color: #fff;
      .type-sfpro-light();
      font-size: 14px;
      line-height: 24px;
      margin-top: 5px;
      margin-bottom: 45px;
    }
  }

  .ant-input.trend-textarea {
    padding: 16px 15px;
    background: #151515;
    .type-sfpro-regular();
    font-size: 15px;
    line-height: 18px;
    &::placeholder {
      font-size: 15px;
      color: #989898;
    }
  }

  .show-campaigns-to-vip {
    border-radius: 2px !important;
    &:before,
    &:after {
      .type-sfpro-regular();
      font-size: 15px;
      padding: 0;
      line-height: 34px;
      top: 0px;
      height: 32px !important;
      width: 51% !important;
    }
    &:after {
      border-top-left-radius: 2px !important;
      border-bottom-left-radius: 2px !important;
    }
    &.ant-switch-checked {
      &:after {
        left: 50% !important;
        border-top-right-radius: 2px !important;
        border-bottom-right-radius: 2px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }
  .ant-btn.ant-btn-primary,
  .trend-btn.product-btn {
    .type-sfpro-iheavy();
    border-radius: 32px;
    font-size: 16px;
    line-height: 18px;
    height: auto;
    padding: 18px 40px;
    color: black;
    text-shadow: none;
    margin-bottom: 30px;
  }
  .ant-steps-item-title {
    color: #fff;
    &::after {
      content: none;
    }
  }

  .sub-header {
    .type-sfpro-regular();
    font-size: 18px;
    line-height: 26px;
    color: #999;
    letter-spacing: -0.008em;
  }

  .trend-label {
    color: #fff;
    font-style: normal;
    font-size: 18px;
  }

  .StripeElement {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-color: #2a2a2a !important;
    border: 1px solid;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none !important;
    color: #eee;
    font-weight: bold;
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 16px;
    margin-bottom: 25px;

    .input {
      height: 50px;
      color: @color-orange;
    }
    font-size: 24px;
    &::placeholder {
      color: @color-black-ter;
    }
  }

  .product-uploader {
    .product-label {
      padding-top: 116px;
    }

    .anticon {
      background: #3c3c3c;
      border-radius: 2px;
      margin-bottom: 18px;
    }
    .label {
      text-align: center;
      .type-sfpro-regular();
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 4px;
    }
    .sub-label {
      .type-sfpro-regular();
      font-style: normal;
      font-weight: normal;
      line-height: 14px;
      font-size: 10px;
      text-align: center;
      color: #ffffff;
    }
  }
  .ant-input {
    padding-left: 0px;
    padding-top: 0px;
    &::placeholder {
      font-size: 15px;
    }
  }
  .ant-input-number {
    line-height: 18px;
    .ant-input-number-input[value='$ '] {
      color: #989898;
    }
  }
  .ant-input-number-input-wrap {
    line-height: normal;
  }
  .ant-input-number-input {
    .type-sfpro-regular();
    padding-left: 0px;
    height: auto;
    line-height: 18px;
    font-size: 15px;
    &::placeholder {
      color: #989898;
    }
  }

  .ant-form-item-control {
    &.has-error {
      .ant-input {
        border-color: #f5222d !important;
        background-color: #000;
      }
      .ant-input-number {
        border-color: #f5222d !important;
      }
    }
    .ant-form-explain {
      .type-sfpro-regular();
      position: absolute;
    }
  }

  .ant-tabs-bar.ant-tabs-left-bar {
    width: 108px;
    border-right-color: #3c3c3c;
    padding-top: 36px;
    .ant-tabs-nav .ant-tabs-tab {
      .type-sfpro-bold();
      color: #999;
      text-transform: uppercase;
      text-align: left;
      font-size: 12px;
      padding: 0px;
      padding-left: 31px;
      margin-bottom: 19px;
      &:hover {
        color: @primary-color;
      }

      span {
        &.hasError {
          color: #ff7b7b;
          &::after {
            background-color: #ff7b7b;
          }
        }
        &::after {
          transition: width 0.5s;
          content: '';
          position: absolute;
          left: 0px;
          height: 3px;
          border: none;
          border-radius: initial;
          width: 10px;
          top: 0px;
          margin-top: 7px;
          background-color: #999;
        }
      }

      &-active {
        span {
          color: @primary-color;
          &.hasError {
            color: @primary-color;
          }
          &::after {
            transition: width 0.5s;
            width: 20px !important;
            background-color: @primary-color !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }

  .ant-tabs .ant-tabs-left-content {
    border-left-color: #3c3c3c;
    padding-left: 0px;
  }

  .page-header {
    color: #fff;
    .type-sfpro-heavy();
    font-style: normal;
    font-size: 24px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .page-description {
    margin-bottom: 35px;
    color: #999;
    .type-sfpro-regular();
    font-size: 16px;
    line-height: 26px;
  }

  .page-other-details {
    h3 {
      .type-sfpro-bold() !important;
      font-style: normal;
      font-size: 14px;
      line-height: 17px;
      color: #989898;
      margin-bottom: 10px;
    }

    .ant-btn {
      margin-bottom: 0px;
    }
    img {
      width: 292px;
    }
    p {
      .type-sfpro-regular();
      color: #989898;
      width: 292px;
      margin-bottom: 35px;
    }
  }
}

.example-campaign {
  .media {
    margin-top: 133.5px;
  }
  img {
    width: 411px;
    height: 197px;
    margin-bottom: 37px;
  }
  h1 {
    font-size: 26px;
    color: #000;
    padding: 0;
    margin-bottom: 8px;
  }
  p {
    .type-sfpro-regular();
    font-size: 16px;
    color: #000;
    line-height: 24px;
  }
}
.bulk-actions {
  .trend-btn {
    height: 35px;
  }
  .ant-btn.yotpo-btn {
    .ant-btn.trend-btn();
    .type-firme-iblack();
    .size-10px();
    height: 35px;
    margin-right: 10px;
    background: #1776ec;
    min-width: 153px;
    text-align: left;
    color: #fff;

    img {
      position: absolute;
      right: 11px;
      top: 6px;
      object-fit: contain;
      vertical-align: top;
      height: 23px;
      width: 23px;
    }
    &:hover {
      color: #fff;
      background: #135fbd;
    }
  }
}

.trend-post-card,
.trend-post-card-content {
  .yotpo-btn {
    img {
      margin-top: -3px;
      vertical-align: top;
      width: 24px;
      height: 24px;
    }
  }
}

#recent-campaign-page {
  h1 {
    margin-top: 38px;
    margin-bottom: 35px;
    font-size: 28px;
  }
}
