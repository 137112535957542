// Variable
// --------------------------------------------------------

@unit: 5px !important;

// Margin
// --------------------------------------------------------

@import 'margin.less';

// Padding
// --------------------------------------------------------

@import 'padding.less';

.content-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.logo-mobile {
  display: none;
}

.logo-wrapper {
  padding: 20px 0px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.brand-image {
  .ant-avatar-image,
  .ant-avatar {
    border-radius: 0;
    background-color: #000;
    width: 100%;
    height: 200px;
  }
}

// Layout
// --------------------------------------------------------

.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}

// Display
// --------------------------------------------------------

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

// Floats
// --------------------------------------------------------

.f-left {
  float: left;
}
.f-right {
  float: right;
}
