//Default empty state components

.empty-no-applications {
  .ant-empty-image {
    height: 280px;
  }
}

.empty-no-messages {
  .ant-empty-image {
    height: 220px;
  }
}

.empty-desc-header {
  font-size: 25px;
  font-weight: normal;
  display: block;
  .type-sfpro-bold();
  .text-black();
}
.empty-desc-body {
  .size-16px();
  .text-black();
  line-height: 23px;
  .type-sfpro-regular();
}
