#open-store-page {
  .trend-steps {
    margin-top: 47px;
  }
  h1 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    width: 422px;
    margin-bottom: 13px;
    line-height: 42px;
    margin-top: 59px;
  }
  .sub-header {
    .type-sfpro-regular();
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    color: #999;
    height: 75px;
    margin-bottom: 10px;
  }

  .trend-textarea {
    background: #151515;
    padding: 16px 15px 17px 15px;

    &::placeholder {
      color: #989898;
    }
  }
  .submit {
    .ant-btn {
      .type-sfpro-regular();
      color: #000;
      height: 58px;
      width: 258px;
      font-style: italic;
      border-radius: 32px;
      font-weight: 800;
      font-size: 20px;
      line-height: 18px;
      margin-bottom: 63px;
    }
  }

  .ant-form-item {
    margin-bottom: 40px;
  }
  .ant-form-item-label {
    line-height: 26px;
    margin-bottom: 7px;
    & > label {
      color: #fff;
      .type-sfpro-regular();
      font-weight: bold;
    }
  }
}
