
table {
  .message-col {
    cursor: pointer;
  }
}

.messages-table {
  td.avatar {
    width: 55px !important;
  }
  td.author {
    width: 200px;
  }
}
