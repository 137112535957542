.trend-table {
  .multi-select {
    width: 66px;
  }
  .col-action {
    width: 200px;
    text-align: right;
    .cell-action {
      float: left;
    }
  }

  .cell-action {
    text-align: left;
    float: right;
    border-left: 1px solid #e8e8e8;
    padding-left: 30px;

    .link span {
      &:hover {
        .type-bold();
        color: #000 !important;
      }
    }

    .ant-btn-circle {
      &:hover,
      &:focus {
        border-color: @color-black !important;
        .text-black() !important;
      }
    }
  }

  .ant-table-thead {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 35px;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }

  .ant-table-tbody > tr {
    &.ant-table-row-selected {
      > td {
        &:first-child:before,
        &:last-child:before {
          background: #fafafa;
        }
      }
    }
    & > td {
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &.ant-table-selection-column {
        text-align: left;
      }
      &:first-child:before,
      &:last-child:before {
        content: '';
        position: absolute;
        top: 1px;
        width: 500px;
        height: 100%;
        border-bottom: 1px solid #e8e8e8;
        transition: all 0.3s;
      }

      &:first-child:before {
        left: -500px;
      }
      &:last-child:before {
        right: -500px;
      }
    }

    &:hover > td {
      &:first-child:before,
      &:last-child:before {
        background: #f9f9f9;
      }
    }
  }

  .ant-table-thead > tr > th {
    &:extend(.ht-5);
    .type-sbold();
    font-size: 8px;
    letter-spacing: 0.5px;
    color: @color-pigeon-dark !important;
    background: transparent !important;
    padding: 11px 0 !important;

    &.ant-table-selection-column-custom {
      padding: 4px 0 0 0 !important;
    }

    div {
      display: inline-block;
    }

    &.influencer-width {
      width: 30%;
      .pl-0() !important;
    }

    &.influencer-width-shipment {
      width: 40%;
      .pl-0() !important;
    }

    &.post-width {
      width: 15%;
    }

    &.comments,
    &.likes {
      width: 12%;
    }
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f9f9f9;
  }

  .ant-table-tbody > tr > td {
    .type-regular();
    font-size: 11px;
    color: @color-black;

    &.avatar {
      padding-right: 0;
    }

    &.influencer-width {
      width: 25%;
      .pl-0() !important;
    }

    &.location {
      width: 20%;
    }

    &.author {
      p:first-child {
        margin: 0;
        padding-top: 5px;
        font-size: 12px;
        font-family: 'truenoextrabold';
        color: @color-black;
      }

      p {
        font-size: 11px;
        color: @color-pigeon-dark;
      }
    }

    &.time {
      padding-left: 0;
      width: 15%;
      color: @color-pigeon-dark;
      font-size: 9px;
      text-align: right;
    }

    &.count {
      .ant-avatar {
        color: @color-black;
        font-size: 9px;
        width: 28px;
        height: 28px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @color-orange;
        border-color: @color-orange;
      }
    }

    .ant-checkbox-inner {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: #f4f4f4;
      border: 1px solid #dadada;

      &:after {
        display: none;
      }
    }
  }
}

.messages-table {
  .ant-table-thead {
    display: none;
  }

  .author {
    width: 15%;
  }
  .padded-table-list tbody td.avatar {
    padding-left: 0 !important;
  }

  tbody > tr {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }
}

.table-awaiting {
  .post-width {
    .ant-avatar {
      background: #fff;
      width: 50px;
      height: 50px;
      border: 1px dashed #888;
      border-radius: 0;
    }
  }
}

.table-approved {
  .post-width {
    .ant-avatar {
      border-radius: 0;
    }
  }
}

.ant-table-placeholder {
  border-bottom: 0 !important;
}

@media (min-width: 1441px) {
  .padded-table-list {
    tbody {
      td.avatar {
        padding-left: 300px !important;
      }
      td.count {
        padding-right: 300px !important;
      }
    }
    ul.ant-table-pagination {
      margin-right: 300px !important;
    }
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) and (max-width: 1440px) {
  .padded-table-list {
    tbody {
      td.avatar {
        padding-left: 110px !important;
      }
      td.count {
        padding-right: 110px !important;
      }
    }
    ul.ant-table-pagination {
      margin-right: 110px !important;
    }
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .padded-table-list {
    tbody {
      td.avatar {
        padding-left: 105px !important;
      }
      td.count {
        padding-right: 105px !important;
      }
    }
    ul.ant-table-pagination {
      margin-right: 105px !important;
    }
  }
}

/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .padded-table-list {
    tbody {
      td.avatar {
        padding-left: 20px !important;
      }
      td.count {
        padding-right: 20px !important;
      }
    }
    ul.ant-table-pagination {
      margin-right: 20px !important;
    }
  }
}

/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .padded-table-list {
    tbody {
      td.avatar {
        padding-left: 20px !important;
      }
      td.count {
        padding-right: 20px !important;
      }
    }
    ul.ant-table-pagination {
      margin-right: 20px !important;
    }
  }
}

.trend-table-actions {
  background: #f8faff;
  border-bottom: solid 1px #dbdfe8;
  padding: 13px 0px;

  .actions {
    position: relative;

    .selected-count {
      position: absolute;
      top: 6px;
      right: 0px;
      font-family: 'FirmeBlackItalic';
      font-size: 16px;
      font-weight: 900;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.38;
      letter-spacing: 1px;
      text-align: right;
      color: #5b6572;
    }
    .hide {
      display: none;
    }
    button.ant-btn {
      border-radius: 0;
      height: 35px;
      text-transform: uppercase;
      .type-firme-iblack();
      font-size: 9px;
      margin-right: 10px;
      color: #5b6572;
      vertical-align: middle;
      text-align: right;
      padding-right: 13px;
      line-height: 1.11;
      letter-spacing: 1px;
      color: #5b6572;

      .anticon,
      img {
        position: absolute;
      }

      &.message {
        width: 141px;

        img {
          width: 20px;
          font-size: 20px;
          left: 12px;
          top: 8px;
        }
      }
      &.cancel {
        width: 96px;
        .anticon {
          font-size: 21px;
          left: 12px;
          top: 7px;
        }
      }
      &.send {
        width: 130px;
        img {
          width: 17px;
          font-size: 17px;
          left: 12px;
          top: 9px;
        }
      }
    }
  }
}
