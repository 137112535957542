.brand-notifications {
  .note {
    color: #999999;
    .type-sfpro-regular();
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.008em;
    margin-top: 4px;
  }

  .notif-container {
    background: #0F0F0F;
    border: 1px solid #3C3C3C;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;
    .type-sfpro-bold();
    font-size: 18px;
    line-height: 30px;
    height: 233px;
    margin-top: 41px;
    padding: 35px 33px 38px 40px;
    width: 484px;

    .new-message-row, .new-post-row {
      margin-top: 34px;
    }

    .notif-row {
      .notif-action {
        text-align: right;
      }
    }
  }

  .notif-switch-action {
    border: 1px solid #3c3c3c !important;
    height: 31px;
    width: 51px;

    &:after {
      height: 28px;
      top: 0;
      width: 28px;
    }
  }
}