.trend-post-card, .trend-post-card-content {
  &:hover {
    box-shadow: 0px 0 37px -11px #000;
  }

  .ant-card-meta-title {
    .size-16px();
    .text-black();
    .type-sbold();
  }

  .ant-card-meta-description {
    font-size: 11px;
    .text-pigeon-dark();
  }

  .ant-card-meta-avatar {
    padding-right: 10px;
  }

  .ant-card-meta-detail {
    vertical-align: middle;
    width: calc(100% - 50px);
    .ant-card-meta-title {
      text-overflow: ellipsis;
      display: block;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ant-card-meta-detail,
  .ant-card-meta-avatar {
    display: inline-block;
  }

  .ant-card-cover {
    position: relative;
    overflow: hidden;
    background: #e8e8e8;
    height: 307px;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .ant-card-body {
    position: relative;
  }

  .ant-card-actions {
    .spin-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      .download {
        display: flex;
        align-items: center;
      }
    }

    span {
      margin-right: 8px;
    }
  }
}

// Override avatar to fix scaled images

.ant-avatar > img {
  object-fit: cover;
}

.post-avatar {
  position: relative;
  display: inline-block;

  .play-button {
    position: absolute;
    z-index: 1;
    font-size: 30px;
    left: 10px;
    top: 10px;
  }

  .story-type {
    color: #000;
    position: absolute;
    padding-top: 2px;
    height: 15px;
    top: 45px;
    width: 59px;
    left: -4px;
    text-align: center;
    text-transform: uppercase;
    font-family: FirmeBlackItalic;
    font-size: 8px;
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: 0.4px;
    text-align: center;
    color: #000000;

    &.type-image {
      background: #ffd982;
    }

    &.type-video {
      background: #4df1ff;
    }

    &.type-story {
      background: #66bdff;
    }
  }
}

.post-filter {
  button {
    height: 100%;
    color: #5b6572;
    padding-bottom: 14px;
    &:focus,
    &:active,
    &:hover {
      color: #000;
    }
  }

  &.post-filter:not(&-selected) {
    button {
      &:focus,
      &:active,
      &:hover {
        color: #000;

        .post-filter-icon {
          background: #000;
          color: #fff;
          transition: all 0.5s;

          svg {
            g,
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }

        .post-filter-label {
          color: #000;
        }
      }
    }
  }

  &-selected {
    border-bottom: 3px solid @primary-color;

    button {
      color: #000;
      &:focus,
      &:active,
      &:hover {
        color: #000;
      }
    }

    .post-filter-icon {
      background: #000;
    }

    &.post-filter {
      &-all,
      &-images,
      &-videos,
      &-stories {
        .post-filter-icon {
          color: #fff;
          transition: all 0.5s;

          svg {
            g,
            path {
              fill: #fff;
              stroke: #fff;
            }
          }
        }
      }
    }

    .post-filter-label {
      color: #000;
    }
  }

  &-icon {
    height: 51px;
    width: 51px;
    display: inline-block;
    border: 1px solid #a9b0b9;
    border-radius: 100%;
    margin-right: 15px;
  }

  &-count {
    font-size: 16px;
    font-family: 'TruenoExtraBold';
    text-align: left;
  }

  &-label {
    font-size: 9px;
    font-family: 'TruenoSemiBold';
    letter-spacing: 0.5px;
    color: #5b6572;
  }

  &-all {
    .post-filter-icon {
      font-family: 'TruenoBold';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      object-fit: contain;
      padding: 16px 13px;
      border-radius: 100%;
    }
  }

  &-images {
    .post-filter-icon {
      font-size: 18px;
      padding-top: 16px;

      .anticon {
        vertical-align: top;
      }
    }
  }

  &-videos {
    .post-filter-icon {
      font-size: 23px;
      padding-top: 16px;
    }

    .anticon {
      vertical-align: top;
      svg {
        height: 18px;
      }
    }
  }

  &-stories {
    .post-filter-icon {
      font-size: 24px;
      padding-top: 16px;
    }

    .anticon {
      vertical-align: top;
      svg {
        height: 18px;
      }
    }
  }
}

#all-post-page {
  h1 {
    margin-top: 38px;
    margin-bottom: 35px;
    font-size: 28px;
  }

  .post-card {
    width: 360px;
    height: auto;
    margin-bottom: 28px;
    background: transparent;

    &.trend-post-card, .trend-post-card-content {
      background: transparent;
      .ant-card-cover {
        border-radius: 5px 5px 0px 0px;
        background: transparent;
        img {
          border-radius: 5px 5px 0px 0px;
          object-fit: cover;
          height: 307px;
        }

        video {
          height: 307px;
          object-fit: cover;
        }
      }
      &:hover {
        border-radius: 5px 5px 0px 0px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
      }

      .ant-card-meta-detail {
        width: calc(100% - 60px);
      }
    }

    .ant-card-body {
      border: 1px solid #e8e8e8;
      height: auto;

      .ant-avatar {
        width: 50px !important;
        height: 50px !important;
      }

      h2 {
        font-size: 16px !important;
      }
      .caps-heading {
        font-size: 10px !important;
      }
    }

    .post-stats {
      margin-top: 19px !important;
    }
  }

  .post-filters {
    margin-bottom: 49px;
    .ant-checkbox + span {
      vertical-align: top;
      padding-left: 9px;
      line-height: normal;
    }

    .ant-checkbox-wrapper {
      margin-left: 25px !important;
    }

    .trend-select {
      .ant-select-selection__placeholder {
        margin-top: -11px;
      }
      .ant-select-selection-selected-value {
        margin-top: -1px;
      }
    }
  }
}
