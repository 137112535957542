.trend-radio-buttons.ant-radio-group-solid {
  width: 100%;
  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    padding: 10px 10px;
    height: 51px;
    background-color: #151515;
    color: #d9d9d9;
    border-color: #3c3c3c;

    &:not(:first-child):before {
      background-color: #3c3c3c;
    }

    &-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #000;
      background: #fff;
      border-color: #3c3c3c;
      box-shadow: -1px 0 0 0 #fff;
      &:hover {
        color: #000;
        border-color: #fff;
        background: #fff;
      }
    }
    .ant-radio-button {
      .type-sfpro-regular();
    }

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
}

#campaign-form_gender {
  &.trend-radio-buttons {
    .ant-radio-button-wrapper {
      width: 33.3333%;
    }
  }
}
