.product-shipment-table {
  .ant-table-content {
    border-top: 1px solid #e8e8e8;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }
}
