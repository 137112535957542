.video-player {
  position: relative;
  &-actions {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    i {
      font-size: 85px;
    }
  }
}
