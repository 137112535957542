.trend-menu-horizontal.ant-menu-horizontal {
  border: 0;

  .ant-badge,
  .ant-badge-count {
    font-size: 9px;
    color: @color-black;
    .type-bold();
    position: absolute;
    left: 0;
    right: 0 !important;
  }

  .ant-badge {
    margin-top: -10px;

    &-count {
      margin: auto;
      padding: 0;
      width: 20px;
      background: @color-orange;
    }
  }

  .ant-menu-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 50px;
    border: 0 !important;
    top: 0 !important;
    padding: 0 !important;

    a {
      padding: 12px 0;
      font-size: 14px;
      .type-sfpro-regular();
    }

    &:hover {
      padding: 0;

      a {
        color: #000 !important;
      }
    }
  }

  & > .ant-menu-item-selected {
    border: 0 !important;

    & > a {
      .type-sfpro-bold();
      color: @color-black !important;

      &:after {
        content: '';
        clear: both;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 31px;
        height: 3px;
        background-color: @color-orange;
      }
    }
  }
}

.account-menu {
  border: 0 !important;
  background: transparent !important;

  & > .ant-menu-item {
    & > a {
      padding-bottom: 14px;
      font-size: 15px;
      color: #747474 !important;
    }

    &-selected {
      & > a {
        .type-bold();
        color: @color-white !important;
      }
    }
  }
}

.trend-menu-sub {
  display: block;
  width: 100%;
  background: transparent !important;

  .ant-menu-item {
    border: 0 !important;

    margin-right: 20px !important;
    font-size: 9px;
    .type-bold();

    .ant-menu-icon {
      float: left;
      width: 51px;
      height: 51px;
      margin-right: 12px;
      border: 1px solid #a9b0b9;
      background: #fff;
      border-radius: 50%;

      svg {
        stroke: @color-pigeon-dark;
      }

      .anticon {
        margin: 0;
        padding: 2px;
      }

      a {
        padding-bottom: 8px;
      }
    }

    a {
      padding-bottom: 2px !important;
    }

    &-selected {
      & > a {
        &:after {
          margin: 0 !important;
          width: 190px !important;
        }
      }

      .ant-menu-icon {
        border: 0;
        background-color: @color-black;

        svg {
          stroke: #fff;
        }
      }
    }
  }

  .tabs-value {
    display: block;
    font-size: 16px;
    font-style: italic;
    .type-bold();
  }
}

.trend-menu-inline {
  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0px 16px !important;
  }

  a.campaign-link.first-campaign {
    display: inline;
    .add-campaign {
      animation: radar-animation 1s ease-out infinite;
      border-radius: 100%;
      z-index: 10;
    }
  }
}
@keyframes radar-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 249, 249, 1);
  }
  100% {
    box-shadow: 0 0 0 7px rgba(249, 193, 202, 0);
  }
}
// Sider

.ant-menu-inline {
  &.settings {
    .ant-menu-item {
      position: fixed;
      bottom: 0px;
      width: 20px !important;

      a {
        .box-border {
          background-color: #151515;
          line-height: 1.9;
        }
        position: fixed;
        bottom: 0;
        left: 0;
        height: 65px;
        background: #151515 !important;
        border-top: 1px solid #3c3c3c;
        padding: 12px;
        .type-sfpro-regular();

        svg {
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
    }
  }
  &.settings.with-notification {
    .ant-menu-item {
      a {
        margin-bottom: 68px;
      }
    }
  }

  &.secondary {
    .pb-6();
    min-height: 80px;

    .ant-menu-item {
      height: 50px !important;
      margin-bottom: 0 !important;
      margin-top: 2px;

      a {
        padding: 5px 0;
      }

      svg {
        float: left;
        margin: 8px 15px 15px 8px;
      }
    }
  }

  &.trend-menu-inline {
    .ant-menu-submenu-title {
      margin: 0 !important;
      height: 100% !important;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 1 !important;
      cursor: default;
      pointer-events: none;

      &:hover {
        background: transparent !important;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding-left: 15px !important;
      margin-bottom: 4px !important;

      &:hover {
        background-color: #161616;
      }

      a {
        .size-11px();
        .text-gray-dark();
        .type-sfpro-regular();
        span:not(.box-border) {
          .type-sfpro-regular();
          font-weight: 600;
          .size-11px();
        }
      }
    }

    .ant-menu-sub {
      .ant-menu-item {
        height: 50px;
        margin: 0;
        padding: 5px 0;
        margin-bottom: 4px;
      }
    }
  }
}

.ant-layout-sider-children {
  .ant-menu-item-selected {
    background-color: #2b2b2b !important;

    a {
      color: #fff !important;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px !important;
      width: 3px;
      height: 100%;
      background-color: @color-orange;
    }
  }
}
.add-credit-message {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 68px;
  line-height: 68px;
  .type-sfpro-heavy();
  color: #fff;
  background: #000;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
  z-index: 50;

  &.expired {
    p {
      padding-right: 50px;
      color: #ffb917;
      font-weight: 800;
    }
  }
  p {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -0.008em;
    display: inline-block;
    /* show the marquee just outside the paragraph */
    animation: marquee 8s linear infinite;
  }

  .countdown {
    margin-left: 20px;
    margin-right: 20px;
    color: #ffb917;
  }
}

/* Make it move */

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    display: none;
    transform: translate(-100%, 0);
  }
}

.with-notification {
  margin-bottom: 68px;
}

.campaign-menu {
  .ant-menu-sub {
    li {
      padding-left: 30px !important;
    }
  }

  .ant-menu-item {
    height: 50px !important;
    line-height: 49px !important;
    padding-right: 0 !important;
  }

  .campaign-link {
    display: block;
    .ant-row-flex {
      height: 50px;
      max-height: 50px;
    }
    .text-wrap();
    .ant-avatar,
    .campaign-item {
      width: 36px;
      float: left;
    }
    .campaign-item {
      padding-right: 12px;
      width: calc(100% - 36px - 9px);
    }
  }

  .campaign-avatar {
    .f-left();
    margin-right: 9px;
    border-radius: 0;
    position: relative;
    overflow: hidden;
  }

  .campaign-item {
    .size-11px;
    .type-sfpro-regular();
    font-weight: 600;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    line-height: 1.4;
    width: 100%;
  }
}

.trend-campaign-avatar {
  position: relative;
  overflow: hidden;
  background: #1f1f1f !important;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Sidebar

.ant-layout-sider-children {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

.content-credit-text {
  padding: 10px 10px 29px 15px;
}

.side-small-title {
  .text-gray-darker();
  .spacing-05px();
  .size-8px();
  .type-sbold();
}

.credit-title {
  .type-sfpro-regular();
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  line-height: 18px;
  letter-spacing: -0.004em;
  font-style: italic;

  &-block {
    line-height: 0.5;
    .size-12px();
    .text-white();

    .credit-desc {
      .type-sfpro-regular();
      font-weight: bold;
      letter-spacing: -0.008em;
      font-size: 10px;
      line-height: 14px;
      margin-bottom: 17px;
    }
  }
}

.credit-value {
  .type-sfpro-regular();
  height: 28px;
  font-size: 26px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: normal;
  font-style: italic;
  display: inline-block;
  width: 100%;

  &.standard {
    background: linear-gradient(257deg, #f7f7f7, #b2bacb);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.vip {
    background-image: linear-gradient(248deg, #f7f7f7, #ceb785);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.credit-add.ant-btn {
  color: #000;
  border-radius: 25px;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;
  font-style: italic;
  padding: 10px 0;
  height: auto;
  .type-sfpro-regular();
  span {
    .type-sfpro-regular();
    font-weight: 800;
    display: inline;
  }
}

.package-title {
  .type-ebold();
  .size-20px();
  padding-top: 2px;
  margin-bottom: 7px;
  color: #3c3c3c;
}

.trend-menu-item-dark {
  background-color: #151515 !important;
  overflow: scroll;
  .ant-dropdown-menu-item {
    background-color: #151515 !important;
    color: #5a626e !important;
    font-size: 10px;
    &:hover {
      background-color: #000 !important;
    }
  }
}
.trend-selected-tags-dropdown {
  background-color: #151515 !important;
}

.trend-select-states-selector {
  .trend-selected-tags .ant-select-selection__rendered ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline;
    li.ant-select-selection__choice {
      display: inline-block;
      background: #ffb917 !important;
      border-radius: 2px;
      border: 0 !important;
      height: 34px;
      padding: 7px 12px;
      margin-bottom: 8px;
      margin-right: 10px;
      font-size: 11px;
      color: #000000;
    }
  }

  .trend-selected-tags .ant-select-selection__rendered {
    background-image: none;
    width: auto;
    height: auto !important;
    margin-right: 33px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
  }

  .trend-dropdown-option {
    &:hover {
      background-color: #000 !important;
    }
  }

  .trend-states-custom-caret {
    position: absolute;
    color: #ffffff;
    left: 100%;
    margin-left: -25px;
    top: 50% !important;
    z-index: 1 !important;
    cursor: pointer;
  }
}

.trend-selected-tags {
  .ant-select-selection--multiple {
    width: 100% important;
    display: 'inline-block';
    cursor: pointer;
    &::before {
      font-family: FontAwesome;
      content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='white' stroke='none'><path d='M0,0 L5,6 L10,0 L0,0 Z'></path></svg>");
      padding-left: 0.5em;
      vertical-align: middle;
      position: absolute;
      right: 9px;
      top: 48%;
      color: #ffffff;
    }
  }
}

.trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 64px;
  z-index: 1 !important;
  cursor: pointer;
}

.trend-selection_choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  padding: 7px 24px 7px 12px;
  font-size: 11px;
  color: #000000;
  margin-right: 8px;
  min-width: 34px;
  margin-right: 10px;
  margin-bottom: 10px !important;
}

.trend-dropdown-option {
  &:hover {
    background-color: #000 !important;
  }
}
.side-menu {
  .anticon.add-campaign {
    font-size: 36px;
    color: #fff;
    vertical-align: middle;
  }
}
