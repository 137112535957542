.product-uploader {
  position: relative;
  overflow: hidden;
  background: #151515;
  height: 326px;

  .product-label {
    display: block;
    height: 326px;
    cursor: pointer;
    border: 1px solid #3c3c3c;
    padding: 107px 0;
    color: #747474;
    font-size: 13px;
  }

  .icon {
    color: #fff;
    padding: 14px;
    background: #222121;
    width: 52px;
    height: 52px;
    font-size: 22px !important;
  }
}

.action-link {
  .back,
  .sign-out {
    position: absolute;
    z-index: 2;
    top: 31px;
  }

  .back {
    left: 35px;
  }

  .sign-out {
    right: 35px;
  }
}

.product-image {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.gender-select {
  .ant-select-selection-selected-value {
    font-size: 14px !important;
  }
}

.select-arrow-down-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}
.yotpo-field {
  img {
    width: 73px;
    height: 73px;
  }
}
