.ant-input.trend-input {
  &-bordered {
    border: 1px solid #3c3c3c;
    background: #151515;
    border-radius: 2px;
    padding: 16px 15px 17px 15px;
    font-size: 15px;
    color: @primary-color;
    height: 51px;

    &::placeholder {
      color: #989898;
    }
  }
}
