#admin-layout {
  .ant-layout-header {
    background: #000;
    height: 62px;
  }

  .logo {
    position: absolute;
    top: 0;
    right: 43px;
  }

  .back a {
    color: #fff;
    .anticon {
      font-size: 18px;
      margin-right: 11px;
    }
  }
}

@primary-color: #ffb917;@info-color: #ffb916;@input-addon-bg: transparent;@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;