html,
body {
  height: auto;
  width: 100%;
}
.progress-flat .ant-progress-bg {
  height: 4px !important;
  border-radius: 0px !important;
}
.trend-label {
  color: #747474;
  font-style: italic;
}
.hashtag-input.ant-input-affix-wrapper .ant-input {
  padding-left: 27px !important;
}
.hashtag-input.ant-input-affix-wrapper.dark.focused .ant-input-prefix {
  color: #FFB917;
}
.brandtag-input.ant-input-affix-wrapper.dark.focused .ant-input-prefix {
  color: #FFB917;
}
.ant-input-affix-wrapper.trend-input .ant-input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #FFB917;
  padding-bottom: 12px;
}
.ant-input-affix-wrapper.trend-input .ant-input:focus {
  border-color: #FFFFFF !important;
}
.ant-input-affix-wrapper.trend-input .ant-input-prefix {
  font-size: 16px;
  padding-bottom: 9px;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input-prefix {
  color: #3A3A3A;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input {
  background: none;
}
.ant-input-affix-wrapper.trend-input.dark .ant-input::placeholder {
  color: #3A3A3A;
}
input.ant-form-item-control.has-error .trend-input.dark {
  background: none;
}
.ant-input.trend-input,
.ant-input-number.trend-input,
.product-form .trend-tag-plain {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #FFB917;
  padding-bottom: 12px;
}
.ant-input.trend-input:focus:not(.inverse),
.ant-input-number.trend-input:focus:not(.inverse) {
  border-color: #000000 !important;
}
.ant-input.trend-input.large,
.ant-input-number.trend-input.large {
  height: 50px;
  padding-bottom: 15px;
  font-size: 24px;
}
.ant-input.trend-input.dark::placeholder,
.ant-input-number.trend-input.dark::placeholder {
  color: #989898;
}
.ant-input.trend-textarea,
.ant-input-number.trend-textarea {
  border: 1px solid #2a2a2a;
  background: #000000;
  border-radius: 0;
  padding: 17px;
  color: #FFB917;
  font-size: 15px;
}
.ant-input.trend-textarea::placeholder,
.ant-input-number.trend-textarea::placeholder {
  color: #3A3A3A;
}
.ant-input.trend-textarea:focus,
.ant-input-number.trend-textarea:focus {
  border-color: #FFFFFF !important;
}
.ant-form-item-label label,
.trend-form-label {
  font-size: 13px !important;
  color: #000000;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.trend-select .ant-select-selection {
  border-radius: 50px !important;
  border-color: #a9b0b9 !important;
}
.trend-select .ant-select-selection--single {
  height: 30px;
}
.trend-select .ant-select-selection:focus {
  box-shadow: none;
}
.trend-select .ant-select-selection__placeholder {
  font-size: 10px !important;
  color: #5B6572 !important;
}
.trend-select .ant-select-selection__rendered {
  margin-left: 15px !important;
  margin-right: 15px !important;
  font-size: 10px !important;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 34px;
  line-height: 34px;
  width: 155px;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered > ul > li .anticon {
  padding-top: 2px;
  line-height: 34px;
  font-size: 20px;
  color: #000;
}
.trend-select-dark:not(.ant-select-open) .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  border-bottom: 0px;
}
.trend-select-dark .ant-select-selection {
  min-height: 51px;
  border-radius: 2px;
  background-color: #151515;
  border: 1px solid #3c3c3c;
  color: #747474;
  padding: 10px 10px 12px;
}
.trend-select-dark .ant-select-selection::before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
}
.trend-select-dark .ant-select-arrow-icon {
  color: #fff;
}
.trend-select-dark .ant-select-selection-selected-value {
  color: #ffb917;
  font-size: 15px;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered {
  line-height: 37px;
  margin: 0;
}
.trend-select-dark .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__placeholder {
  margin-left: 0;
}
.trend-select-dark.ant-select-no-arrow .ant-select-selection__rendered {
  margin: 0 !important;
}
.trend-select-dark ul li.ant-select-selection__choice {
  background: #ffb917;
  border: 0 !important;
  color: #000000;
}
.trend-select.ant-select-open .ant-select-selection {
  border-radius: 17px 17px 0px 0px !important;
  border-color: #a9b0b9 !important;
}
.trend-select.ant-select-open:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #a9b0b9;
  border-top: 0;
}
.trend-select:after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #a9b0b9;
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 13px;
}
.ant-select-dropdown {
  border: 1px solid #a9b0b9;
  border-radius: 0px 0px 17px 17px !important;
}
.ant-select-dropdown .ant-select-dropdown-menu {
  margin-top: 12px;
  margin-bottom: 12px;
}
.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  font-size: 10px !important;
  color: #5b6572 !important;
}
.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  border-radius: 0px !important;
}
.ant-select-dropdown .ant-select-dropdown-menu-item-active,
.ant-select-dropdown .ant-select-dropdown-menu-item:hover {
  background-color: #e8e8e8 !important;
  border-radius: 0px !important;
}
.trend-radio-group .ant-radio-button-wrapper {
  background: #000000 !important;
  border-radius: 0 !important;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;
}
.trend-radio-group .ant-radio-button-wrapper:not(:selected) {
  border-color: #494949;
}
.item-listing {
  position: relative;
  transition: all 0.18s ease-in;
}
.item-listing .ant-card-bordered {
  border: 0;
  box-shadow: none;
}
.item-listing .ant-card-body {
  box-shadow: 0 0 0 1px #f4f4f4;
}
.item-listing:hover .ant-card-body {
  box-shadow: none;
}
.item-listing:hover .ant-card-bordered {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
}
.item-campaign .ant-card {
  border: 0;
}
.item-campaign .ant-card-cover div {
  transition: all 0.18s ease-in;
  border-radius: 4px;
}
.item-campaign .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}
.item-campaign .ant-card:hover {
  box-shadow: none;
}
.item-campaign .ant-card:hover .ant-card-cover div {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
}
.item-campaign .ant-card-cover div {
  border-radius: 4px;
}
.item-campaign .campaign-user {
  margin-bottom: 5px !important;
  color: #5B6572;
  letter-spacing: 0.5px;
  font-size: 10px !important;
  font-family: 'TruenoSemiBold', Arial, sans-serif !important;
  text-transform: uppercase;
}
.item-campaign .campaign-name {
  font-size: 16px !important;
  color: #000000;
  line-height: 1.38;
}
.item-campaign .campaign-value {
  display: inline-block;
  position: relative;
  z-index: 2;
  font-size: 14px !important;
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #000000;
  margin-top: 6.8px;
}
.item-campaign .campaign-value:after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 11px;
  background: #ffe9b7;
}
.trend-message-input input::placeholder {
  font-size: 16px;
  color: #000000;
}
.product-form .ant-form-item-label label {
  font-size: 13px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  color: #a6a6a6;
}
.product-form .ant-input-number {
  color: #FFB917;
  width: 100%;
  height: 40px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border-radius: 0;
  font-size: 16px;
  background-color: transparent;
  box-shadow: none !important;
  padding-left: 0;
}
.product-form .ant-input-number:focus-within {
  border-bottom: 1px solid #FFFFFF !important;
}
.product-form .ant-input-number:focus-within,
.product-form .orange {
  color: #ffb917;
}
.product-form .ant-input-number-handler-wrap {
  background: transparent;
  border-left: 0;
}
.product-form .ant-input-number-handler-wrap .ant-input-number-handler-down {
  border-top: 0;
}
.product-form .ant-input-number-handler-wrap .ant-input-number-handler-active {
  background-color: #151515;
}
.product-form .trend-tag-plain {
  color: #3A3A3A;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  width: 100%;
  cursor: text;
}
.product-form input.trend-tag-plain {
  color: #FFB917;
}
.product-form .trend-tag {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  background: #FFB917 !important;
  border-radius: 2px;
  border: 0 !important;
  height: 34px;
  line-height: 34px;
  color: #000000;
}
.product-form .trend-tag.ant-tag .anticon.anticon-close {
  font-size: 20px;
  line-height: 21px;
  color: #000;
  vertical-align: middle;
}
.product-form .ant-switch[aria-checked='false'] {
  background: #3A3A3A;
}
.plain-radio {
  font-size: 11px !important !important;
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.plain-radio .ant-radio-button-wrapper {
  font-family: 'TruenoRegular', Arial, sans-serif;
  padding-left: 0;
  padding-right: 20px;
  box-shadow: none !important;
  border: 0 !important;
  color: #5b6572;
}
.plain-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  color: rgba(0, 0, 0);
}
.plain-radio .ant-radio-button-wrapper:hover {
  color: #000;
}
.ant-radio-checked .ant-radio-inner:after {
  transform: scale(1) !important;
}
.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  background: #f4f4f4 !important;
  border-color: #dadada !important;
}
.ant-radio-inner:after {
  width: 20px !important;
  height: 20px !important;
  left: -1px !important;
  top: -1px !important;
  border-radius: 30px !important;
}
.plain-radio-group .ant-radio-button-wrapper {
  color: #5B6572;
  font-size: 10px !important;
  letter-spacing: 0.5px;
  border-color: #a9b0b9;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #000 !important;
  border-color: #000 !important;
}
.plain-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
  opacity: 1;
  background-color: #fff !important;
}
.trend-terms-checkbox {
  position: relative;
}
.trend-terms-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffb917 !important;
  border-color: #ffb917 !important;
}
.trend-terms-checkbox .ant-checkbox-inner {
  width: 29px;
  height: 29px;
  background-color: #3c3c3c;
  border-color: #7a7a7a;
  border-radius: 0;
}
.trend-terms-checkbox .ant-checkbox-inner:after {
  opacity: 0;
  width: 29px;
  height: 29px;
  transform: rotate(0deg) !important;
  color: transparent;
}
.trend-terms-checkbox-mobile {
  position: relative;
}
.trend-terms-checkbox-mobile .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffb917 !important;
  border-color: #ffb917 !important;
}
.trend-terms-checkbox-mobile .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-color: #7a7a7a;
  border-radius: 4px;
}
.trend-terms-checkbox-mobile .ant-checkbox-inner:after {
  opacity: 0;
  width: 25px;
  height: 25px;
  transform: rotate(0deg) !important;
  color: transparent;
}
.custom-checkbox {
  position: relative;
  top: 1px;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffb917 !important;
  border-color: #ffb917 !important;
}
.custom-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #fff !important;
  border-color: #a9b0b9 !important;
  border-radius: 0;
}
.custom-checkbox .ant-checkbox-inner:after {
  opacity: 1;
  left: 1px;
  width: 21px;
  height: 21px;
  transform: rotate(0deg) !important;
  background-repeat: no-repeat;
}
label[for='cancelAll'] .custom-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: #a9b0b9 !important;
  border-color: #a9b0b9 !important;
  border-radius: 0;
}
label[for='cancelAll'] .custom-checkbox .ant-checkbox-inner:after {
  opacity: 1;
  left: 1px;
  width: 21px;
  height: 21px;
  transform: rotate(0deg) !important;
  background-image: url('../../images/icon-check-box-selected.svg');
  background-repeat: no-repeat;
}
.status-tag {
  position: absolute;
  top: 50px;
  left: 0;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  font-size: 8px !important;
  background-color: #FFB917;
  padding: 4px 8px;
}
.status-tag span {
  position: relative;
  z-index: 2;
}
.status-tag:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: -5px;
  left: 18px;
  width: 13px;
  height: 13px;
  background-color: #FFB917;
  transform: rotate(45deg);
}
.trend-selected-tags {
  margin-top: 15px !important;
}
.trend-selected-tags-dropdown {
  border: 1px solid #666;
}
.trend-selected-tags .ant-select-selection__rendered {
  width: 600px;
  overflow-x: auto;
  height: 55px !important;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000000 100%, #000000);
}
.trend-selected-tags .ant-select-selection__rendered ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
}
.trend-selected-tags .ant-select-selection__rendered ul li {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-uploader .image img {
  width: 100%;
  height: 112px;
  object-fit: cover;
}
.ant-btn.trend-btn {
  background: #FFB917;
  border: 0;
  color: #000000;
  font-size: 11px;
  font-family: 'TruenoBold', Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 2px;
}
.ant-btn.trend-btn:hover {
  background: #ffe8bc;
}
.ant-btn.trend-btn.secondary {
  height: 35px;
  padding-top: 2px;
  background: #f8faff;
  border: 1px solid #dbdfe8;
  box-shadow: none;
  font-size: 9px;
  color: #5B6572;
  letter-spacing: 1px;
}
.ant-btn.trend-btn.secondary:hover {
  background: #f0f2f9 !important;
}
.ant-btn.trend-btn.gray {
  background: #3A3A3A;
  color: #FFFFFF !important;
}
.ant-btn.huge-btn {
  height: 57px !important;
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: none !important;
  letter-spacing: 0;
}
.ant-btn.medium-btn {
  height: 45px !important;
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: none !important;
  letter-spacing: 0;
}
.ant-btn.reject {
  background: #ff5a5a !important;
}
.ant-btn.product-btn {
  height: 57px;
  padding: 16px 68px;
  font-size: 16px;
  font-family: 'TruenoBold', Arial, sans-serif;
  text-transform: none;
  letter-spacing: 0;
}
.ant-btn.product-btn.ant-btn-loading {
  padding: 16px 68px !important;
}
.action-btn {
  height: 34px;
  padding: 10px 15px;
  background: #000000;
  border: 1px solid #3c3c3c;
  font-size: 11px;
  color: #b0b0b0;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.edit-btn {
  position: absolute !important;
  z-index: 2;
  bottom: 15px;
  right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  line-height: 30px !important;
}
.sub-menu-btn {
  float: right;
  color: #b0b0b0;
  border-radius: 2px;
  border: 1px solid #3c3c3c;
  background: #151515;
  letter-spacing: 0.5px;
  font-family: SFProDisplay;
  font-weight: normal;
  text-transform: uppercase;
}
.sub-menu-btn.archive {
  font-size: 8px !important;
  height: 18px;
  line-height: 11px;
  padding: 3px 5px;
}
.sub-menu-btn.edit {
  font-size: 9px !important;
  height: 24px;
  padding: 6px 6px 5px 6px;
  letter-spacing: 0.8px;
  line-height: 1.4;
}
.post-filter-action {
  position: relative;
  z-index: 2;
  text-align: right !important;
}
.post-filter-action .ant-btn-group {
  height: 25px;
  padding: 3px 0;
  background: #f8f9fb;
  border-radius: 2px;
}
.ant-btn.post-filter-btn {
  font-size: 8px !important;
  text-transform: uppercase;
  font-family: 'TruenoBold';
  background-color: #f8f9fb;
  color: #5b6572;
  text-align: center;
  border: 0;
  height: 100%;
  float: left;
  border-radius: 2px !important;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.ant-btn.post-filter-btn:disabled,
.ant-btn.post-filter-btn:disabled:hover {
  background-color: #5b6572;
  color: #fff;
}
.remove-dropdown span {
  font-size: 9px !important;
  letter-spacing: 0.5px;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  color: #5B6572;
}
.remove-dropdown .ant-dropdown-menu-item:hover {
  background-color: #f8faff !important;
}
.show-campaigns-to-vip {
  width: 123px;
  height: 34px !important;
  border-radius: 0px !important;
  border: 1px solid #3c3c3c !important;
  background-color: #151515 !important;
  font-size: 10px !important;
  text-transform: uppercase;
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.show-campaigns-to-vip:after {
  border-radius: 0px !important;
  width: 61px !important;
  height: 30px !important;
  padding-top: 5px;
  content: 'No' !important;
  left: 0px !important;
  color: #000;
  transition-property: none !important;
  transform: none !important;
  animation: none !important;
}
.show-campaigns-to-vip:before {
  content: 'No   Yes' !important;
  word-spacing: 32px;
  color: #7a7a7a !important;
  transition-property: none !important;
  transform: none !important;
  animation: none !important;
}
.show-campaigns-to-vip.ant-switch-checked:after {
  padding-top: 5px;
  background-color: #f6ba45 !important;
  content: 'Yes' !important;
  left: 59px !important;
}
.yotpo-btn.ant-btn:not(.ant-btn-link) {
  background-color: #1776ec;
  color: #fff;
  border-radius: 2px;
  border: 0;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 10px;
  height: 33px;
  padding-right: 15px;
  padding-left: 15px;
}
.yotpo-btn.ant-btn:not(.ant-btn-link).ant-btn-loading {
  padding-right: 42px;
}
.yotpo-btn.ant-btn:not(.ant-btn-link):hover,
.yotpo-btn.ant-btn:not(.ant-btn-link):focus {
  color: #fff;
  background-color: #528cd4;
}
.ant-layout-sider,
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #000000 !important;
}
.ant-layout-sider .box-border,
.ant-menu-dark .box-border,
.ant-menu-dark .ant-menu-sub .box-border {
  width: 36px;
  height: 36px;
  margin-right: 9px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  border: solid 1px #3c3c3c;
  background-color: #000000;
  line-height: 1.9;
}
.ant-layout-sider .sub-menu-item span,
.ant-menu-dark .sub-menu-item span,
.ant-menu-dark .ant-menu-sub .sub-menu-item span {
  vertical-align: middle;
}
.ant-layout-sider .sub-menu-item .icon-posts,
.ant-menu-dark .sub-menu-item .icon-posts,
.ant-menu-dark .ant-menu-sub .sub-menu-item .icon-posts {
  background-color: #151515;
}
.ant-layout-sider .sub-menu-item .icon-posts svg,
.ant-menu-dark .sub-menu-item .icon-posts svg,
.ant-menu-dark .ant-menu-sub .sub-menu-item .icon-posts svg {
  margin: 6px 15px 15px 6px !important;
}
.ant-layout {
  height: 100vh;
}
.trend-table .multi-select {
  width: 66px;
}
.trend-table .col-action {
  width: 200px;
  text-align: right;
}
.trend-table .col-action .cell-action {
  float: left;
}
.trend-table .cell-action {
  text-align: left;
  float: right;
  border-left: 1px solid #e8e8e8;
  padding-left: 30px;
}
.trend-table .cell-action .link span:hover {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #000 !important;
}
.trend-table .cell-action .ant-btn-circle:hover,
.trend-table .cell-action .ant-btn-circle:focus {
  border-color: #000000 !important;
  color: #000000 !important;
}
.trend-table .ant-table-thead:before,
.trend-table .ant-table-thead:after {
  content: '';
  position: absolute;
  top: 35px;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.trend-table .ant-table-thead:before {
  left: -100%;
}
.trend-table .ant-table-thead:after {
  right: -100%;
}
.trend-table .ant-table-tbody > tr.ant-table-row-selected > td:first-child:before,
.trend-table .ant-table-tbody > tr.ant-table-row-selected > td:last-child:before {
  background: #fafafa;
}
.trend-table .ant-table-tbody > tr > td {
  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.trend-table .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: left;
}
.trend-table .ant-table-tbody > tr > td:first-child:before,
.trend-table .ant-table-tbody > tr > td:last-child:before {
  content: '';
  position: absolute;
  top: 1px;
  width: 500px;
  height: 100%;
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s;
}
.trend-table .ant-table-tbody > tr > td:first-child:before {
  left: -500px;
}
.trend-table .ant-table-tbody > tr > td:last-child:before {
  right: -500px;
}
.trend-table .ant-table-tbody > tr:hover > td:first-child:before,
.trend-table .ant-table-tbody > tr:hover > td:last-child:before {
  background: #f9f9f9;
}
.trend-table .ant-table-thead > tr > th {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 8px;
  letter-spacing: 0.5px;
  color: #5B6572 !important;
  background: transparent !important;
  padding: 11px 0 !important;
}
.trend-table .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding: 4px 0 0 0 !important;
}
.trend-table .ant-table-thead > tr > th div {
  display: inline-block;
}
.trend-table .ant-table-thead > tr > th.influencer-width {
  width: 30%;
  padding-left: 0 !important;
}
.trend-table .ant-table-thead > tr > th.influencer-width-shipment {
  width: 40%;
  padding-left: 0 !important;
}
.trend-table .ant-table-thead > tr > th.post-width {
  width: 15%;
}
.trend-table .ant-table-thead > tr > th.comments,
.trend-table .ant-table-thead > tr > th.likes {
  width: 12%;
}
.trend-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f9f9f9;
}
.trend-table .ant-table-tbody > tr > td {
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 11px;
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.avatar {
  padding-right: 0;
}
.trend-table .ant-table-tbody > tr > td.influencer-width {
  width: 25%;
  padding-left: 0 !important;
}
.trend-table .ant-table-tbody > tr > td.location {
  width: 20%;
}
.trend-table .ant-table-tbody > tr > td.author p:first-child {
  margin: 0;
  padding-top: 5px;
  font-size: 12px;
  font-family: 'truenoextrabold';
  color: #000000;
}
.trend-table .ant-table-tbody > tr > td.author p {
  font-size: 11px;
  color: #5B6572;
}
.trend-table .ant-table-tbody > tr > td.time {
  padding-left: 0;
  width: 15%;
  color: #5B6572;
  font-size: 9px;
  text-align: right;
}
.trend-table .ant-table-tbody > tr > td.count .ant-avatar {
  color: #000000;
  font-size: 9px;
  width: 28px;
  height: 28px;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFB917;
  border-color: #FFB917;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.trend-table .ant-table-tbody > tr > td .ant-checkbox-inner:after {
  display: none;
}
.messages-table .ant-table-thead {
  display: none;
}
.messages-table .author {
  width: 15%;
}
.messages-table .padded-table-list tbody td.avatar {
  padding-left: 0 !important;
}
.messages-table tbody > tr:before,
.messages-table tbody > tr:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.messages-table tbody > tr:before {
  left: -100%;
}
.messages-table tbody > tr:after {
  right: -100%;
}
.table-awaiting .post-width .ant-avatar {
  background: #fff;
  width: 50px;
  height: 50px;
  border: 1px dashed #888;
  border-radius: 0;
}
.table-approved .post-width .ant-avatar {
  border-radius: 0;
}
.ant-table-placeholder {
  border-bottom: 0 !important;
}
@media (min-width: 1441px) {
  .padded-table-list tbody td.avatar {
    padding-left: 300px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 300px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 300px !important;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) and (max-width: 1440px) {
  .padded-table-list tbody td.avatar {
    padding-left: 110px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 110px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 110px !important;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .padded-table-list tbody td.avatar {
    padding-left: 105px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 105px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 105px !important;
  }
}
/*
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .padded-table-list tbody td.avatar {
    padding-left: 20px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 20px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 20px !important;
  }
}
/*
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .padded-table-list tbody td.avatar {
    padding-left: 20px !important;
  }
  .padded-table-list tbody td.count {
    padding-right: 20px !important;
  }
  .padded-table-list ul.ant-table-pagination {
    margin-right: 20px !important;
  }
}
.trend-table-actions {
  background: #f8faff;
  border-bottom: solid 1px #dbdfe8;
  padding: 13px 0px;
}
.trend-table-actions .actions {
  position: relative;
}
.trend-table-actions .actions .selected-count {
  position: absolute;
  top: 6px;
  right: 0px;
  font-family: 'FirmeBlackItalic';
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.38;
  letter-spacing: 1px;
  text-align: right;
  color: #5b6572;
}
.trend-table-actions .actions .hide {
  display: none;
}
.trend-table-actions .actions button.ant-btn {
  border-radius: 0;
  height: 35px;
  text-transform: uppercase;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 9px;
  margin-right: 10px;
  vertical-align: middle;
  text-align: right;
  padding-right: 13px;
  line-height: 1.11;
  letter-spacing: 1px;
  color: #5b6572;
}
.trend-table-actions .actions button.ant-btn .anticon,
.trend-table-actions .actions button.ant-btn img {
  position: absolute;
}
.trend-table-actions .actions button.ant-btn.message {
  width: 141px;
}
.trend-table-actions .actions button.ant-btn.message img {
  width: 20px;
  font-size: 20px;
  left: 12px;
  top: 8px;
}
.trend-table-actions .actions button.ant-btn.cancel {
  width: 96px;
}
.trend-table-actions .actions button.ant-btn.cancel .anticon {
  font-size: 21px;
  left: 12px;
  top: 7px;
}
.trend-table-actions .actions button.ant-btn.send {
  width: 130px;
}
.trend-table-actions .actions button.ant-btn.send img {
  width: 17px;
  font-size: 17px;
  left: 12px;
  top: 9px;
}
.trend-menu-horizontal.ant-menu-horizontal {
  border: 0;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge,
.trend-menu-horizontal.ant-menu-horizontal .ant-badge-count {
  font-size: 9px;
  color: #000000;
  font-family: 'TruenoBold', Arial, sans-serif;
  position: absolute;
  left: 0;
  right: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge {
  margin-top: -10px;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-badge-count {
  margin: auto;
  padding: 0;
  width: 20px;
  background: #FFB917;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 50px;
  border: 0 !important;
  top: 0 !important;
  padding: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item a {
  padding: 12px 0;
  font-size: 14px;
  font-family: SFProDisplay;
  font-weight: normal;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item:hover {
  padding: 0;
}
.trend-menu-horizontal.ant-menu-horizontal .ant-menu-item:hover a {
  color: #000 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected {
  border: 0 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected > a {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000 !important;
}
.trend-menu-horizontal.ant-menu-horizontal > .ant-menu-item-selected > a:after {
  content: '';
  clear: both;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 3px;
  background-color: #FFB917;
}
.account-menu {
  border: 0 !important;
  background: transparent !important;
}
.account-menu > .ant-menu-item > a {
  padding-bottom: 14px;
  font-size: 15px;
  color: #747474 !important;
}
.account-menu > .ant-menu-item-selected > a {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #FFFFFF !important;
}
.trend-menu-sub {
  display: block;
  width: 100%;
  background: transparent !important;
}
.trend-menu-sub .ant-menu-item {
  border: 0 !important;
  margin-right: 20px !important;
  font-size: 9px;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon {
  float: left;
  width: 51px;
  height: 51px;
  margin-right: 12px;
  border: 1px solid #a9b0b9;
  background: #fff;
  border-radius: 50%;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon svg {
  stroke: #5B6572;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon .anticon {
  margin: 0;
  padding: 2px;
}
.trend-menu-sub .ant-menu-item .ant-menu-icon a {
  padding-bottom: 8px;
}
.trend-menu-sub .ant-menu-item a {
  padding-bottom: 2px !important;
}
.trend-menu-sub .ant-menu-item-selected > a:after {
  margin: 0 !important;
  width: 190px !important;
}
.trend-menu-sub .ant-menu-item-selected .ant-menu-icon {
  border: 0;
  background-color: #000000;
}
.trend-menu-sub .ant-menu-item-selected .ant-menu-icon svg {
  stroke: #fff;
}
.trend-menu-sub .tabs-value {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.trend-menu-inline.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px 16px !important;
}
.trend-menu-inline a.campaign-link.first-campaign {
  display: inline;
}
.trend-menu-inline a.campaign-link.first-campaign .add-campaign {
  animation: radar-animation 1s ease-out infinite;
  border-radius: 100%;
  z-index: 10;
}
@keyframes radar-animation {
  0% {
    box-shadow: 0 0 0 0 #f9f9f9;
  }
  100% {
    box-shadow: 0 0 0 7px rgba(249, 193, 202, 0);
  }
}
.ant-menu-inline.settings .ant-menu-item {
  position: fixed;
  bottom: 0px;
  width: 20px !important;
}
.ant-menu-inline.settings .ant-menu-item a {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 65px;
  background: #151515 !important;
  border-top: 1px solid #3c3c3c;
  padding: 12px;
  font-family: SFProDisplay;
  font-weight: normal;
}
.ant-menu-inline.settings .ant-menu-item a .box-border {
  background-color: #151515;
  line-height: 1.9;
}
.ant-menu-inline.settings .ant-menu-item a svg {
  vertical-align: middle;
  margin-bottom: 3px;
}
.ant-menu-inline.settings.with-notification .ant-menu-item a {
  margin-bottom: 68px;
}
.ant-menu-inline.secondary {
  padding-bottom: 30px !important;
  min-height: 80px;
}
.ant-menu-inline.secondary .ant-menu-item {
  height: 50px !important;
  margin-bottom: 0 !important;
  margin-top: 2px;
}
.ant-menu-inline.secondary .ant-menu-item a {
  padding: 5px 0;
}
.ant-menu-inline.secondary .ant-menu-item svg {
  float: left;
  margin: 8px 15px 15px 8px;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title {
  margin: 0 !important;
  height: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1 !important;
  cursor: default;
  pointer-events: none;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover {
  background: transparent !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title,
.ant-menu-inline.trend-menu-inline .ant-menu-item {
  padding-left: 15px !important;
  margin-bottom: 4px !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title:hover,
.ant-menu-inline.trend-menu-inline .ant-menu-item:hover {
  background-color: #161616;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title a,
.ant-menu-inline.trend-menu-inline .ant-menu-item a {
  font-size: 11px !important;
  color: #999999;
  font-family: SFProDisplay;
  font-weight: normal;
}
.ant-menu-inline.trend-menu-inline .ant-menu-submenu-title a span:not(.box-border),
.ant-menu-inline.trend-menu-inline .ant-menu-item a span:not(.box-border) {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 600;
  font-size: 11px !important;
}
.ant-menu-inline.trend-menu-inline .ant-menu-sub .ant-menu-item {
  height: 50px;
  margin: 0;
  padding: 5px 0;
  margin-bottom: 4px;
}
.ant-layout-sider-children .ant-menu-item-selected {
  background-color: #2b2b2b !important;
}
.ant-layout-sider-children .ant-menu-item-selected a {
  color: #fff !important;
}
.ant-layout-sider-children .ant-menu-item-selected:after {
  content: '';
  position: absolute;
  right: 0px !important;
  width: 3px;
  height: 100%;
  background-color: #FFB917;
}
.add-credit-message {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 68px;
  line-height: 68px;
  font-family: SFProDisplay;
  font-weight: 800;
  color: #fff;
  background: #000;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0 auto;
  cursor: pointer;
  z-index: 50;
}
.add-credit-message.expired p {
  padding-right: 50px;
  color: #ffb917;
  font-weight: 800;
}
.add-credit-message p {
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  letter-spacing: -0.008em;
  display: inline-block;
  /* show the marquee just outside the paragraph */
  animation: marquee 8s linear infinite;
}
.add-credit-message .countdown {
  margin-left: 20px;
  margin-right: 20px;
  color: #ffb917;
}
/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    display: none;
    transform: translate(-100%, 0);
  }
}
.with-notification {
  margin-bottom: 68px;
}
.campaign-menu .ant-menu-sub li {
  padding-left: 30px !important;
}
.campaign-menu .ant-menu-item {
  height: 50px !important;
  line-height: 49px !important;
  padding-right: 0 !important;
}
.campaign-menu .campaign-link {
  display: block;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.campaign-menu .campaign-link .ant-row-flex {
  height: 50px;
  max-height: 50px;
}
.campaign-menu .campaign-link .ant-avatar,
.campaign-menu .campaign-link .campaign-item {
  width: 36px;
  float: left;
}
.campaign-menu .campaign-link .campaign-item {
  padding-right: 12px;
  width: calc(100% - 36px - 9px);
}
.campaign-menu .campaign-avatar {
  float: left;
  margin-right: 9px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}
.campaign-menu .campaign-item {
  font-size: 11px !important;
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 600;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  line-height: 1.4;
  width: 100%;
}
.trend-campaign-avatar {
  position: relative;
  overflow: hidden;
  background: #1f1f1f !important;
}
.trend-campaign-avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-layout-sider-children {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}
.content-credit-text {
  padding: 10px 10px 29px 15px;
}
.side-small-title {
  color: #969696;
  letter-spacing: 0.5px;
  font-size: 8px !important;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.credit-title {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3px;
  line-height: 18px;
  letter-spacing: -0.004em;
  font-style: italic;
}
.credit-title-block {
  line-height: 0.5;
  font-size: 12px !important;
  color: #FFFFFF;
}
.credit-title-block .credit-desc {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: bold;
  letter-spacing: -0.008em;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 17px;
}
.credit-value {
  font-family: SFProDisplay;
  font-weight: normal;
  height: 28px;
  font-size: 26px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: normal;
  font-style: italic;
  display: inline-block;
  width: 100%;
}
.credit-value.standard {
  background: linear-gradient(257deg, #f7f7f7, #b2bacb);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.credit-value.vip {
  background-image: linear-gradient(248deg, #f7f7f7, #ceb785);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.credit-add.ant-btn {
  color: #000;
  border-radius: 25px;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;
  font-style: italic;
  padding: 10px 0;
  height: auto;
  font-family: SFProDisplay;
  font-weight: normal;
}
.credit-add.ant-btn span {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 800;
  display: inline;
}
.package-title {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  font-size: 20px !important;
  padding-top: 2px;
  margin-bottom: 7px;
  color: #3c3c3c;
}
.trend-menu-item-dark {
  background-color: #151515 !important;
  overflow: scroll;
}
.trend-menu-item-dark .ant-dropdown-menu-item {
  background-color: #151515 !important;
  color: #5a626e !important;
  font-size: 10px;
}
.trend-menu-item-dark .ant-dropdown-menu-item:hover {
  background-color: #000 !important;
}
.trend-selected-tags-dropdown {
  background-color: #151515 !important;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered ul li.ant-select-selection__choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  height: 34px;
  padding: 7px 12px;
  margin-bottom: 8px;
  margin-right: 10px;
  font-size: 11px;
  color: #000000;
}
.trend-select-states-selector .trend-selected-tags .ant-select-selection__rendered {
  background-image: none;
  width: auto;
  height: auto !important;
  margin-right: 33px;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 15px;
}
.trend-select-states-selector .trend-dropdown-option:hover {
  background-color: #000 !important;
}
.trend-select-states-selector .trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 50% !important;
  z-index: 1 !important;
  cursor: pointer;
}
.trend-selected-tags .ant-select-selection--multiple {
  width: 100% important;
  display: 'inline-block';
  cursor: pointer;
}
.trend-selected-tags .ant-select-selection--multiple::before {
  font-family: FontAwesome;
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='white' stroke='none'><path d='M0,0 L5,6 L10,0 L0,0 Z'></path></svg>");
  padding-left: 0.5em;
  vertical-align: middle;
  position: absolute;
  right: 9px;
  top: 48%;
  color: #ffffff;
}
.trend-states-custom-caret {
  position: absolute;
  color: #ffffff;
  left: 100%;
  margin-left: -25px;
  top: 64px;
  z-index: 1 !important;
  cursor: pointer;
}
.trend-selection_choice {
  display: inline-block;
  background: #ffb917 !important;
  border-radius: 2px;
  border: 0 !important;
  padding: 7px 24px 7px 12px;
  font-size: 11px;
  color: #000000;
  margin-right: 8px;
  min-width: 34px;
  margin-right: 10px;
  margin-bottom: 10px !important;
}
.trend-dropdown-option:hover {
  background-color: #000 !important;
}
.side-menu .anticon.add-campaign {
  font-size: 36px;
  color: #fff;
  vertical-align: middle;
}
.post-card.ant-card .ant-card-cover > * {
  height: 100%;
}
.post-card.ant-card .ant-card-body .ant-card-meta-title {
  color: #000000;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 16px !important;
  margin: 0 !important;
}
.trend-post-card .ant-card-body {
  height: 126px;
}
.post-card-video,
.post-card-image {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.post-card-video .post-type-wrapper,
.post-card-image .post-type-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.post-card-video .post-type-wrapper .post-type-label,
.post-card-image .post-type-wrapper .post-type-label {
  display: inline-block;
  width: 59px;
  height: 15px;
  padding-top: 2px;
  text-transform: uppercase;
  font-family: FirmeBlackItalic;
  font-size: 8px;
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000;
}
.post-card-video .post-type-wrapper .post-type-label.type-image,
.post-card-image .post-type-wrapper .post-type-label.type-image {
  background: #ffd982;
}
.post-card-video .post-type-wrapper .post-type-label.type-video,
.post-card-image .post-type-wrapper .post-type-label.type-video {
  background: #4df1ff;
}
.post-card-video .post-type-wrapper .post-type-label.type-story,
.post-card-image .post-type-wrapper .post-type-label.type-story {
  background: #66bdff;
}
.post-card-video-actions,
.post-card-image-actions {
  position: absolute;
  align-self: center;
  width: 100%;
  text-align: center;
  z-index: 4;
}
.post-card-video-actions-button,
.post-card-image-actions-button {
  display: inline-block;
  font-size: 80px;
  color: #fff;
  opacity: 1;
}
.post-card-video:hover .post-card-video-actions-button,
.post-card-image:hover .post-card-video-actions-button {
  opacity: 1;
}
.post-card-image {
  position: relative;
}
.post-card-image a,
.post-card-image img {
  display: block;
  width: 100%;
  height: 100%;
}
.post-card-image .ant-spin-nested-loading,
.post-card-image .ant-spin-container {
  width: 100%;
  height: 100%;
}
.post-card-image .post-gallery-actions button {
  position: absolute;
  top: 144px;
  width: 32px;
  height: 32px;
  padding: 0px;
  margin: 0px;
  font-size: 32px;
  border: none;
}
.post-card-image .post-gallery-actions button.action-next {
  right: 10px;
}
.post-card-image .post-gallery-actions button.action-previous {
  left: 10px;
}
.post-card-image .post-gallery-actions button .anticon {
  display: block;
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.19) !important;
}
.trend-modal .ant-modal-body {
  padding: 26px;
}
.trend-modal .ant-modal-footer {
  padding: 24px 26px;
  border: 0;
}
.trend-modal .ant-radio-wrapper {
  margin-bottom: 10px !important;
  color: #000000;
}
.trend-modal .ant-radio {
  margin-right: 5px !important;
}
.trend-modal textarea {
  padding: 16px;
  border: 1px solid #D6D6D6 !important;
  line-height: 18px;
  font-size: 12px !important;
  color: #5B6572 !important;
}
.trend-modal .label {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: truenoextrabold;
}
.trend-modal .label .highlighted {
  color: #AF4C4C;
}
.pending-review-modal .ant-modal-content {
  width: 460px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 0;
}
.pending-review-modal .ant-modal-content .ant-modal-body {
  min-height: 686px;
  margin: 0px;
  padding: 0px;
  padding-bottom: 21px;
}
.pending-review-modal .ant-modal-content .review-photo {
  text-align: center;
  padding-top: 40px;
  position: relative;
}
.pending-review-modal .ant-modal-content .review-photo img {
  display: inline-block;
  width: 200px;
  height: 200px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.44);
  background-color: #d8d8d8;
  object-fit: cover;
}
.pending-review-modal .ant-modal-content .review-photo .instagram-logo {
  position: absolute;
  top: 16px;
  right: 111px;
  background-image: url('../../images/instagram-logo@3x.png');
  background-size: cover;
  height: 43px;
  width: 43px;
}
.pending-review-modal .ant-modal-content .review-photo .emoji-heart {
  position: absolute;
  top: 138px;
  left: 107px;
  background-size: cover;
  background-image: url('../../images/heart-eyes-emoji@3x.png');
  height: 44px;
  width: 44px;
}
.pending-review-modal .ant-modal-content .review-photo .link {
  position: absolute;
  top: 217px;
  left: 175px;
  width: 110px;
  height: 45px;
}
.pending-review-modal .ant-modal-content .review-photo .link span {
  margin-top: 15px;
  font-family: 'FirmeBlackItalic';
  font-size: 12px;
  font-stretch: normal;
  line-height: 1.08;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
}
.pending-review-modal .ant-modal-content .review-message {
  margin-top: 45px;
  font-family: 'TruenoExtraBold';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.pending-review-modal .ant-modal-content .review-message p {
  display: inline-block;
  width: 279px;
}
.pending-review-modal .ant-modal-content .review-form {
  margin-bottom: 24.8px;
}
.pending-review-modal .ant-modal-content .review-form .review-input .ant-form-item-control {
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-form .review-input .ant-form-item-control .ant-form-explain {
  text-align: center;
  position: absolute;
  top: -10px;
  font-size: 10px;
}
.pending-review-modal .ant-modal-content .review-form .review-input.ant-form-item {
  margin-bottom: 17.8px;
}
.pending-review-modal .ant-modal-content .review-form .review-input:last-child {
  margin-bottom: 0;
}
.pending-review-modal .ant-modal-content .review-form .review-input-label {
  display: block;
  font-family: 'TruenoSemiBold';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-bottom: 6px;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control {
  text-align: center;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control .ant-rate .ant-rate-star .anticon {
  width: 38.2px;
  height: 38.2px;
  font-size: 38.2px;
}
.pending-review-modal .ant-modal-content .review-form .review-input-control .ant-rate .ant-rate-star.ant-rate-star-full .anticon svg path {
  fill: #ffb917;
  stroke: #ffb917;
}
.pending-review-modal .ant-modal-content .review-additional-comments {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item {
  margin-bottom: 0;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item-control {
  display: flex;
  justify-content: center;
}
.pending-review-modal .ant-modal-content .review-additional-comments .ant-form-item-control .ant-form-explain {
  text-align: center;
  position: absolute;
  top: -17px;
  font-size: 10px;
}
.pending-review-modal .ant-modal-content .review-additional-comments textarea {
  padding: 13px 18px;
  resize: none;
  width: 371px;
  height: 99px;
  object-fit: contain;
  border-radius: 2px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5b6572;
}
.pending-review-modal .ant-modal-content .review-footer {
  text-align: center;
}
.pending-review-modal .ant-modal-content .review-footer button {
  border-radius: 4px;
  background-color: #ffb917;
  border: none;
  font-weight: 900;
  width: 177.5px;
  height: 45px;
  font-family: 'FirmeBlackItalic';
  font-size: 12px;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.08;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
  text-shadow: none;
}
.yotpo-modal .ant-modal-body {
  padding: 42px 45px 38px;
}
.yotpo-modal .ant-modal-content {
  width: 423px;
  min-height: 504px;
  border-radius: 0;
}
.yotpo-modal.sent .ant-modal-body {
  padding: 63px 45px 43px;
}
.yotpo-modal.sent .ant-modal-content {
  min-height: 423px;
}
.yotpo-modal.sent .logos {
  margin-bottom: 39px;
}
.yotpo-modal.sent .ant-form-item.submit {
  margin-bottom: 0;
}
.yotpo-modal.transfer .ant-modal-body {
  padding: 63px 45px 43px;
}
.yotpo-modal.transfer .ant-modal-content {
  min-height: 423px;
}
.yotpo-modal.transfer .logos {
  margin-bottom: 39px;
}
.yotpo-modal.transfer .ant-form-item.submit {
  margin-bottom: 0;
}
.yotpo-modal.transfer .ant-modal-body {
  padding-top: 81px;
}
.yotpo-modal.transfer .logos img {
  width: 288px;
  height: 108px;
}
.yotpo-modal .logos {
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 20px;
  vertical-align: middle;
  margin-bottom: 23px;
}
.yotpo-modal .logos > span {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.yotpo-modal .logos .content-sent-image {
  height: 119px;
  width: 199px;
}
.yotpo-modal .logos .yotpo-image {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  margin-left: 18px;
}
.yotpo-modal .logos .trend-image {
  vertical-align: middle;
  width: 95px;
  height: 18px;
  margin-right: 18px;
}
.yotpo-modal h1 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 39px;
}
.yotpo-modal .label {
  font-size: 12px;
  color: #000;
  font-family: 'TruenoBold', Arial, sans-serif;
  line-height: 1;
  margin-bottom: 11px;
}
.yotpo-modal .ant-input {
  height: 52px;
  color: #000;
  border: 0;
  border-radius: 3px;
  background-color: #f8f9fb;
  padding: 15px 16px;
}
.yotpo-modal .ant-input ::placeholder {
  color: #bababa;
}
.yotpo-modal .ant-select {
  width: 100%;
}
.yotpo-modal .ant-select .ant-select-selection {
  background-color: #f4f4f4;
  border: 0;
  height: 52px;
  border-radius: 3px;
}
.yotpo-modal .ant-select .ant-select-selection .ant-select-selection-selected-value {
  margin-top: 17px;
  line-height: normal;
}
.yotpo-modal .ant-select .ant-select-selection .ant-select-selection__placeholder {
  margin-top: 2px;
  line-height: normal;
  color: #000;
}
.yotpo-modal .ant-select .ant-select-search--inline .ant-select-search__field {
  margin-top: 10px;
}
.yotpo-modal .ant-select .ant-select-selection-selected-value {
  color: #000;
}
.yotpo-modal .ant-select .ant-select-arrow {
  right: 22px;
}
.yotpo-modal .ant-select.ant-select-open:not(.ant-select-loading):after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  border-top: 0;
}
.yotpo-modal .ant-select:not(.ant-select-loading):after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 21.5px;
  right: 23.4px;
}
.yotpo-modal .ant-form-item.submit {
  margin-bottom: 38px;
}
.yotpo-modal .ant-alert {
  margin-top: 10px;
}
.yotpo-modal .ant-alert-description {
  text-align: center;
}
.yotpo-modal .submit .ant-btn {
  height: 52px;
  color: #fff;
  background-color: #1776ec;
  border: 0;
  font-family: 'TruenoBold', Arial, sans-serif;
}
.yotpo-modal .submit .ant-btn:hover {
  background-color: #5495e6;
  border: 0;
  color: #fff;
}
.yotpo-modal .help {
  margin-bottom: 0;
}
.yotpo-modal .help a {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #1776ec;
  font-size: 12px;
}
.yotpo-modal .error {
  font-family: 'TruenoBold', Arial, sans-serif;
  color: #ff0000;
  position: absolute;
  bottom: 23px;
  text-align: center;
  width: 100%;
}
.yotpo-modal .close {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 24px;
  font-size: 18px;
  padding: 0;
}
.yotpo-modal .close svg g {
  stroke: #000;
}
.yotpo-product-select.ant-select-dropdown {
  margin-top: -10px;
  border-radius: 0px !important;
  border: none;
  background-color: #f4f4f4;
}
.yotpo-product-select .ant-select-dropdown-menu-item {
  height: 52px;
  font-size: 15px;
  padding-top: 15px;
}
.yotpo-product-select.ant-select-dropdown--empty .ant-select-dropdown-menu-item {
  font-size: 15px !important;
}
.story-modal .ant-modal-close {
  right: -56px;
  color: #000;
  background: #efefef;
}
.pending-trend-review-modal .ant-modal-body {
  padding: 0;
}
.pending-trend-review-modal .ant-modal-content {
  width: 423px;
  height: 349px;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.pending-trend-review-modal .ant-modal-content .review-photo {
  text-align: center;
  padding-top: 43px;
  padding-bottom: 31px;
  position: relative;
}
.pending-trend-review-modal .ant-modal-content .review-photo .Earn_Credits {
  width: 151px;
  height: 114px;
  object-fit: contain;
}
.pending-trend-review-modal .ant-modal-content .review-message {
  padding-left: 71px;
  padding-right: 72px;
  text-align: center;
  padding-bottom: 35px;
}
.pending-trend-review-modal .ant-modal-content .review-message .message {
  font-family: 'TruenoExtraBold';
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
}
.pending-trend-review-modal .ant-modal-content .review-footer {
  padding-bottom: 33px;
  padding-left: 26px;
}
.pending-trend-review-modal .ant-modal-content .review-footer .no-thank-you {
  width: 177.5px;
  height: 45px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #dbdfe8;
  background-color: #f8faff;
}
.pending-trend-review-modal .ant-modal-content .review-footer .no-thank-you span {
  font-family: "TruenoBold";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}
.pending-trend-review-modal .ant-modal-content .review-footer .earn-credits {
  margin-left: 12.5px;
  width: 181.3px;
  height: 45px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #ffb917;
}
.pending-trend-review-modal .ant-modal-content .review-footer .earn-credits span {
  font-family: "TruenoBold";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}
.ant-spin-nested-loading.dark-theme .ant-spin-blur {
  opacity: 0;
}
.empty-no-applications .ant-empty-image {
  height: 280px;
}
.empty-no-messages .ant-empty-image {
  height: 220px;
}
.empty-desc-header {
  font-size: 25px;
  font-weight: normal;
  display: block;
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000000;
}
.empty-desc-body {
  font-size: 16px !important;
  color: #000000;
  line-height: 23px;
  font-family: SFProDisplay;
  font-weight: normal;
}
#chartContainer {
  height: 100;
  width: 100%;
  margin: 0 auto;
  position: block;
}
#chart-cursor {
  position: absolute;
  height: 100;
  width: 100%;
  margin: 0 auto;
  top: 0;
  left: 0;
}
#postGraph {
  position: relative;
  width: 100% !important;
  overflow: hidden;
}
#chartjs-tooltip {
  background-color: #fff;
  box-shadow: -4px 4px 10px -2px #ddd;
  padding: 8px 15px 10px;
}
#chartjs-tooltip .tooltip-container {
  width: 140px;
}
#chartjs-tooltip .tooltip-container .tooltip-title {
  text-transform: uppercase;
  font-size: 8pt;
  color: #a7afbe;
}
#chartjs-tooltip .tooltip-container .tooltip-item {
  font-family: 'TruenoBold';
  font-size: 9pt;
}
#chartjs-tooltip .tooltip-container .tooltip-item-icon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
}
#chartjs-tooltip .tooltip-container .tooltip-item-label {
  display: inline-block;
  padding-left: 5px;
}
#chartjs-tooltip .tooltip-container .tooltip-item-value {
  float: right;
}
.trend-table-actions .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFB917;
  border-color: #FFB917;
}
.trend-table-actions .ant-checkbox-checked .ant-checkbox-inner:after {
  display: none;
}
.trend-table-actions .ant-checkbox-indeterminate .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.trend-table-actions .ant-checkbox-indeterminate .ant-checkbox-inner:before {
  display: block;
  margin-top: 4px;
  margin-left: 4px;
  content: "";
  background: #FFB917;
  width: 15px;
  height: 15px;
  border-radius: 25px;
}
.trend-table-actions .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #f4f4f4;
  border: 1px solid #dadada;
}
.ant-row.page-filters {
  margin-bottom: 49px;
}
.ant-row.page-filters .ant-select {
  margin-right: 25px;
}
.ant-row.page-filters .ant-select .ant-select-selection-selected-value {
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #5b6572;
}
.ant-row.page-filters .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.ant-row.page-filters .ant-checkbox .ant-checkbox-inner:after {
  left: 6.571429px;
  top: 3.142857px;
}
.ant-row.page-filters .ant-checkbox + span {
  margin: 0 9px;
}
.trend-radio-buttons.ant-radio-group-solid {
  width: 100%;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
  padding: 10px 10px;
  height: 51px;
  background-color: #151515;
  color: #d9d9d9;
  border-color: #3c3c3c;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:not(:first-child):before {
  background-color: #3c3c3c;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000;
  background: #fff;
  border-color: #3c3c3c;
  box-shadow: -1px 0 0 0 #fff;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #000;
  border-color: #fff;
  background: #fff;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper .ant-radio-button {
  font-family: SFProDisplay;
  font-weight: normal;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px;
}
.trend-radio-buttons.ant-radio-group-solid .ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
#campaign-form_gender.trend-radio-buttons .ant-radio-button-wrapper {
  width: 33.3333%;
}
.video-player {
  position: relative;
}
.video-player-actions {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
}
.video-player-actions i {
  font-size: 85px;
}
.trend-steps.steps {
  color: #fff;
  background: #151515;
  border-radius: 28.5px;
  height: 59px;
  width: 100%;
  padding-top: 15px;
  padding-left: 30px;
}
.trend-steps.steps .steps-item {
  display: inline-block;
}
.trend-steps.steps .steps-item:first-child {
  margin-right: 34px;
}
.trend-steps.steps .steps-item:nth-child(2) {
  margin-right: 16px;
}
.trend-steps.steps .steps-item-icon {
  font-family: SFProDisplay;
  font-weight: normal;
  background: #151515;
  border: 1px solid #3c3c3c;
  color: #3c3c3c;
  display: inline-block;
  width: 28px;
  height: 28px;
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  margin-right: 8px;
}
.trend-steps.steps .steps-item-label {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #747474;
  font-size: 12px;
}
.trend-steps.steps .steps-item.active .steps-item-icon {
  color: #000;
  background: #ffb917;
  border-color: #ffb917;
}
.trend-steps.steps .steps-item.active .steps-item-label {
  color: #fff;
  font-weight: bold;
}
.ant-input.trend-input-bordered {
  border: 1px solid #3c3c3c;
  background: #151515;
  border-radius: 2px;
  padding: 16px 15px 17px 15px;
  font-size: 15px;
  color: #ffb917;
  height: 51px;
}
.ant-input.trend-input-bordered::placeholder {
  color: #989898;
}
.ant-notification.trend-notifcation.notif-on {
  width: 261px;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 2px 2px 0px;
  padding: 14px;
  float: right;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-icon {
  margin-left: 0;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-family: SFProDisplay;
  font-weight: 800;
  color: #000;
  font-size: 14px;
  line-height: 14px;
  margin-left: 72px;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-description {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #000;
  font-size: 12px;
  line-height: 16px;
  margin-left: 72px;
}
.ant-notification.trend-notifcation.notif-on .ant-notification-notice-close {
  display: none;
}
.stats-heading {
  margin: 0;
  font-size: 17px;
  font-style: italic;
  color: #000000;
  line-height: 1.4;
}
.caps-heading {
  font-size: 8px !important;
  letter-spacing: 0.5px;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  text-transform: uppercase;
  color: #5B6572;
}
.campaign-image {
  position: relative;
  height: 272px;
  overflow: hidden;
  background: #D6D6D6;
}
.campaign-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.campaign-image .trend-btn {
  position: absolute;
  bottom: 15px;
  right: 0;
  box-shadow: -2px 1px 4px 0 rgba(0, 0, 0, 0.28);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.item-campaign {
  height: 359px;
}
.post-requirements .required-posts .post-icons {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
.post-requirements .label-value {
  vertical-align: middle;
}
.post-requirements-states-state {
  margin-top: 13px;
}
.post-requirements-states .circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 8px;
  display: inline-block;
  border: 1px solid #000;
  padding-top: 4px;
  text-align: center;
  margin-right: 10px;
}
#recent-post .post-item-details {
  display: inline-block;
  vertical-align: top;
  padding-top: 7px;
  width: calc(100% - 50px);
}
#recent-post .post-item-details-name {
  text-overflow: ellipsis;
  display: block;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
#recent-post .post-item-details-date {
  padding-top: 5pt;
  display: block;
}
#recent-post .post-item-avatar {
  display: inline-block;
  margin-right: 10px;
}
#recent-post .post-item-avatar .post-card-image {
  width: 100%;
  height: 100%;
}
#recent-post .post-item-avatar .post-card-image a {
  display: block;
  width: 100%;
  height: 100%;
}
#recent-post .post-item-avatar .post-card-image a img {
  display: block;
  width: 100%;
  height: 100%;
}
.campaign-content .nav-header {
  min-height: 156px;
  padding-top: 38px;
}
.campaign-content .nav-header h1 {
  font-size: 26px;
}
#application-page .trend-table .ant-table-thead th,
#influencers-page .trend-table .ant-table-thead th {
  text-align: center !important;
}
#application-page .trend-table .ant-table-thead th.age-range,
#influencers-page .trend-table .ant-table-thead th.age-range {
  width: 70px;
}
#application-page .trend-table .ant-table-thead th.followers,
#influencers-page .trend-table .ant-table-thead th.followers {
  width: 90px;
}
#application-page .trend-table .ant-table-thead th.like-count,
#influencers-page .trend-table .ant-table-thead th.like-count {
  width: 90px;
}
#application-page .trend-table .ant-table-thead th.date-applied,
#influencers-page .trend-table .ant-table-thead th.date-applied {
  width: 120px;
}
#application-page .trend-table .ant-table-thead th.comment-count,
#influencers-page .trend-table .ant-table-thead th.comment-count {
  width: 120px;
}
#application-page .trend-table .ant-table-thead th.actions,
#influencers-page .trend-table .ant-table-thead th.actions {
  width: 150px;
}
#application-page .trend-table .ant-table-thead th.engagement-rate,
#influencers-page .trend-table .ant-table-thead th.engagement-rate {
  width: 150px;
}
#application-page .trend-table .ant-table-thead th:first-child,
#influencers-page .trend-table .ant-table-thead th:first-child {
  text-align: left !important;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner:after,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent #5b6572 transparent;
  position: absolute;
  bottom: 14px;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-has-sorters:not(.ant-table-column-sort) .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort {
  background-color: #fffcf3 !important;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-title,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-title {
  font-family: TruenoSemiBold;
  color: #000;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
  position: absolute;
  display: inline-block;
  color: #000000;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.off {
  display: none;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  position: absolute;
  color: #000000;
  bottom: 16px;
}
#application-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off,
#influencers-page .trend-table .ant-table-thead .ant-table-column-sort .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.off {
  display: none;
}
#application-page .trend-table .ant-table-row td,
#influencers-page .trend-table .ant-table-row td {
  cursor: pointer;
}
#application-page .trend-table .ant-table-row td.followers,
#influencers-page .trend-table .ant-table-row td.followers {
  padding-right: 12px !important;
}
#application-page .trend-table .ant-table-row td.age-range,
#influencers-page .trend-table .ant-table-row td.age-range {
  padding-right: 19px !important;
}
#application-page .trend-table .ant-table-row td.like-count,
#influencers-page .trend-table .ant-table-row td.like-count {
  padding-right: 12px !important;
}
#application-page .trend-table .ant-table-row td.comment-count,
#influencers-page .trend-table .ant-table-row td.comment-count {
  padding-right: 24px !important;
}
#application-page .trend-table .ant-table-row td.engagement-rate,
#influencers-page .trend-table .ant-table-row td.engagement-rate {
  padding-right: 24px !important;
}
#application-page .trend-table .ant-table-row td.date-applied,
#influencers-page .trend-table .ant-table-row td.date-applied {
  padding-right: 19px !important;
}
#application-page .trend-table .ant-table-row td.influencer-details .influencer-info,
#influencers-page .trend-table .ant-table-row td.influencer-details .influencer-info,
#application-page .trend-table .ant-table-row td.influencer-details .influencer-image,
#influencers-page .trend-table .ant-table-row td.influencer-details .influencer-image {
  cursor: pointer;
}
#application-page .trend-table .ant-table-row .ant-table-column-sort,
#influencers-page .trend-table .ant-table-row .ant-table-column-sort {
  font-family: TruenoBold;
  background-color: #fffcf3 !important;
}
#application-page .empty-application,
#influencers-page .empty-application {
  padding-top: 120px;
}
#application-page .empty-application .media,
#influencers-page .empty-application .media {
  width: 440px;
  height: 250px;
  margin-bottom: 22px;
}
#application-page .empty-application p,
#influencers-page .empty-application p {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}
#influencers-page .trend-table .ant-table-thead th {
  text-align: right !important;
}
#influencers-page #post-approval-content .trend-table .ant-table-thead th {
  text-align: left !important;
}
#influencers-page #shipments-content .trend-table .ant-table-thead th {
  text-align: left !important;
}
#influencer-drawer.ant-drawer:not(.ant-drawer-open) .ant-drawer-content,
#influencer-drawer-admin.ant-drawer:not(.ant-drawer-open) .ant-drawer-content {
  box-shadow: none;
}
#influencer-drawer .ant-drawer-body,
#influencer-drawer-admin .ant-drawer-body {
  padding: 0px;
  overflow: hidden;
}
#influencer-drawer .ant-drawer-header-no-title,
#influencer-drawer-admin .ant-drawer-header-no-title {
  position: relative;
}
#influencer-drawer .ant-drawer-header-no-title .ant-drawer-close,
#influencer-drawer-admin .ant-drawer-header-no-title .ant-drawer-close {
  left: 0;
  color: #FFFFFF;
}
#influencer-drawer .ant-drawer-content,
#influencer-drawer-admin .ant-drawer-content {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.55);
  background-image: linear-gradient(to bottom, #2e114a, #12041f);
}
#influencer-drawer .ant-drawer-content .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-label {
  display: block;
  height: 10px;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions {
  padding: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button {
  font-size: 12px;
  height: 43px;
  border-radius: 2px;
  font-family: 'FirmeBlackItalic';
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  line-height: 0.92;
  letter-spacing: 0.6px;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-reject,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-reject {
  color: #FFFFFF;
  border: solid 1px #46266d;
  background-color: rgba(59, 31, 92, 0.49);
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-reject:hover,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-reject:hover {
  background-color: #381d58;
}
#influencer-drawer .ant-drawer-content .influencer-info-actions button.action-approve,
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions button.action-approve {
  color: #000000;
}
#influencer-drawer .ant-drawer-content .influencer-info-avatar,
#influencer-drawer-admin .ant-drawer-content .influencer-info-avatar {
  text-align: center;
  margin-top: 35px;
  position: relative;
}
#influencer-drawer .ant-drawer-content .influencer-info-vip,
#influencer-drawer-admin .ant-drawer-content .influencer-info-vip {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  display: block;
  bottom: 0;
  right: 117px;
}
#influencer-drawer .ant-drawer-content .influencer-info-name,
#influencer-drawer-admin .ant-drawer-content .influencer-info-name {
  font-family: 'TruenoExtraBold';
  color: #FFFFFF;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  padding: 0px;
  padding-top: 8px;
  margin: 0px;
}
#influencer-drawer .ant-drawer-content .influencer-info-address,
#influencer-drawer-admin .ant-drawer-content .influencer-info-address {
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: center;
  color: #FFFFFF;
  margin-top: -3px;
}
#influencer-drawer .ant-drawer-content .influencer-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-stats {
  border: solid 1px #46266d;
  background-color: rgba(59, 31, 92, 0.44);
  color: #fff;
  border-right: 0;
  border-left: 0;
  margin-bottom: 37px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers {
  padding-top: 16px;
  text-align: center;
  color: #FFFFFF;
  border-bottom: solid 1px #46266d;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers .influencer-stats-label,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers .influencer-stats-label {
  text-align: center;
}
#influencer-drawer .ant-drawer-content .influencer-stats-followers .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-followers .influencer-stats-value {
  padding-top: 10px;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  padding-bottom: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-label,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-label {
  display: block;
  height: 10px;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-stats-other-details,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-other-details {
  padding: 18px 0 18px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-engagement,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-engagement {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
  font-size: 10px !important;
  text-transform: uppercase;
  padding: 10px 11px;
  text-align: center;
  background: #3b1f5c;
  border-top: 1px solid #46266d;
  letter-spacing: 2px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes,
#influencer-drawer .ant-drawer-content .influencer-stats-comments,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments {
  padding-left: 19px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value {
  font-size: 21px;
  font-family: 'TruenoLight';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-icon,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-icon,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-icon,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-icon {
  display: inline-block;
  margin-right: 7px;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value-indicator-percent {
  display: inline-block;
  margin-left: 7px;
  font-size: 9px;
  color: #836ba1;
  font-family: 'TruenoSemiBold';
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value.down .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value.down .influencer-stats-value-indicator-percent {
  color: #f54c15;
}
#influencer-drawer .ant-drawer-content .influencer-stats-likes .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-likes .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer .ant-drawer-content .influencer-stats-comments .influencer-stats-value.up .influencer-stats-value-indicator-percent,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments .influencer-stats-value.up .influencer-stats-value-indicator-percent {
  color: #15f590;
}
#influencer-drawer .ant-drawer-content .influencer-stats-comments,
#influencer-drawer-admin .ant-drawer-content .influencer-stats-comments {
  padding-left: 23px;
  border-left: solid 1px #46266d;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats {
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding-bottom: 2px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat {
  margin-bottom: 26px;
  position: relative;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat:last-child {
  margin-bottom: 0px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats label.review-stat-label,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats label.review-stat-label {
  font-family: 'TruenoSemiBold';
  text-transform: uppercase;
  height: 10px;
  font-size: 8px;
  line-height: normal;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 9px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-value,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-value {
  width: 24px;
  height: 21px;
  font-family: 'TruenoLight';
  font-size: 16px;
  line-height: 1.31;
  position: absolute;
  right: 0;
  bottom: 7px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .review-stat-rate,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .review-stat-rate {
  height: 4px;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-bg,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-bg,
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner {
  display: block;
  border-radius: 0 !important;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress > div,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress > div {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-outer,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-outer {
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner {
  display: block;
  background: transparent;
}
#influencer-drawer .ant-drawer-content .influencer-review-stats .ant-progress-inner:before,
#influencer-drawer-admin .ant-drawer-content .influencer-review-stats .ant-progress-inner:before {
  content: '';
  height: 2px;
  border: solid 1px #46266d;
  width: 100%;
  display: block;
  position: absolute;
  top: 1px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews {
  margin-left: 21px;
  margin-right: 21px;
  margin-bottom: 47px;
  color: #ffffff;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-info-label {
  margin-bottom: 10px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .review-comment,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .review-comment {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: #ffffff;
  font-family: 'TruenoRegular';
  margin-bottom: 8px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .review-reviewer,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .review-reviewer {
  font-size: 10px;
  font-family: 'TruenoBold';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review {
  border-bottom: 1px solid #46266d;
  padding-bottom: 24px;
  margin-top: 24px;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review:first-child,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review:first-child {
  margin-top: 0;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .influencer-review:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .influencer-review:last-child {
  margin-bottom: 25px;
  border-bottom: none;
  padding-bottom: 0;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn {
  height: 28px;
  background-color: rgba(59, 31, 92, 0.44);
  border-color: rgba(59, 31, 92, 0.44);
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  text-align: center;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn:hover,
#influencer-drawer-admin .ant-drawer-content .influencer-reviews .load-more-reviews button.ant-btn:hover {
  background-color: rgba(59, 31, 92, 0.9);
  border-color: rgba(59, 31, 92, 0.9);
}
#influencer-drawer .ant-drawer-content .influencer-posts,
#influencer-drawer-admin .ant-drawer-content .influencer-posts {
  margin-left: 21px;
  margin-right: 21px;
}
#influencer-drawer .ant-drawer-content .influencer-posts .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-posts .influencer-info-label {
  margin-bottom: 12px;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images a,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images a {
  display: inline-block;
  width: 141px;
  height: 141px;
  padding-right: 4px;
  padding-bottom: 4px;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images a:last-child,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images a:last-child {
  padding-right: 0px;
}
#influencer-drawer .ant-drawer-content .influencer-posts-images img,
#influencer-drawer-admin .ant-drawer-content .influencer-posts-images img {
  width: 100%;
  height: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-bio,
#influencer-drawer-admin .ant-drawer-content .influencer-info-bio {
  color: #FFFFFF;
  margin: 31px 21px;
}
#influencer-drawer .ant-drawer-content .influencer-info-bio p,
#influencer-drawer-admin .ant-drawer-content .influencer-info-bio p {
  white-space: pre-wrap;
  margin-top: 10px;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-info-work,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work {
  color: #FFFFFF;
  margin: 41px 21px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-label {
  margin-bottom: 12px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card {
  margin-bottom: 20px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource {
  position: relative;
  width: 100%;
  height: 287px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-video,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-video,
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image {
  width: 100%;
  height: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image a,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource .post-card-image a {
  width: 100%;
  height: 100%;
  display: block;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource video,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource video,
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource img,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource:after,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-resource:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 62px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stats,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stats {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 16px;
  z-index: 2;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat {
  float: left;
  width: auto;
  margin-right: 30px;
  margin-bottom: 11px;
  color: #ffffff;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-value,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-value {
  font-family: 'TruenoBold';
  font-size: 16px;
  line-height: 0.75;
  padding-bottom: 6px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-label,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-stat-label {
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  letter-spacing: 0.5px;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions {
  width: 100%;
}
#influencer-drawer .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions button,
#influencer-drawer-admin .ant-drawer-content .influencer-info-work .influencer-info-posts .post-card-actions button {
  background-color: #2f154a;
  height: 48px;
  border: none;
  border-radius: 0;
  color: #fff;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  letter-spacing: 3px;
}
#influencer-drawer .post-gallery-actions button,
#influencer-drawer-admin .post-gallery-actions button {
  top: 118px;
}
#influencer-drawer .post-gallery-actions button.action-next,
#influencer-drawer-admin .post-gallery-actions button.action-next {
  right: 16px;
}
#influencer-drawer .post-gallery-actions button.action-previous,
#influencer-drawer-admin .post-gallery-actions button.action-previous {
  left: 16px;
}
#influencer-drawer .ant-tabs-bar,
#influencer-drawer-admin .ant-tabs-bar {
  border-bottom: solid 1px #46266d;
  margin-bottom: 0;
}
#influencer-drawer .ant-tabs-ink-bar,
#influencer-drawer-admin .ant-tabs-ink-bar {
  background-color: #ffb917;
  margin-left: 39px;
  width: 31px !important;
}
#influencer-drawer .ant-tabs-nav,
#influencer-drawer-admin .ant-tabs-nav {
  width: 100%;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab {
  padding: 23px 0;
  width: calc(100% / 3);
  margin: 0;
  color: #fff;
  opacity: 0.5;
  font-family: 'TruenoLight';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  font-family: 'TruenoBold';
  opacity: 1;
}
#influencer-drawer .ant-tabs-nav .ant-tabs-tab:hover,
#influencer-drawer-admin .ant-tabs-nav .ant-tabs-tab:hover {
  opacity: 1;
}
#influencer-drawer .campaign-details,
#influencer-drawer-admin .campaign-details {
  text-align: left;
  color: #fff;
  margin: 18px 19px;
  padding: 19px 20px;
  border-radius: 1;
  background-color: rgba(59, 31, 92, 0.49);
}
#influencer-drawer .campaign-details-brand-name,
#influencer-drawer-admin .campaign-details-brand-name {
  font-family: 'TruenoBold';
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 3px;
}
#influencer-drawer .campaign-details-name,
#influencer-drawer-admin .campaign-details-name {
  font-family: 'TruenoBold';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 18px;
}
#influencer-drawer .campaign-details .details-status,
#influencer-drawer-admin .campaign-details .details-status {
  position: relative;
  margin-bottom: 16px;
}
#influencer-drawer .campaign-details .details-status:last-child,
#influencer-drawer-admin .campaign-details .details-status:last-child {
  margin-bottom: 0;
}
#influencer-drawer .campaign-details .details-status .status,
#influencer-drawer-admin .campaign-details .details-status .status {
  margin-left: 15px;
  font-family: 'TruenoRegular';
  line-height: 0.86;
}
#influencer-drawer .campaign-details .details-status .status-value,
#influencer-drawer-admin .campaign-details .details-status .status-value {
  margin-bottom: 8px;
}
#influencer-drawer .campaign-details .details-status .status-label,
#influencer-drawer-admin .campaign-details .details-status .status-label {
  text-transform: uppercase;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  line-height: normal;
  letter-spacing: 2px;
}
#influencer-drawer .campaign-details .details-status .icon,
#influencer-drawer-admin .campaign-details .details-status .icon {
  top: 5px;
  width: 8px;
  height: 8px;
  position: absolute;
  display: inline-block;
  border-radius: 100%;
}
#influencer-drawer .campaign-details .details-status .icon.success,
#influencer-drawer-admin .campaign-details .details-status .icon.success {
  background: #15f590;
}
#influencer-drawer .campaign-details .details-status .icon.danger,
#influencer-drawer-admin .campaign-details .details-status .icon.danger {
  background-color: #ff0000;
}
#influencer-drawer .campaign-details .details-status .icon.warning,
#influencer-drawer-admin .campaign-details .details-status .icon.warning {
  background-color: #ffb917;
}
#influencer-drawer .ant-spin-nested-loading,
#influencer-drawer-admin .ant-spin-nested-loading {
  height: 100%;
  width: 100%;
}
#influencer-drawer .ant-spin-nested-loading .ant-spin-container,
#influencer-drawer-admin .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
  width: 100%;
}
#influencer-drawer-admin .ant-drawer-content .influencer-stats {
  border-top: 0;
}
#influencer-drawer-admin .ant-drawer-content .influencer-info-actions {
  padding: 23px 34px 0 34px;
}
#influencer-drawer-admin .influencer-badge {
  position: absolute;
  top: 22px;
  right: 43px;
  width: 34px;
}
#campaign-posts-list .trend-post-card .ant-card-meta-detail,
#campaign-posts-list .trend-post-card-content .ant-card-meta-detail {
  width: calc(100% - 45px);
}
#campaign-posts-page .post-filter-types {
  border-bottom: 1px solid #d6d6d6;
}
#campaign-overview .post-guidelines .guideline {
  margin-bottom: 50px;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
}
#campaign-overview .styleguide .image {
  padding-bottom: 20px;
}
#campaign-overview .styleguide .image img {
  width: 100%;
  height: 112px;
  object-fit: cover;
}
.example-campaign .video-player {
  width: 446px;
  height: 250px;
  margin-top: 86.5px;
  margin-bottom: 42px;
}
.example-campaign .account-approval-status {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 8px;
}
.example-campaign .approval-status::after {
  content: '';
  margin-left: 3px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-bottom: 2px;
}
.example-campaign .approval-status.pending,
.example-campaign .approval-status.rejected {
  color: #f6ab00;
}
.example-campaign .approval-status.pending::after,
.example-campaign .approval-status.rejected::after {
  background-color: #f6ab00;
}
.example-campaign .approval-status.approved {
  color: #19d921;
}
.example-campaign .approval-status.approved::after {
  background-color: #19d921;
}
.product-form .image-uploader .image,
.product-form .image-uploader .add {
  padding-bottom: 20px;
}
.product-form .image-uploader .actions .remove {
  height: 24px;
  background-color: #3c3c3c;
  color: #fff;
  border-radius: 0;
  font-size: 10px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.product-form .image-uploader .add {
  width: 100%;
  height: 112px;
  position: relative;
  background-color: #151515;
  border: 1px solid #3c3c3c;
  border-radius: 2px;
}
.product-form .image-uploader .add input[type='file'] {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}
.product-form .image-uploader .add .button {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 34px;
  text-align: center;
}
.product-form .image-uploader .add .button .anticon {
  font-size: 28px;
}
.product-form .image-uploader .add .button .label {
  font-size: 10px;
  color: #747474;
  font-family: SFProDisplay;
  font-weight: normal;
}
#new-campaign-page .ant-steps {
  font-family: SFProDisplay;
  font-weight: normal;
  width: 459px;
  padding: 15px 30px 16px;
  background: #151515;
  border-radius: 28.5px;
  margin-bottom: 0px;
}
#new-campaign-page .ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0px;
}
#new-campaign-page .ant-steps .ant-steps-item-icon {
  margin-right: 8px;
  line-height: 28px;
}
#new-campaign-page .ant-steps .ant-steps-icon {
  width: 28px;
  height: 28px;
  border: 1px solid #3c3c3c;
  font-size: 12px;
  display: inline-block;
  border-radius: 100%;
  line-height: 26px;
  color: #747474;
}
#new-campaign-page .ant-steps .ant-steps-item-title {
  line-height: 31px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 12px;
  color: #747474;
}
#new-campaign-page .ant-steps .ant-steps-item-process .ant-steps-item-title {
  color: #fff;
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: normal;
}
#new-campaign-page .ant-steps .ant-steps-item-process .ant-steps-icon {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #000;
  background-color: #ffb917;
  border-color: #ffb917;
}
#new-campaign-page .ant-steps .ant-steps-item-wait .ant-steps-icon {
  font-family: SFProDisplay;
  font-weight: normal;
}
#new-campaign-page.first-campaign input.ant-input.trend-input {
  padding-top: 0px;
  font-family: SFProDisplay;
  font-weight: normal;
}
#new-campaign-page.first-campaign input.ant-input.trend-input.large {
  font-size: 20px;
  line-height: 24px;
}
#new-campaign-page.first-campaign input.ant-input.trend-input.large::placeholder {
  font-size: 20px;
}
#new-campaign-page.first-campaign input.ant-input.trend-input::placeholder {
  color: #989898;
}
#new-campaign-page.first-campaign .steps {
  color: #fff;
  background: #151515;
  border-radius: 28.5px;
  height: 59px;
  width: 459px;
  padding-top: 15px;
  padding-left: 30px;
}
#new-campaign-page.first-campaign .steps .steps-item {
  display: inline-block;
}
#new-campaign-page.first-campaign .steps .steps-item:first-child {
  margin-right: 34px;
}
#new-campaign-page.first-campaign .steps .steps-item:nth-child(2) {
  margin-right: 16px;
}
#new-campaign-page.first-campaign .steps .steps-item-icon {
  font-family: SFProDisplay;
  font-weight: normal;
  background: #151515;
  border: 1px solid #3c3c3c;
  color: #3c3c3c;
  display: inline-block;
  width: 28px;
  height: 28px;
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
  line-height: 26px;
  margin-right: 8px;
}
#new-campaign-page.first-campaign .steps .steps-item-label {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #747474;
  font-size: 12px;
}
#new-campaign-page.first-campaign .steps .steps-item.active .steps-item-icon {
  color: #000;
  background: #ffb917;
  border-color: #ffb917;
}
#new-campaign-page.first-campaign .steps .steps-item.active .steps-item-label {
  color: #fff;
  font-weight: bold;
}
#new-campaign-page .payment-form .trend-label {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: #ffffff;
}
#new-campaign-page h1,
#edit-campaign-page h1 {
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -0.008em;
}
#new-campaign-page .setup-steps,
#edit-campaign-page .setup-steps {
  color: #fff;
  width: 379px;
  border-radius: 28.5px;
  background: #131313;
  border: solid 1px #272727;
  width: 459px;
}
@media (max-width: 576px) {
  #new-campaign-page .setup-steps,
  #edit-campaign-page .setup-steps {
    width: 100%;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}
#new-campaign-page .setup-steps .step,
#edit-campaign-page .setup-steps .step {
  margin-left: 21px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'TruenoBold';
  font-size: 12px;
  float: left;
  color: #444444;
}
@media (max-width: 576px) {
  #new-campaign-page .setup-steps .step,
  #edit-campaign-page .setup-steps .step {
    width: 50%;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 576px) {
  #new-campaign-page .setup-steps .step:first-child,
  #edit-campaign-page .setup-steps .step:first-child {
    text-align: right;
  }
}
@media (max-width: 576px) {
  #new-campaign-page .setup-steps .step:last-child,
  #edit-campaign-page .setup-steps .step:last-child {
    text-align: left;
  }
}
#new-campaign-page .setup-steps .step-label,
#edit-campaign-page .setup-steps .step-label,
#new-campaign-page .setup-steps .step-symbol,
#edit-campaign-page .setup-steps .step-symbol {
  display: inline-block;
  text-align: left;
}
#new-campaign-page .setup-steps .step-label,
#edit-campaign-page .setup-steps .step-label {
  font-family: 'TruenoSemiBold';
}
@media (max-width: 576px) {
  #new-campaign-page .setup-steps .step-label,
  #edit-campaign-page .setup-steps .step-label {
    width: 79px;
    font-size: 10px;
    vertical-align: middle;
  }
}
#new-campaign-page .setup-steps .step.active .step-symbol,
#edit-campaign-page .setup-steps .step.active .step-symbol {
  background: #ffb917;
  color: #000;
}
#new-campaign-page .setup-steps .step.active .step-label,
#edit-campaign-page .setup-steps .step.active .step-label {
  color: #fff;
}
#new-campaign-page .setup-steps .step-symbol,
#edit-campaign-page .setup-steps .step-symbol {
  margin-right: 10px;
  box-shadow: -20px 22px 23px 0 rgba(0, 0, 0, 0.19);
  background-color: #272727;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  text-align: center;
  color: #636363;
  line-height: 28px;
}
@media (min-width: 992px) {
  #new-campaign-page .ant-col-lg-10,
  #edit-campaign-page .ant-col-lg-10 {
    width: 580px;
  }
}
#new-campaign-page .ant-form-item,
#edit-campaign-page .ant-form-item {
  padding-bottom: 0;
}
#new-campaign-page .ant-form-item,
#edit-campaign-page .ant-form-item {
  margin-bottom: 45px;
}
#new-campaign-page .ant-form-item-label,
#edit-campaign-page .ant-form-item-label {
  margin-bottom: 7px;
  padding-bottom: 0px;
}
#new-campaign-page .ant-form-item-label > label,
#edit-campaign-page .ant-form-item-label > label {
  display: block;
  height: auto;
  color: #fff;
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 26px;
  letter-spacing: -0.008em;
}
#new-campaign-page .ant-form-item-label > label .with-sub-label,
#edit-campaign-page .ant-form-item-label > label .with-sub-label {
  line-height: 20px;
}
#new-campaign-page .ant-form-item-label > label .with-sub-label .sub-label,
#edit-campaign-page .ant-form-item-label > label .with-sub-label .sub-label {
  font-family: SFProDisplay;
  font-weight: normal;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  color: #fff;
}
#new-campaign-page .ant-input,
#edit-campaign-page .ant-input {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 14.5px;
}
#new-campaign-page .ant-input .ant-input-affix-wrapper.trend-input.dark .ant-input-prefix,
#edit-campaign-page .ant-input .ant-input-affix-wrapper.trend-input.dark .ant-input-prefix {
  font-size: 15px;
}
#new-campaign-page .ant-input-prefix-touch,
#edit-campaign-page .ant-input-prefix-touch {
  color: #ffb917 !important;
}
#new-campaign-page .ant-input-prefix,
#edit-campaign-page .ant-input-prefix {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #989898;
  left: 0;
  padding-bottom: 13px;
  line-height: 18px;
}
#new-campaign-page .ant-input-prefix + .ant-input,
#edit-campaign-page .ant-input-prefix + .ant-input {
  padding-left: 18px;
}
#new-campaign-page .ant-input-prefix + .ant-input::placeholder,
#edit-campaign-page .ant-input-prefix + .ant-input::placeholder {
  color: #989898;
}
#new-campaign-page .ant-select,
#edit-campaign-page .ant-select {
  font-family: SFProDisplay;
  font-weight: normal;
}
#new-campaign-page h2,
#edit-campaign-page h2 {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}
#new-campaign-page h2 .sub-header,
#edit-campaign-page h2 .sub-header {
  color: #fff;
  font-family: SFProDisplay;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 45px;
}
#new-campaign-page .ant-input.trend-textarea,
#edit-campaign-page .ant-input.trend-textarea {
  padding: 16px 15px;
  background: #151515;
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}
#new-campaign-page .ant-input.trend-textarea::placeholder,
#edit-campaign-page .ant-input.trend-textarea::placeholder {
  font-size: 15px;
  color: #989898;
}
#new-campaign-page .show-campaigns-to-vip,
#edit-campaign-page .show-campaigns-to-vip {
  border-radius: 2px !important;
}
#new-campaign-page .show-campaigns-to-vip:before,
#edit-campaign-page .show-campaigns-to-vip:before,
#new-campaign-page .show-campaigns-to-vip:after,
#edit-campaign-page .show-campaigns-to-vip:after {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 15px;
  padding: 0;
  line-height: 34px;
  top: 0px;
  height: 32px !important;
  width: 51% !important;
}
#new-campaign-page .show-campaigns-to-vip:after,
#edit-campaign-page .show-campaigns-to-vip:after {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
#new-campaign-page .show-campaigns-to-vip.ant-switch-checked:after,
#edit-campaign-page .show-campaigns-to-vip.ant-switch-checked:after {
  left: 50% !important;
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
#new-campaign-page .ant-btn.ant-btn-primary,
#edit-campaign-page .ant-btn.ant-btn-primary,
#new-campaign-page .trend-btn.product-btn,
#edit-campaign-page .trend-btn.product-btn {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
  border-radius: 32px;
  font-size: 16px;
  line-height: 18px;
  height: auto;
  padding: 18px 40px;
  color: black;
  text-shadow: none;
  margin-bottom: 30px;
}
#new-campaign-page .ant-steps-item-title,
#edit-campaign-page .ant-steps-item-title {
  color: #fff;
}
#new-campaign-page .ant-steps-item-title::after,
#edit-campaign-page .ant-steps-item-title::after {
  content: none;
}
#new-campaign-page .sub-header,
#edit-campaign-page .sub-header {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #999;
  letter-spacing: -0.008em;
}
#new-campaign-page .trend-label,
#edit-campaign-page .trend-label {
  color: #fff;
  font-style: normal;
  font-size: 18px;
}
#new-campaign-page .StripeElement,
#edit-campaign-page .StripeElement {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #eee;
  font-weight: bold;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 16px;
  margin-bottom: 25px;
  font-size: 24px;
}
#new-campaign-page .StripeElement .input,
#edit-campaign-page .StripeElement .input {
  height: 50px;
  color: #FFB917;
}
#new-campaign-page .StripeElement::placeholder,
#edit-campaign-page .StripeElement::placeholder {
  color: #3A3A3A;
}
#new-campaign-page .product-uploader .product-label,
#edit-campaign-page .product-uploader .product-label {
  padding-top: 116px;
}
#new-campaign-page .product-uploader .anticon,
#edit-campaign-page .product-uploader .anticon {
  background: #3c3c3c;
  border-radius: 2px;
  margin-bottom: 18px;
}
#new-campaign-page .product-uploader .label,
#edit-campaign-page .product-uploader .label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
}
#new-campaign-page .product-uploader .sub-label,
#edit-campaign-page .product-uploader .sub-label {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
}
#new-campaign-page .ant-input,
#edit-campaign-page .ant-input {
  padding-left: 0px;
  padding-top: 0px;
}
#new-campaign-page .ant-input::placeholder,
#edit-campaign-page .ant-input::placeholder {
  font-size: 15px;
}
#new-campaign-page .ant-input-number,
#edit-campaign-page .ant-input-number {
  line-height: 18px;
}
#new-campaign-page .ant-input-number .ant-input-number-input[value='$ '],
#edit-campaign-page .ant-input-number .ant-input-number-input[value='$ '] {
  color: #989898;
}
#new-campaign-page .ant-input-number-input-wrap,
#edit-campaign-page .ant-input-number-input-wrap {
  line-height: normal;
}
#new-campaign-page .ant-input-number-input,
#edit-campaign-page .ant-input-number-input {
  font-family: SFProDisplay;
  font-weight: normal;
  padding-left: 0px;
  height: auto;
  line-height: 18px;
  font-size: 15px;
}
#new-campaign-page .ant-input-number-input::placeholder,
#edit-campaign-page .ant-input-number-input::placeholder {
  color: #989898;
}
#new-campaign-page .ant-form-item-control.has-error .ant-input,
#edit-campaign-page .ant-form-item-control.has-error .ant-input {
  border-color: #f5222d !important;
  background-color: #000;
}
#new-campaign-page .ant-form-item-control.has-error .ant-input-number,
#edit-campaign-page .ant-form-item-control.has-error .ant-input-number {
  border-color: #f5222d !important;
}
#new-campaign-page .ant-form-item-control .ant-form-explain,
#edit-campaign-page .ant-form-item-control .ant-form-explain {
  font-family: SFProDisplay;
  font-weight: normal;
  position: absolute;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar {
  width: 108px;
  border-right-color: #3c3c3c;
  padding-top: 36px;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab {
  font-family: SFProDisplay;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  padding: 0px;
  padding-left: 31px;
  margin-bottom: 19px;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab:hover,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab:hover {
  color: #ffb917;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span.hasError,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span.hasError {
  color: #ff7b7b;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span.hasError::after,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span.hasError::after {
  background-color: #ff7b7b;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span::after,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab span::after {
  transition: width 0.5s;
  content: '';
  position: absolute;
  left: 0px;
  height: 3px;
  border: none;
  border-radius: initial;
  width: 10px;
  top: 0px;
  margin-top: 7px;
  background-color: #999;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span {
  color: #ffb917;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span.hasError,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span.hasError {
  color: #ffb917;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span::after,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active span::after {
  transition: width 0.5s;
  width: 20px !important;
  background-color: #ffb917 !important;
}
#new-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-ink-bar,
#edit-campaign-page .ant-tabs-bar.ant-tabs-left-bar .ant-tabs-ink-bar {
  display: none !important;
}
#new-campaign-page .ant-tabs .ant-tabs-left-content,
#edit-campaign-page .ant-tabs .ant-tabs-left-content {
  border-left-color: #3c3c3c;
  padding-left: 0px;
}
#new-campaign-page .page-header,
#edit-campaign-page .page-header {
  color: #fff;
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: normal;
  font-size: 24px;
  line-height: 18px;
  margin-bottom: 15px;
}
#new-campaign-page .page-description,
#edit-campaign-page .page-description {
  margin-bottom: 35px;
  color: #999;
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
#new-campaign-page .page-other-details h3,
#edit-campaign-page .page-other-details h3 {
  font-family: SFProDisplay !important;
  font-weight: bold !important;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #989898;
  margin-bottom: 10px;
}
#new-campaign-page .page-other-details .ant-btn,
#edit-campaign-page .page-other-details .ant-btn {
  margin-bottom: 0px;
}
#new-campaign-page .page-other-details img,
#edit-campaign-page .page-other-details img {
  width: 292px;
}
#new-campaign-page .page-other-details p,
#edit-campaign-page .page-other-details p {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #989898;
  width: 292px;
  margin-bottom: 35px;
}
.example-campaign .media {
  margin-top: 133.5px;
}
.example-campaign img {
  width: 411px;
  height: 197px;
  margin-bottom: 37px;
}
.example-campaign h1 {
  font-size: 26px;
  color: #000;
  padding: 0;
  margin-bottom: 8px;
}
.example-campaign p {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  line-height: 24px;
}
.bulk-actions .trend-btn {
  height: 35px;
}
.bulk-actions .ant-btn.yotpo-btn {
  background: #FFB917;
  border: 0;
  color: #000000;
  font-size: 11px;
  font-family: 'TruenoBold', Arial, sans-serif;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 2px;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  font-size: 10px !important;
  height: 35px;
  margin-right: 10px;
  background: #1776ec;
  min-width: 153px;
  text-align: left;
  color: #fff;
}
.bulk-actions .ant-btn.yotpo-btn:hover {
  background: #ffe8bc;
}
.bulk-actions .ant-btn.yotpo-btn.secondary {
  height: 35px;
  padding-top: 2px;
  background: #f8faff;
  border: 1px solid #dbdfe8;
  box-shadow: none;
  font-size: 9px;
  color: #5B6572;
  letter-spacing: 1px;
}
.bulk-actions .ant-btn.yotpo-btn.secondary:hover {
  background: #f0f2f9 !important;
}
.bulk-actions .ant-btn.yotpo-btn.gray {
  background: #3A3A3A;
  color: #FFFFFF !important;
}
.bulk-actions .ant-btn.yotpo-btn img {
  position: absolute;
  right: 11px;
  top: 6px;
  object-fit: contain;
  vertical-align: top;
  height: 23px;
  width: 23px;
}
.bulk-actions .ant-btn.yotpo-btn:hover {
  color: #fff;
  background: #135fbd;
}
.trend-post-card .yotpo-btn img,
.trend-post-card-content .yotpo-btn img {
  margin-top: -3px;
  vertical-align: top;
  width: 24px;
  height: 24px;
}
#recent-campaign-page h1 {
  margin-top: 38px;
  margin-bottom: 35px;
  font-size: 28px;
}
.product-uploader {
  position: relative;
  overflow: hidden;
  background: #151515;
  height: 326px;
}
.product-uploader .product-label {
  display: block;
  height: 326px;
  cursor: pointer;
  border: 1px solid #3c3c3c;
  padding: 107px 0;
  color: #747474;
  font-size: 13px;
}
.product-uploader .icon {
  color: #fff;
  padding: 14px;
  background: #222121;
  width: 52px;
  height: 52px;
  font-size: 22px !important;
}
.action-link .back,
.action-link .sign-out {
  position: absolute;
  z-index: 2;
  top: 31px;
}
.action-link .back {
  left: 35px;
}
.action-link .sign-out {
  right: 35px;
}
.product-image {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.gender-select .ant-select-selection-selected-value {
  font-size: 14px !important;
}
.select-arrow-down-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}
.yotpo-field img {
  width: 73px;
  height: 73px;
}
.trend-post-card:hover,
.trend-post-card-content:hover {
  box-shadow: 0px 0 37px -11px #000;
}
.trend-post-card .ant-card-meta-title,
.trend-post-card-content .ant-card-meta-title {
  font-size: 16px !important;
  color: #000000;
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.trend-post-card .ant-card-meta-description,
.trend-post-card-content .ant-card-meta-description {
  font-size: 11px;
  color: #5B6572;
}
.trend-post-card .ant-card-meta-avatar,
.trend-post-card-content .ant-card-meta-avatar {
  padding-right: 10px;
}
.trend-post-card .ant-card-meta-detail,
.trend-post-card-content .ant-card-meta-detail {
  vertical-align: middle;
  width: calc(100% - 50px);
}
.trend-post-card .ant-card-meta-detail .ant-card-meta-title,
.trend-post-card-content .ant-card-meta-detail .ant-card-meta-title {
  text-overflow: ellipsis;
  display: block;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.trend-post-card .ant-card-meta-detail,
.trend-post-card-content .ant-card-meta-detail,
.trend-post-card .ant-card-meta-avatar,
.trend-post-card-content .ant-card-meta-avatar {
  display: inline-block;
}
.trend-post-card .ant-card-cover,
.trend-post-card-content .ant-card-cover {
  position: relative;
  overflow: hidden;
  background: #e8e8e8;
  height: 307px;
}
.trend-post-card .ant-card-cover img,
.trend-post-card-content .ant-card-cover img {
  object-fit: cover;
  height: 100%;
}
.trend-post-card .ant-card-body,
.trend-post-card-content .ant-card-body {
  position: relative;
}
.trend-post-card .ant-card-actions .spin-btn,
.trend-post-card-content .ant-card-actions .spin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trend-post-card .ant-card-actions .spin-btn .download,
.trend-post-card-content .ant-card-actions .spin-btn .download {
  display: flex;
  align-items: center;
}
.trend-post-card .ant-card-actions span,
.trend-post-card-content .ant-card-actions span {
  margin-right: 8px;
}
.ant-avatar > img {
  object-fit: cover;
}
.post-avatar {
  position: relative;
  display: inline-block;
}
.post-avatar .play-button {
  position: absolute;
  z-index: 1;
  font-size: 30px;
  left: 10px;
  top: 10px;
}
.post-avatar .story-type {
  color: #000;
  position: absolute;
  padding-top: 2px;
  height: 15px;
  top: 45px;
  width: 59px;
  left: -4px;
  text-transform: uppercase;
  font-family: FirmeBlackItalic;
  font-size: 8px;
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.4px;
  text-align: center;
  color: #000000;
}
.post-avatar .story-type.type-image {
  background: #ffd982;
}
.post-avatar .story-type.type-video {
  background: #4df1ff;
}
.post-avatar .story-type.type-story {
  background: #66bdff;
}
.post-filter button {
  height: 100%;
  color: #5b6572;
  padding-bottom: 14px;
}
.post-filter button:focus,
.post-filter button:active,
.post-filter button:hover {
  color: #000;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus,
.post-filter.post-filter:not(.post-filter-selected) button:active,
.post-filter.post-filter:not(.post-filter-selected) button:hover {
  color: #000;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon {
  background: #000;
  color: #fff;
  transition: all 0.5s;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon svg g,
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-icon svg path,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-icon svg path,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-icon svg path {
  fill: #fff;
  stroke: #fff;
}
.post-filter.post-filter:not(.post-filter-selected) button:focus .post-filter-label,
.post-filter.post-filter:not(.post-filter-selected) button:active .post-filter-label,
.post-filter.post-filter:not(.post-filter-selected) button:hover .post-filter-label {
  color: #000;
}
.post-filter-selected {
  border-bottom: 3px solid #ffb917;
}
.post-filter-selected button {
  color: #000;
}
.post-filter-selected button:focus,
.post-filter-selected button:active,
.post-filter-selected button:hover {
  color: #000;
}
.post-filter-selected .post-filter-icon {
  background: #000;
}
.post-filter-selected.post-filter-all .post-filter-icon,
.post-filter-selected.post-filter-images .post-filter-icon,
.post-filter-selected.post-filter-videos .post-filter-icon,
.post-filter-selected.post-filter-stories .post-filter-icon {
  color: #fff;
  transition: all 0.5s;
}
.post-filter-selected.post-filter-all .post-filter-icon svg g,
.post-filter-selected.post-filter-images .post-filter-icon svg g,
.post-filter-selected.post-filter-videos .post-filter-icon svg g,
.post-filter-selected.post-filter-stories .post-filter-icon svg g,
.post-filter-selected.post-filter-all .post-filter-icon svg path,
.post-filter-selected.post-filter-images .post-filter-icon svg path,
.post-filter-selected.post-filter-videos .post-filter-icon svg path,
.post-filter-selected.post-filter-stories .post-filter-icon svg path {
  fill: #fff;
  stroke: #fff;
}
.post-filter-selected .post-filter-label {
  color: #000;
}
.post-filter-icon {
  height: 51px;
  width: 51px;
  display: inline-block;
  border: 1px solid #a9b0b9;
  border-radius: 100%;
  margin-right: 15px;
}
.post-filter-count {
  font-size: 16px;
  font-family: 'TruenoExtraBold';
  text-align: left;
}
.post-filter-label {
  font-size: 9px;
  font-family: 'TruenoSemiBold';
  letter-spacing: 0.5px;
  color: #5b6572;
}
.post-filter-all .post-filter-icon {
  font-family: 'TruenoBold';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  object-fit: contain;
  padding: 16px 13px;
  border-radius: 100%;
}
.post-filter-images .post-filter-icon {
  font-size: 18px;
  padding-top: 16px;
}
.post-filter-images .post-filter-icon .anticon {
  vertical-align: top;
}
.post-filter-videos .post-filter-icon {
  font-size: 23px;
  padding-top: 16px;
}
.post-filter-videos .anticon {
  vertical-align: top;
}
.post-filter-videos .anticon svg {
  height: 18px;
}
.post-filter-stories .post-filter-icon {
  font-size: 24px;
  padding-top: 16px;
}
.post-filter-stories .anticon {
  vertical-align: top;
}
.post-filter-stories .anticon svg {
  height: 18px;
}
#all-post-page h1 {
  margin-top: 38px;
  margin-bottom: 35px;
  font-size: 28px;
}
#all-post-page .post-card {
  width: 360px;
  height: auto;
  margin-bottom: 28px;
  background: transparent;
}
#all-post-page .post-card.trend-post-card,
#all-post-page .post-card .trend-post-card-content {
  background: transparent;
}
#all-post-page .post-card.trend-post-card .ant-card-cover,
#all-post-page .post-card .trend-post-card-content .ant-card-cover {
  border-radius: 5px 5px 0px 0px;
  background: transparent;
}
#all-post-page .post-card.trend-post-card .ant-card-cover img,
#all-post-page .post-card .trend-post-card-content .ant-card-cover img {
  border-radius: 5px 5px 0px 0px;
  object-fit: cover;
  height: 307px;
}
#all-post-page .post-card.trend-post-card .ant-card-cover video,
#all-post-page .post-card .trend-post-card-content .ant-card-cover video {
  height: 307px;
  object-fit: cover;
}
#all-post-page .post-card.trend-post-card:hover,
#all-post-page .post-card .trend-post-card-content:hover {
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
}
#all-post-page .post-card.trend-post-card .ant-card-meta-detail,
#all-post-page .post-card .trend-post-card-content .ant-card-meta-detail {
  width: calc(100% - 60px);
}
#all-post-page .post-card .ant-card-body {
  border: 1px solid #e8e8e8;
  height: auto;
}
#all-post-page .post-card .ant-card-body .ant-avatar {
  width: 50px !important;
  height: 50px !important;
}
#all-post-page .post-card .ant-card-body h2 {
  font-size: 16px !important;
}
#all-post-page .post-card .ant-card-body .caps-heading {
  font-size: 10px !important;
}
#all-post-page .post-card .post-stats {
  margin-top: 19px !important;
}
#all-post-page .post-filters {
  margin-bottom: 49px;
}
#all-post-page .post-filters .ant-checkbox + span {
  vertical-align: top;
  padding-left: 9px;
  line-height: normal;
}
#all-post-page .post-filters .ant-checkbox-wrapper {
  margin-left: 25px !important;
}
#all-post-page .post-filters .trend-select .ant-select-selection__placeholder {
  margin-top: -11px;
}
#all-post-page .post-filters .trend-select .ant-select-selection-selected-value {
  margin-top: -1px;
}
.plan-list .plan-header {
  background: #3c3c3c;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 8px 0;
}
.plan-list .ant-radio-group .plan-radio > span {
  height: 86px;
  display: block;
  padding: 0 !important;
}
.plan-list .ant-radio-group .plan-radio > span .ant-row-flex {
  height: 100%;
}
.plan-list .ant-radio-group .plan-radio.ant-radio-wrapper:last-child {
  border-bottom: 1px solid #3c3c3c;
}
.plan-list .ant-radio-group .plan-radio.ant-radio-wrapper:last-child.ant-radio-wrapper-checked {
  border: 1px solid #ffb917;
}
.plan-list .ant-radio-group .plan-radio .plan-icon {
  margin-left: 25px;
  margin-right: 11px;
  display: inline-block;
}
@media (max-width: 767.98px) {
  .plan-list .plan-header {
    display: none;
  }
}
.plan-radio {
  min-height: 86px;
}
.plan-radio.current-plan.ant-radio-wrapper-checked {
  border: 1px solid #3c3c3c !important;
}
.plan-radio.current-plan.ant-radio-wrapper-checked .ant-radio {
  display: none !important;
}
.plan-radio-popular {
  min-height: 108px;
}
.plan-radio.custom-radio.ant-radio-wrapper {
  border-top: 0;
}
.plan-radio.ant-radio-wrapper {
  white-space: inherit !important;
  border: 1px solid #3c3c3c;
  border-bottom: 0;
  background-color: #0f0f0f;
  margin-right: 0px;
}
.plan-radio.ant-radio-wrapper-checked {
  border: 1px solid #ffb917;
}
.plan-radio .ant-radio {
  position: absolute;
  top: 0px;
  right: 24px;
  height: 100%;
  display: flex !important;
  align-items: center;
}
.plan-radio .ant-radio-inner {
  width: 36px !important;
  height: 36px !important;
  background: #3c3c3c !important;
  border-color: #7a7a7a !important;
}
.plan-radio .ant-radio-inner:after {
  width: 36px !important;
  height: 36px !important;
}
.plan-radio .ant-radio-checked::after {
  content: none !important;
}
.plan-radio .ant-radio-checked .ant-radio-inner {
  background: #ffb917 !important;
  border-color: #ffb917 !important;
}
.plan-radio .ant-radio-checked .ant-radio-inner::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ffb917;
  background-color: #ffb917;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}
.plan-radio .plan-cost {
  text-align: right;
}
.plan-radio .col-type {
  display: none;
}
@media (max-width: 767.98px) {
  .plan-radio {
    margin-bottom: 20px !important;
    border-radius: 2px;
  }
  .plan-radio .col-type {
    display: block;
  }
  .plan-radio.ant-radio-wrapper {
    border: 1px solid #3c3c3c;
  }
  .plan-radio.ant-radio-wrapper-checked {
    border: 1px solid #ffb917;
  }
  .plan-radio > span {
    height: auto !important;
  }
  .plan-radio .plan-name,
  .plan-radio .plan-description,
  .plan-radio .plan-initial-credits,
  .plan-radio .plan-duration,
  .plan-radio .plan-popular {
    text-align: center;
    margin-top: 30px;
    line-height: 0.89;
  }
  .plan-radio .plan-name .col-type,
  .plan-radio .plan-description .col-type,
  .plan-radio .plan-initial-credits .col-type,
  .plan-radio .plan-duration .col-type,
  .plan-radio .plan-popular .col-type {
    margin-top: 5px;
    font-weight: normal !important;
    font-family: 'TruenoRegular';
  }
  .plan-radio .plan-popular {
    display: block;
    padding-top: 20px;
  }
  .plan-radio .plan-name {
    margin-top: 14px;
    font-size: 18px;
    display: block !important;
    text-align: center;
  }
  .plan-radio .plan-duration {
    margin-top: 30px;
  }
  .plan-radio .plan-icon {
    padding-top: 32px;
    display: block !important;
    text-align: center;
    margin: 0 !important;
  }
  .plan-radio .plan-description {
    padding-left: 23px;
    padding-right: 23px;
  }
  .plan-radio .plan-cost {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 23px;
  }
  .plan-radio .ant-radio {
    bottom: 33px;
    width: 100%;
    left: 0;
    top: unset;
    height: fit-content;
    justify-content: center;
  }
}
.StripeElement {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #2a2a2a !important;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none !important;
  color: #eee;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 5px;
  margin-bottom: 12px;
  font-size: 24px;
}
.StripeElement .input {
  height: 50px;
  color: #FFB917;
}
.StripeElement::placeholder {
  color: #3A3A3A;
}
.text-gradient {
  text-transform: uppercase;
}
.text-gradient.silver {
  background: linear-gradient(to right, #b2bacb 0%, #f7f7f7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient.gold {
  background: linear-gradient(to right, #ceb785 0%, #f7f7f7 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#add-credits {
  margin-top: 67px;
}
#add-credits .ant-slider .ant-slider-mark {
  margin-top: 20px;
}
#add-credits .ant-slider .ant-slider-mark .ant-slider-mark-text {
  color: #747474;
  font-size: 10px;
  font-weight: 900;
}
#add-credits .ant-slider .ant-slider-dot {
  visibility: hidden;
}
#add-credits .ant-slider .ant-slider-rail,
#add-credits .ant-slider .ant-slider-step,
#add-credits .ant-slider .ant-slider-track {
  height: 7px;
}
#add-credits .ant-slider .ant-slider-handle {
  margin-top: -3px;
}
#add-credits .order-summary {
  height: 100%;
  padding: 122px 20px 12px !important;
  width: 300px;
  position: relative;
  border: solid 1px #272727;
  background-color: #131313;
  height: auto;
}
@media (max-width: 576px) {
  #add-credits .order-summary {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 0px !important;
  }
}
#add-credits .order-summary > h3 {
  margin-top: 32px;
  margin-bottom: 0px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
  padding-bottom: 0px;
  display: none;
  font-size: 13px;
}
@media (max-width: 576px) {
  #add-credits .order-summary > h3 {
    display: block;
    margin-bottom: 208px;
  }
}
#add-credits .order-summary .add-credits-component {
  padding-bottom: 19px;
  border-bottom: 1px solid #393939;
}
@media (max-width: 576px) {
  #add-credits .order-summary .add-credits-component {
    display: block;
    border-bottom: none;
    padding-bottom: 0;
  }
}
#add-credits .order-summary .add-credits-component.total {
  margin-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 38px;
}
@media (max-width: 576px) {
  #add-credits .order-summary .add-credits-component.total {
    padding-bottom: 45px;
    border-bottom: 1px solid #393939;
  }
}
#add-credits .order-summary h3 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #ffffff;
  font-family: 'galano';
}
#add-credits .order-summary .credit-total-amount {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  font-family: Helvetica, sans-serif !important;
}
#add-credits .order-summary .credit-vip .credit-label,
#add-credits .order-summary .credit-standard .credit-label,
#add-credits .order-summary .credit-vip .credit-total-label,
#add-credits .order-summary .credit-standard .credit-total-label {
  font-family: Helvetica, sans-serif;
  line-height: 1.14;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}
#add-credits .order-summary .credit-vip .credit-label.orange,
#add-credits .order-summary .credit-standard .credit-label.orange,
#add-credits .order-summary .credit-vip .credit-total-label.orange,
#add-credits .order-summary .credit-standard .credit-total-label.orange {
  color: #f6d384;
}
#add-credits .order-summary .credit-vip .credit-total-label,
#add-credits .order-summary .credit-standard .credit-total-label {
  font-family: Helvetica, sans-serif !important;
}
#add-credits .order-summary .credit-vip .credit-value,
#add-credits .order-summary .credit-standard .credit-value {
  margin-bottom: 6px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
}
#add-credits .order-summary .credit-vip .credit-total-amount,
#add-credits .order-summary .credit-standard .credit-total-amount {
  margin-bottom: 6px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
}
#add-credits .order-summary .credit-standard .credit-value {
  font-size: 28px;
}
#add-credits .order-summary .total-credits {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 154px;
  height: 154px;
  background: linear-gradient(to bottom left, #ffffff, #f5cf79);
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  left: 0;
  bottom: 24px;
  padding-top: 46px;
}
#add-credits .order-summary .total-credits:after {
  background-color: #000;
  position: absolute;
  border-radius: 100%;
  width: 142px;
  height: 142px;
  content: '';
  z-index: -1;
  left: 6px;
  top: 6px;
}
#add-credits .order-summary .total-credits .value {
  font-size: 50px;
  line-height: 28px;
  margin-bottom: 14px;
  font-family: SFProDisplay;
  font-weight: bold;
}
#add-credits .order-summary .total-credits .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
#add-credits .order-summary .add-credits-component {
  padding-bottom: 16px;
}
#add-credits .order-summary .add-credits-component .total {
  border: none;
  margin-bottom: 0px;
}
#add-credits .order-summary .confirmation button {
  margin-bottom: 15px;
}
#add-credits .order-summary .confirmation .purchase-description {
  font-family: SFProDisplay;
}
#add-credits .order-summary .credit-standard-section .credit-label,
#add-credits .order-summary .credit-standard-section .credit-total-label {
  font-family: SFProDisplay;
  font-weight: normal;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}
#add-credits .order-summary .credit-standard-section .credit-label.orange,
#add-credits .order-summary .credit-standard-section .credit-total-label.orange {
  color: #f6d384;
}
#add-credits .order-summary .credit-standard-section .credit-total-label {
  font-family: SFProDisplay;
  font-weight: normal;
}
#add-credits .order-summary .credit-standard-section .credit-value {
  margin-bottom: 6px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: bold;
}
#add-credits .order-summary .credit-standard-section .credit-total-amount {
  margin-bottom: 6px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: bold;
}
#add-credits .order-summary .order-total {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  font-family: SFProDisplay;
  font-weight: normal;
  margin-top: 23px;
  margin-bottom: 26px;
}
#add-credits .order-summary .purchase-btn {
  height: 50px;
  font-size: 16px;
  text-transform: none !important;
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic !important;
  font-weight: 800 !important;
  line-height: 18px;
  letter-spacing: -0.004em;
}
.credit-form {
  border-radius: 3px;
  border: solid 1px #272727;
  background-color: #131313;
  padding: 40px 38px !important;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
}
.credit-form.ant-col.ant-col-md-8 {
  width: 619px;
  margin-right: 20px;
}
.credit-form .creator-credits {
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 42px;
  letter-spacing: -0.008em;
  margin-bottom: 9px;
}
.credit-form .sub-credits-text {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.008em;
}
.credit-form .credits-descriptions {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.008em;
  color: #ffffff;
  margin-bottom: 109px;
}
.credits-slider {
  position: relative;
}
.credits-slider .ant-tooltip-inner {
  width: max-content;
  color: #000;
  font-size: 12px;
  border-radius: 11px;
  height: 22px;
  min-height: 22px;
  font-weight: bold;
  padding: 5px 10px;
  line-height: 0.97;
}
.credits-slider .discount-percent-tag {
  position: absolute;
  width: 100%;
  top: 0px;
}
.credits-slider .discount-percent-tag .tag {
  text-align: center;
  color: #000;
  font-size: 12px;
  padding-top: 33px;
  height: 60px;
  width: 33px;
  top: -27px;
  background-size: 33px 60px;
  position: absolute;
  margin-left: -16.5px;
  font-family: SFProDisplay;
  font-weight: 300;
}
.credits-slider .discount-percent-tag .tag .percent {
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
}
.credits-slider .discount-percent-tag .tag .percent-label {
  margin-top: 2px;
  font-size: 8px;
  line-height: normal;
  font-weight: bold;
}
.credits-slider.standard .ant-slider-handle {
  background-image: url('../../images/gold-slider@2x.png');
}
.credits-slider.standard .discount-percent-tag .tag {
  background-image: url('../../images/gold-tag@2x.png');
}
.credits-slider.standard .ant-tooltip-placement-top .ant-tooltip-inner {
  background: #f5cf79;
}
.credits-slider.standard .ant-tooltip-placement-top .ant-tooltip-arrow {
  border-right-color: #f5cf79;
  border-bottom-color: #f5cf79;
}
.credits-slider .ant-slider:hover .ant-slider-track {
  background: linear-gradient(to left, #ffffff, #f5cf79, #f5cf79);
}
.credits-slider .ant-slider-track {
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  background: linear-gradient(to left, #ffffff, #f5cf79, #f5cf79);
}
.credits-slider .ant-slider-dot {
  background-color: #0f0f0f !important;
}
.credits-slider .ant-slider-handle {
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  border: 0 !important;
  margin-top: -21px !important;
  margin-left: 0 !important;
}
.credits-slider .ant-slider-handle:not(.ant-tooltip-open) {
  border: 0 !important;
  box-shadow: none;
}
.credits-slider .ant-slider-rail {
  height: 7px;
  border: 1px solid #3c3c3c;
  border-radius: 35px;
  background-color: #0f0f0f;
}
.credits-slider .discount-container {
  margin: 0 6px;
  position: relative;
}
.credits-slider .discount-range {
  width: '100%';
  margin-top: 20px;
}
.credits-slider .discount-range .discount {
  width: 20%;
  height: 5px;
  float: left;
  border-left: 1px solid #393939;
  border-bottom: 1px solid #393939;
  border-right: 1px solid #393939;
}
.credits-slider .discount-range .discount.in-range {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
table .message-col {
  cursor: pointer;
}
.messages-table td.avatar {
  width: 55px !important;
}
.messages-table td.author {
  width: 200px;
}
.blur-bg {
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  position: fixed;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8) !important;
}
#sign-up-page {
  background: url('../../images/Sand@2x.jpg');
}
@media (max-width: 576px) {
  #sign-up-page .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
#sign-up-page .wrapper h2,
#sign-up-page .wrapper p {
  width: 100%;
}
@media (max-width: 576px) {
  #sign-up-page .wrapper h2,
  #sign-up-page .wrapper p {
    text-align: center;
    width: 262px;
  }
}
#sign-up-page .wrapper h2 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
}
#sign-up-page .wrapper p {
  line-height: 1.71;
  font-size: 14px;
  min-height: 59px;
}
#sign-up-page .ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}
#sign-up-page .setup-steps {
  color: #fff;
  width: 379px;
  border-radius: 28.5px;
  background: #131313;
  border: solid 1px #272727;
}
@media (max-width: 576px) {
  #sign-up-page .setup-steps {
    width: 100%;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}
#sign-up-page .setup-steps .step {
  margin-left: 21px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'TruenoBold';
  font-size: 12px;
  float: left;
  color: #444444;
}
@media (max-width: 576px) {
  #sign-up-page .setup-steps .step {
    width: 50%;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 576px) {
  #sign-up-page .setup-steps .step:first-child {
    text-align: right;
  }
}
@media (max-width: 576px) {
  #sign-up-page .setup-steps .step:last-child {
    text-align: left;
  }
}
#sign-up-page .setup-steps .step-label,
#sign-up-page .setup-steps .step-symbol {
  display: inline-block;
  text-align: left;
}
#sign-up-page .setup-steps .step-label {
  font-family: 'TruenoSemiBold';
}
@media (max-width: 576px) {
  #sign-up-page .setup-steps .step-label {
    width: 79px;
    font-size: 10px;
    vertical-align: middle;
  }
}
#sign-up-page .setup-steps .step.active .step-symbol {
  background: #ffb917;
  color: #000;
}
#sign-up-page .setup-steps .step.active .step-label {
  color: #fff;
}
#sign-up-page .setup-steps .step-symbol {
  margin-right: 10px;
  box-shadow: -20px 22px 23px 0 rgba(0, 0, 0, 0.19);
  background-color: #272727;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  text-align: center;
  color: #636363;
  line-height: 28px;
}
#sign-up-page .ant-form-item {
  margin-bottom: 25px;
}
#sign-up-page .header {
  margin-top: 35px;
  margin-bottom: 29px;
}
#sign-up-page .back {
  color: #fff;
  font-family: 'TruenoBoldItalic';
}
#sign-up-page .back .anticon {
  font-size: 18px;
}
#sign-up-page .has-error .ant-input {
  background: none;
}
#sign-up-page p {
  font-family: 'TruenoLight', Arial, sans-serif;
  margin-bottom: 25px;
  line-height: 1.71;
}
#sign-up-page .trend-label {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000;
}
#sign-up-page input {
  font-family: SFProDisplay;
  height: 49px;
  padding-left: 0;
  padding-bottom: 15px;
  padding-top: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}
#sign-up-page input::placeholder {
  color: #757575;
}
#sign-up-page .stripe-form .stripe-element-input {
  font-size: 15px;
  height: 49px;
  padding-bottom: 18px;
  padding-top: 14px;
  font-family: 'TruenoRegular';
  margin-bottom: 25px;
}
#sign-up-page .signup-form {
  margin-right: 28px;
}
@media (max-width: 576px) {
  #sign-up-page .signup-form {
    margin-right: 0px;
  }
}
@media (min-width: 768px) {
  #sign-up-page .signup-form.ant-col.ant-col-md-8 {
    width: 621px;
  }
}
#sign-up-page .order-summary {
  height: 100%;
  padding: 122px 20px 12px !important;
  width: 300px;
  position: relative;
  border: solid 1px #272727;
  background-color: #131313;
}
@media (max-width: 576px) {
  #sign-up-page .order-summary {
    width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 0px !important;
  }
}
#sign-up-page .order-summary > h3 {
  margin-top: 32px;
  margin-bottom: 0px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
  padding-bottom: 0px;
  display: none;
  font-size: 13px;
}
@media (max-width: 576px) {
  #sign-up-page .order-summary > h3 {
    display: block;
    margin-bottom: 208px;
  }
}
#sign-up-page .order-summary .add-credits-component {
  padding-bottom: 19px;
  border-bottom: 1px solid #393939;
}
@media (max-width: 576px) {
  #sign-up-page .order-summary .add-credits-component {
    display: block;
    border-bottom: none;
    padding-bottom: 0;
  }
}
#sign-up-page .order-summary .add-credits-component.total {
  margin-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 38px;
}
@media (max-width: 576px) {
  #sign-up-page .order-summary .add-credits-component.total {
    padding-bottom: 45px;
    border-bottom: 1px solid #393939;
  }
}
#sign-up-page .order-summary h3 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #ffffff;
  font-family: 'galano';
}
#sign-up-page .order-summary .credit-total-amount {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  font-family: Helvetica, sans-serif !important;
}
#sign-up-page .order-summary .credit-vip .credit-label,
#sign-up-page .order-summary .credit-standard .credit-label,
#sign-up-page .order-summary .credit-vip .credit-total-label,
#sign-up-page .order-summary .credit-standard .credit-total-label {
  font-family: Helvetica, sans-serif;
  line-height: 1.14;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}
#sign-up-page .order-summary .credit-vip .credit-label.orange,
#sign-up-page .order-summary .credit-standard .credit-label.orange,
#sign-up-page .order-summary .credit-vip .credit-total-label.orange,
#sign-up-page .order-summary .credit-standard .credit-total-label.orange {
  color: #f6d384;
}
#sign-up-page .order-summary .credit-vip .credit-total-label,
#sign-up-page .order-summary .credit-standard .credit-total-label {
  font-family: Helvetica, sans-serif !important;
}
#sign-up-page .order-summary .credit-vip .credit-value,
#sign-up-page .order-summary .credit-standard .credit-value {
  margin-bottom: 6px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
}
#sign-up-page .order-summary .credit-vip .credit-total-amount,
#sign-up-page .order-summary .credit-standard .credit-total-amount {
  margin-bottom: 6px;
  font-family: Helvetica, sans-serif !important;
  font-weight: bold;
}
#sign-up-page .order-summary .credit-standard .credit-value {
  font-size: 28px;
}
#sign-up-page .order-summary .total-credits {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 154px;
  height: 154px;
  background: linear-gradient(to bottom left, #ffffff, #f5cf79);
  position: absolute;
  border-radius: 100%;
  z-index: 1;
  left: 0;
  bottom: 24px;
  padding-top: 46px;
}
#sign-up-page .order-summary .total-credits:after {
  background-color: #000;
  position: absolute;
  border-radius: 100%;
  width: 142px;
  height: 142px;
  content: '';
  z-index: -1;
  left: 6px;
  top: 6px;
}
#sign-up-page .order-summary .total-credits .value {
  font-size: 50px;
  line-height: 28px;
  margin-bottom: 14px;
  font-family: SFProDisplay;
  font-weight: bold;
}
#sign-up-page .order-summary .total-credits .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
#sign-up-page .confirmation button {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
  line-height: 18px;
  text-transform: none;
  width: 238px;
  height: 56px;
  font-size: 20px;
  border-radius: 32px;
  margin-bottom: 47px;
}
#sign-up-page .confirmation-text p {
  width: 298px;
  height: 62px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
#sign-up-page .due {
  margin-top: 25px;
  margin-bottom: 41px;
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
  line-height: 18px;
  color: #000;
}
#sign-up-page .terms-conditions {
  margin-bottom: 41px;
}
#sign-up-page .terms-conditions .ant-checkbox-wrapper + span,
#sign-up-page .terms-conditions .ant-checkbox + span {
  color: #000;
  padding: 0 0 0 15px;
  letter-spacing: normal;
}
#sign-up-page .trend-terms-checkbox {
  padding-bottom: 1px;
  margin-right: 0px !important;
}
#sign-up-page .trend-terms-checkbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: #fff;
}
#sign-up-page .registration-message {
  padding-top: 210px;
  color: #fff;
}
#sign-up-page .registration-message img.trend {
  height: 16px;
  width: 83px;
  margin-bottom: 23px;
}
#sign-up-page .registration-message p {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: normal;
  font-size: 30px;
  line-height: 40px;
}
#sign-up-page .registration-message p.sub {
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
}
#sign-up-page .registration-message .quoted-by {
  vertical-align: middle;
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}
#sign-up-page .registration-message .quoted-by img {
  margin-right: 15px;
  width: 35px;
  height: 25px;
  vertical-align: middle;
}
@media (max-width: 576px) {
  #onboarding-page .wrapper {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
#onboarding-page .wrapper h2,
#onboarding-page .wrapper p {
  width: 100%;
}
@media (max-width: 576px) {
  #onboarding-page .wrapper h2,
  #onboarding-page .wrapper p {
    text-align: center;
    width: 262px;
  }
}
#onboarding-page .wrapper h2 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
}
#onboarding-page .wrapper p {
  line-height: 1.71;
  font-size: 14px;
  min-height: 59px;
}
#onboarding-page .header {
  margin-top: 35px;
  margin-bottom: 29px;
}
#onboarding-page .setup-steps {
  color: #fff;
  width: 379px;
  border-radius: 28.5px;
  background: #131313;
  border: solid 1px #272727;
}
@media (max-width: 576px) {
  #onboarding-page .setup-steps {
    width: 100%;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
  }
}
#onboarding-page .setup-steps .step {
  margin-left: 21px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'TruenoBold';
  font-size: 12px;
  float: left;
  color: #444444;
}
@media (max-width: 576px) {
  #onboarding-page .setup-steps .step {
    width: 50%;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 576px) {
  #onboarding-page .setup-steps .step:first-child {
    text-align: right;
  }
}
@media (max-width: 576px) {
  #onboarding-page .setup-steps .step:last-child {
    text-align: left;
  }
}
#onboarding-page .setup-steps .step-label,
#onboarding-page .setup-steps .step-symbol {
  display: inline-block;
  text-align: left;
}
#onboarding-page .setup-steps .step-label {
  font-family: 'TruenoSemiBold';
}
@media (max-width: 576px) {
  #onboarding-page .setup-steps .step-label {
    width: 79px;
    font-size: 10px;
    vertical-align: middle;
  }
}
#onboarding-page .setup-steps .step.active .step-symbol {
  background: #ffb917;
  color: #000;
}
#onboarding-page .setup-steps .step.active .step-label {
  color: #fff;
}
#onboarding-page .setup-steps .step-symbol {
  margin-right: 10px;
  box-shadow: -20px 22px 23px 0 rgba(0, 0, 0, 0.19);
  background-color: #272727;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  text-align: center;
  color: #636363;
  line-height: 28px;
}
#onboarding-page h1,
#onboarding-page p {
  color: #fff;
  text-align: center;
}
#onboarding-page h1 {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  margin-bottom: 28px;
}
@media (max-width: 576px) {
  #onboarding-page h1 {
    width: 208px;
    font-size: 18px;
  }
}
#onboarding-page p {
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
#onboarding-page .calendar {
  width: 641px;
  height: 356px;
  object-fit: contain;
  background-color: #131313;
}
@media (max-width: 576px) {
  #onboarding-page .calendar {
    width: 100%;
  }
}
.brand-integrations .ant-btn-link {
  width: 100%;
  display: block;
  white-space: pre-wrap;
}
.brand-integrations .card {
  padding: 26px 19px 21px;
  background: #fff;
}
.brand-integrations .card-logo {
  margin-bottom: 37px;
}
.brand-integrations .card-logo img {
  width: 117px;
  height: 115px;
}
.brand-integrations .card-description {
  font-family: 'TruenoRegular', Arial, sans-serif;
  color: #4a4a4a;
  line-height: 1.58;
  text-align: left;
  min-height: 120px;
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}
.brand-integrations .card-actions {
  margin-top: 20px;
  font-family: 'TruenoExtraBold', Arial, sans-serif;
  color: #000;
  font-size: 16px;
}
.brand-integrations .card-actions .circle {
  font-family: 'TruenoBold', Arial, sans-serif;
  display: inline-block;
  color: #151515;
  font-size: 20px;
  margin-right: 8px;
  position: relative;
  z-index: 1;
  width: 28px;
  height: 28px;
}
.brand-integrations .card-actions .circle::after {
  z-index: -1;
  top: 0.5px;
  content: '';
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: #ffb917;
}
.brand-integrations .card.active .card-actions {
  color: #2e84ed;
}
.brand-notifications .note {
  color: #999999;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.008em;
  margin-top: 4px;
}
.brand-notifications .notif-container {
  background: #0F0F0F;
  border: 1px solid #3C3C3C;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  height: 233px;
  margin-top: 41px;
  padding: 35px 33px 38px 40px;
  width: 484px;
}
.brand-notifications .notif-container .new-message-row,
.brand-notifications .notif-container .new-post-row {
  margin-top: 34px;
}
.brand-notifications .notif-container .notif-row .notif-action {
  text-align: right;
}
.brand-notifications .notif-switch-action {
  border: 1px solid #3c3c3c !important;
  height: 31px;
  width: 51px;
}
.brand-notifications .notif-switch-action:after {
  height: 28px;
  top: 0;
  width: 28px;
}
#free-credits-page .page-header {
  background-color: #eef7ee;
  padding: 0 41px 0 41px;
}
#free-credits-page .page-header h1 {
  padding-top: 66px;
  margin-bottom: 46px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #000000;
  position: relative;
}
#free-credits-page .page-header h1 img {
  position: absolute;
  width: 151px;
  height: 114px;
  right: 0px;
  top: 34px;
}
#free-credits-page .page-header .ant-menu-horizontal {
  background-color: transparent;
  border-bottom: none;
}
#free-credits-page .page-header .ant-menu-horizontal .ant-menu-item {
  width: 93px;
  margin-right: 38px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  padding: 0px;
  text-align: left;
  padding-bottom: 25px;
  border-bottom: none;
  text-align: center;
}
#free-credits-page .page-header .ant-menu-horizontal .ant-menu-item:before {
  position: absolute;
  content: '';
  width: 31px;
  height: 2px;
  background-color: transparent;
  bottom: 0;
  left: 50%;
  transition: background-color 0.3s ease;
}
#free-credits-page .page-header .ant-menu-horizontal .ant-menu-item-selected {
  font-weight: bold;
}
#free-credits-page .page-header .ant-menu-horizontal .ant-menu-item-selected a {
  color: #000;
}
#free-credits-page .page-header .ant-menu-horizontal .ant-menu-item-selected:before {
  position: absolute;
  content: '';
  width: 31px;
  height: 2px;
  background-color: orange;
  bottom: 0;
  left: 29px;
}
#free-credits-reviews-page {
  margin-bottom: 40px;
}
#free-credits-reviews-page .review-header h2 {
  padding-top: 71px;
  margin-bottom: 28px;
  position: relative;
  font-style: italic;
  font-size: 24px;
  line-height: 1.25;
}
#free-credits-reviews-page .review-header h2 small {
  font-size: 18px;
  font-style: normal;
}
#free-credits-reviews-page .review-header h2 img {
  position: absolute;
  width: 164px;
  height: 37px;
  top: 74px;
  right: 0;
}
#free-credits-reviews-page .review {
  padding-bottom: 58px;
  border-bottom: 1px solid #e8e8e8;
}
#free-credits-reviews-page .review.g2-crowd h2 img {
  width: 145px;
  height: 80px;
  top: 60px;
}
#free-credits-reviews-page .review.approved .success-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
}
#free-credits-reviews-page .review.approved .review-link,
#free-credits-reviews-page .review.approved .review-status {
  margin-left: calc(60px + 20px);
}
#free-credits-reviews-page .review-content h3 {
  font-size: 13px;
  margin-bottom: 9px;
}
#free-credits-reviews-page .review-content p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
  width: 484px;
}
#free-credits-reviews-page .review-content .review-url {
  position: relative;
}
#free-credits-reviews-page .review-content .review-url p {
  height: 84px;
  margin-bottom: 10px;
}
#free-credits-reviews-page .review-content .review-url .ant-btn {
  padding-top: 5px;
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  position: absolute;
  right: 4px;
  text-transform: uppercase;
  font-size: 13px;
  color: #000;
  height: 40px;
  width: 145px;
  border-radius: 2px;
  top: 25px;
}
#free-credits-reviews-page .review-content .review-form p {
  height: 29px;
  margin-bottom: 23px;
}
#free-credits-reviews-page .review-content .review-input {
  height: 51px;
  border-radius: 2px;
  border: solid 1px #a9b0b9;
  position: relative;
  margin-bottom: 26px;
}
#free-credits-reviews-page .review-content .review-input input.ant-input {
  padding-left: 15px;
  border: none;
  height: 49px;
}
#free-credits-reviews-page .review-content .review-input input.ant-input:focus {
  box-shadow: none;
}
#free-credits-reviews-page .review-content .review-input input.ant-input::placeholder {
  color: #4e4e4e;
}
#free-credits-reviews-page .review-content .review-input .ant-btn {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  position: absolute;
  right: 4px;
  text-transform: uppercase;
  font-size: 13px;
  color: #000;
  height: 40px;
  width: 181px;
  border-radius: 2px;
  top: 4px;
}
#free-credits-reviews-page .review-content .review-link,
#free-credits-reviews-page .review-content .review-status {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
}
#free-credits-reviews-page .review-content .review-status {
  height: 14px;
}
#free-credits-reviews-page .review-content .review-status.pending {
  color: #ffb917;
}
#free-credits-reviews-page .review-content .review-status.rejected {
  color: #ff1717;
}
#free-credits-reviews-page .review-content .review-status.approved {
  color: #00b628;
}
#refer-brand-page .tab-header h1 {
  position: relative;
  padding-top: 71px;
  font-family: 'TruenoRegular', Arial, sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000;
}
#refer-brand-page .tab-header h1 small {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}
#refer-brand-page .tab-header h1 img {
  position: absolute;
  right: 0px;
  top: 23px;
  width: 101px;
  height: 100.8px;
}
#refer-brand-page .tab-content {
  padding-top: 36px;
}
#refer-brand-page .tab-content h2 {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #000000;
}
#refer-brand-page .tab-content p {
  font-family: 'TruenoRegular', Arial, sans-serif;
  width: 564px;
  height: 84px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 17px;
}
#refer-brand-page .tab-content .ant-form-item-label {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 14px;
}
#refer-brand-page .tab-content .ant-input {
  width: 471px;
  height: 50px;
  border-color: #a9b0b9;
  border-radius: 2px;
  padding-left: 15px;
}
#refer-brand-page .tab-content .ant-input::placeholder {
  color: #4e4e4e;
}
#refer-brand-page .tab-content .ant-btn {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
  right: 4px;
  text-transform: uppercase;
  font-size: 13px;
  color: #000;
  height: 40px;
  width: 181px;
  border-radius: 2px;
  line-height: 1;
}
#refer-brand-page .tab-content .referrals {
  margin-top: 40px;
}
#refer-brand-page .tab-content .referrals .referral {
  margin-bottom: 27px;
}
#refer-brand-page .tab-content .referrals .referral-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
}
#refer-brand-page .tab-content .referrals .referral-icon img {
  width: 60px;
  height: 60px;
}
#refer-brand-page .tab-content .referrals .referral-details {
  display: inline-block;
  vertical-align: top;
  padding-top: 2px;
}
#refer-brand-page .tab-content .referrals .referral-details-brand {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  color: #000000;
}
#refer-brand-page .tab-content .referrals .referral-details-status {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00b628;
}
#refer-brand-page .tab-content .referrals .referral-details-status.pending {
  color: #ffb917;
}
#refer-brand-page .tab-content .referrals .referral-details-status.rejected {
  color: #ff1717;
}
#refer-brand-page .tab-content .referrals .referral-details-status.approved {
  color: #00b628;
}
#sign-up-v2-page {
  font-family: SFProDisplay;
  font-weight: normal;
}
#sign-up-v2-page h1,
#sign-up-v2-page p {
  color: #fff;
}
@media (max-width: 576px) {
  #sign-up-v2-page .ant-col-xs-20.header {
    width: 220px;
  }
}
#sign-up-v2-page h1 {
  font-family: SFProDisplay;
  font-size: 40px;
  line-height: 42px;
  margin-bottom: 79px;
  font-weight: 800;
  text-align: center;
}
@media (max-width: 576px) {
  #sign-up-v2-page h1 {
    font-size: 22px;
    margin-bottom: 49px;
  }
}
#sign-up-v2-page h1 .sub-header {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 300 !important;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #999;
  margin-top: 13px;
  letter-spacing: -0.008em;
}
#sign-up-v2-page p {
  font-family: 'SFProMedium';
  width: 492px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 60px;
  font-weight: bold;
}
#sign-up-v2-page .ant-form-item {
  margin-bottom: 24px;
}
@media (max-width: 576px) {
  #sign-up-v2-page .ant-form-item {
    font-size: 22px;
    margin-bottom: 40px;
  }
}
#sign-up-v2-page .ant-form-item-label label {
  font-family: SFProDisplay;
  font-weight: bold;
  font-size: 18px !important;
  color: #fff;
}
#sign-up-v2-page .trend-input {
  padding-top: 0px;
  padding-left: 0px;
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 16px;
}
#sign-up-v2-page .trend-input::placeholder {
  color: #989898;
}
#sign-up-v2-page .has-error .trend-input {
  background-color: #000;
}
#sign-up-v2-page .has-error .ant-form-explain {
  right: 0px;
  top: -30px;
  position: absolute;
}
#sign-up-v2-page .ant-btn {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
  border-radius: 32px;
  padding: 18px 44px 18px;
  margin-bottom: 107px;
  height: auto;
  font-size: 20px;
  line-height: 18px;
}
#sign-up-v2-page .ant-btn > span {
  color: #000;
}
@media (max-width: 576px) {
  #sign-up-v2-page .ant-btn {
    font-size: 22px;
    margin-top: 12px;
  }
}
#sign-up-v2-page .submit {
  margin-top: 76px;
}
#mobile-view-page {
  color: #fff;
}
#mobile-view-page .image {
  margin-top: 93px;
  margin-bottom: 36px;
}
#mobile-view-page h1 {
  color: #fff;
  font-size: 22px;
  text-align: center !important;
}
#mobile-view-page h1 .sub-header {
  margin-top: 13px;
  font-size: 16px;
  font-family: SFProDisplay;
  font-weight: normal;
  color: #999;
}
#mobile-view-session-page {
  color: #fff;
  background: url('../../images/Sand@2x.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
}
#mobile-view-session-page .image {
  margin-top: 93px;
  margin-bottom: 36px;
}
#mobile-view-session-page h1 {
  color: #fff;
  font-size: 22px;
  text-align: center !important;
}
#mobile-view-session-page h1 .sub-header {
  margin-top: 13px;
  font-size: 16px;
  font-family: SFProDisplay;
  font-weight: normal;
  color: #999;
}
#open-store-page .trend-steps {
  margin-top: 47px;
}
#open-store-page h1 {
  text-align: center;
  color: #fff;
  font-size: 40px;
  width: 422px;
  margin-bottom: 13px;
  line-height: 42px;
  margin-top: 59px;
}
#open-store-page .sub-header {
  font-family: SFProDisplay;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  color: #999;
  height: 75px;
  margin-bottom: 10px;
}
#open-store-page .trend-textarea {
  background: #151515;
  padding: 16px 15px 17px 15px;
}
#open-store-page .trend-textarea::placeholder {
  color: #989898;
}
#open-store-page .submit .ant-btn {
  font-family: SFProDisplay;
  font-weight: normal;
  color: #000;
  height: 58px;
  width: 258px;
  font-style: italic;
  border-radius: 32px;
  font-weight: 800;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 63px;
}
#open-store-page .ant-form-item {
  margin-bottom: 40px;
}
#open-store-page .ant-form-item-label {
  line-height: 26px;
  margin-bottom: 7px;
}
#open-store-page .ant-form-item-label > label {
  color: #fff;
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: bold;
}
.product-shipment-table .ant-table-content {
  border-top: 1px solid #e8e8e8;
}
.product-shipment-table .ant-table-content:before,
.product-shipment-table .ant-table-content:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.product-shipment-table .ant-table-content:before {
  left: -100%;
}
.product-shipment-table .ant-table-content:after {
  right: -100%;
}
#admin-layout {
  overflow-x: hidden;
}
#admin-layout .search-filters {
  position: relative;
  padding-top: 15px;
  padding-bottom: 16px;
}
#admin-layout .search-filters .trend-select {
  width: 100%;
}
#admin-layout .search-filters .trend-select .ant-select-selection__clear {
  right: 25px;
}
#admin-layout .search-filters .search .ant-select-selection {
  background: transparent;
  position: relative;
}
#admin-layout .search-filters .search .ant-select-selection .ant-select-selection__clear {
  top: 13px;
}
#admin-layout .search-filters .search .anticon {
  font-size: 18px;
  color: #777e9b;
}
#admin-layout .search-filters .search .ant-select-selection__rendered {
  font-size: 17px;
}
#admin-layout .search-filters .search input {
  background: transparent;
  border: none;
  font-family: 'TruenoLight';
  color: #777e9b;
  font-size: 17px;
  padding-left: 14;
  width: calc(100% - 20px);
}
#admin-layout .ant-table .ant-table-thead:before,
#admin-layout .ant-table .ant-table-thead:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 28px;
  top: 0px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
#admin-layout .ant-table .ant-table-thead:before {
  left: -100%;
}
#admin-layout .ant-table .ant-table-thead:after {
  right: -100%;
}
#admin-layout .ant-table .ant-table-thead th {
  border-top: solid 1px #e8e8e8;
  text-transform: uppercase;
  padding: 8px 0;
  font-family: 'TruenoSemiBold';
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #5b6572;
  background: #fff;
  position: relative;
  text-align: center;
}
#admin-layout .ant-table .ant-table-thead th.username {
  text-align: left;
}
#admin-layout .ant-table .ant-table-tbody td {
  padding: 0px;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background: #fff;
  position: relative;
}
#admin-layout .ant-table .ant-table-tbody td:first-child:before {
  content: '';
  position: absolute;
  width: 1000px;
  height: 100%;
  top: 1px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  left: -1000px;
}
#admin-layout .ant-table .ant-table-tbody td:last-child:after {
  content: '';
  position: absolute;
  width: 1000px;
  height: 100%;
  top: 1px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  right: -1000px;
}
#admin-influencer-page {
  background: #fff;
}
#admin-influencer-page .influencers-stats {
  margin-top: 28px;
  margin-bottom: 24px;
}
#admin-influencer-page .influencers-stats .stats {
  color: #000;
  background: #fff;
  border-radius: 2px;
  border: solid 1px #a9b0b9;
  background-color: #ffffff;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 1.48;
  height: 100%;
}
#admin-influencer-page .influencers-stats .stats .stat-title {
  font-family: 'TruenoBold', Arial, sans-serif;
  text-transform: uppercase;
}
#admin-influencer-page .influencers-stats .stats .stat-count {
  font-family: 'TruenoBold', Arial, sans-serif;
}
#admin-influencer-page .influencers-stats .stats .stat-label {
  font-size: 11px;
}
#admin-influencer-page .search-filters {
  background: #fff;
}
#admin-influencer-page .search-filters:before,
#admin-influencer-page .search-filters:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 63px;
  top: 0px;
  background: #fff;
  z-index: 1;
  overflow: hidden;
}
#admin-influencer-page .search-filters:before {
  left: -100%;
}
#admin-influencer-page .search-filters:after {
  right: -100%;
}
#admin-influencer-page .search-filters button.download-csv {
  width: 122px;
  padding-top: 6px;
  padding-bottom: 9px;
  color: #5b6572;
  text-align: left;
  border-radius: 2px;
  border: solid 1px #dbdfe8;
  background-color: #f8faff;
}
#admin-influencer-page .search-filters button.download-csv .anticon {
  font-size: 16px;
}
#admin-influencer-page .search-filters button.download-csv span {
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-family: 'FirmeBlackItalic';
  font-size: 9px;
  font-style: italic;
  line-height: 1.11;
  letter-spacing: 1px;
}
#admin-influencer-page .ant-table td {
  text-align: right;
}
#admin-influencer-page .ant-table td.username {
  padding: 22px 0 25px;
}
#admin-influencer-page .ant-table td.last-login-date {
  width: 100px;
  padding-right: 25px;
  color: #5b6572;
}
#admin-influencer-page .ant-table td.joined {
  width: calc(34px + 43px);
  color: #000000;
  padding-right: calc(43px / 2);
}
#admin-influencer-page .ant-table td.rating {
  width: calc(34px + (43px + 34px) / 2);
  padding-right: calc((43px + 34px) / 4);
}
#admin-influencer-page .ant-table td.followers {
  width: calc(56px + (34px + 33px) / 2);
  padding-right: calc((34px + 33px) / 4);
}
#admin-influencer-page .ant-table td.apps {
  width: calc(27px + (33px + 30px) / 2);
  padding-right: calc((33px + 30px) / 4);
}
#admin-influencer-page .ant-table td.last-applied-date {
  width: calc(70px + (30px + 29px) / 2);
  padding-right: calc((30px + 29px) / 4);
}
#admin-influencer-page .ant-table td.active {
  width: calc(36px + (29px + 34px) / 2);
  padding-right: calc((29px + 34px) / 4);
}
#admin-influencer-page .ant-table td.complete {
  width: calc(49px + (34px + 38px) / 2);
  padding-right: calc((34px + 38px) / 4);
}
#admin-influencer-page .ant-table td.level {
  text-align: center;
}
#admin-influencer-page .ant-table td.level img {
  width: 34px;
  height: 47px;
}
#admin-influencer-page .ant-table td .active-partnerships,
#admin-influencer-page .ant-table td .completed-partnerships {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: 2;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
}
#admin-influencer-page .ant-table td .active-partnerships {
  border: solid 1px #a9b0b9;
  cursor: pointer;
  background-color: #ffffff;
}
#admin-influencer-page .ant-table td .completed-partnerships {
  border: solid 1px #a9b0b9;
  cursor: pointer;
  background-color: #ffffff;
}
.admin-influencer-active-campaigns {
  width: 197px;
  font-family: 'TruenoRegular';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  background: #000;
  padding: 10px 18px 17px 14px;
  border-radius: 2px;
  color: #fff;
}
.admin-influencer-active-campaigns p {
  margin-bottom: 10px;
  cursor: pointer;
}
.admin-influencer-active-campaigns p:hover {
  color: #ffb917;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  color: #000 !important;
  border-top-color: #000 !important;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #000 !important;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  border-right-color: #000 !important;
  border-bottom-color: #000 !important;
}
.reminder-stats small {
  display: block;
}
#payouts-page .ant-table .ant-table-thead th {
  text-align: left;
}
#payouts-page .ant-table .ant-table-row td {
  height: 50px;
}
#payouts-page .partnership-details {
  padding: 15px 10px 16px;
}
#payouts-page .partnership-details .ant-descriptions-item-content {
  padding-left: 15px !important;
}
#payouts-page .partnership-header {
  margin: 10px 0 10px;
}
.success-purchase {
  color: #fff;
}
.success-purchase .logo {
  margin-top: 38.37px;
}
.success-purchase .header {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 40px;
  line-height: 46px;
  margin-top: 225px;
  text-align: center;
}
.success-purchase .description {
  font-family: SFProDisplay;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-top: 14px;
  text-align: center;
}
.success-purchase .btn-section {
  margin-top: 43px;
}
.success-purchase .btn-section .start-new-campaign-btn.ant-btn,
.success-purchase .btn-section .accept-creators-btn.ant-btn {
  color: #000;
  border-radius: 25px;
  height: 50px;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;
  font-style: italic;
  padding: 10px 0;
  width: 358px;
  font-family: SFProDisplay;
  font-weight: normal;
}
.success-purchase .btn-section .start-new-campaign-btn.ant-btn span,
.success-purchase .btn-section .accept-creators-btn.ant-btn span {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 800;
  display: inline;
}
.success-purchase .btn-section .accept-creators-btn {
  margin-top: 19px;
  margin-bottom: 50px;
}
.confetti-particles {
  position: fixed;
  top: 0;
}
.confetti-particles .particle {
  position: absolute;
  transition: all 10s ease-out;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.ml-7 {
  margin-left: 35px !important;
}
.mr-7 {
  margin-right: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-8 {
  margin-left: 40px !important;
}
.mr-8 {
  margin-right: 40px !important;
}
.mt-9 {
  margin-top: 45px !important;
}
.mb-9 {
  margin-bottom: 45px !important;
}
.ml-9 {
  margin-left: 45px !important;
}
.mr-9 {
  margin-right: 45px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.pt-7 {
  padding-top: 35px !important;
}
.pb-7 {
  padding-bottom: 35px !important;
}
.pl-7 {
  padding-left: 35px !important;
}
.pr-7 {
  padding-right: 35px !important;
}
.pt-8 {
  padding-top: 40px !important;
}
.pb-8 {
  padding-bottom: 40px !important;
}
.pl-8 {
  padding-left: 40px !important;
}
.pr-8 {
  padding-right: 40px !important;
}
.content-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.logo-mobile {
  display: none;
}
.logo-wrapper {
  padding: 20px 0px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}
.brand-image .ant-avatar-image,
.brand-image .ant-avatar {
  border-radius: 0;
  background-color: #000;
  width: 100%;
  height: 200px;
}
.min-layout-content {
  width: 100%;
  padding-left: 39px;
  padding-right: 39px;
}
.d-block {
  display: block;
}
.d-iblock {
  display: inline-block;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.font-face {
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLight';
  src: url('../../webfonts/Trueno/TruenoLt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoLightItalic';
  src: url('../../webfonts/Trueno/TruenoLtIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegular';
  src: url('../../webfonts/Trueno/TruenoRg.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoRegularItalic';
  src: url('../../webfonts/Trueno/TruenoRgIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBold';
  src: url('../../webfonts/Trueno/TruenoBd.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoBoldItalic';
  src: url('../../webfonts/Trueno/TruenoBdIt.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoSemiBold';
  src: url('../../webfonts/Trueno/TruenoSBd.otf') format('opentype');
}
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'TruenoExtraBold';
  src: url('../../webfonts/Trueno/TruenoExBd.otf') format('opentype');
}
@font-face {
  font-family: 'FirmeBlackItalic';
  src: url('../../webfonts/firme-blackitalic-webfont.woff2') format('woff2'), url('../../webfonts/firme-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FirmeMedium';
  src: url('../../webfonts/firme-medium-webfont.woff2') format('woff2'), url('../../webfonts/firme-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Black.ttf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Bold.ttf') format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Heavy-1.ttf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-HeavyItalic-1.ttf') format('opentype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Light-1.ttf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-Medium.ttf') format('opentype');
  font-weight: normal;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-MediumItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-BoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SFProDisplay;
  src: url('../../webfonts/SF-Pro/FontsFree-Net-SFProDisplay-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
.type-light {
  font-family: 'TruenoLight', Arial, sans-serif;
}
.type-ilight {
  font-family: 'TruenoLightItalic', Arial, sans-serif;
}
.type-regular {
  font-family: 'TruenoRegular', Arial, sans-serif;
}
.type-bold {
  font-family: 'TruenoBold', Arial, sans-serif;
}
.type-sbold {
  font-family: 'TruenoSemiBold', Arial, sans-serif;
}
.type-ebold {
  font-family: 'TruenoExtraBold', Arial, sans-serif;
}
.type-firme-iblack {
  font-family: 'FirmeBlackItalic', Arial, sans-serif !important;
}
.type-firme-medium {
  font-family: 'FirmeMedium', Arial, sans-serif !important;
}
.type-sfpro-regular {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-medium {
  font-family: SFProDisplay;
  font-weight: normal;
}
.type-sfpro-iregular {
  font-family: SFProDisplay;
  font-weight: normal;
  font-style: italic;
}
.type-sfpro-bold {
  font-family: SFProDisplay;
  font-weight: bold;
}
.type-sfpro-light {
  font-family: SFProDisplay;
  font-weight: 300;
}
.type-sfpro-heavy {
  font-family: SFProDisplay;
  font-weight: 800;
}
.type-sfpro-iheavy {
  font-family: SFProDisplay;
  font-weight: 800;
  font-style: italic;
}
.type-sfpro-thin {
  font-family: SFProDisplay;
  font-weight: 100;
}
.type-sfpro-ibold {
  font-family: SFProDisplay;
  font-weight: bold;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SFProDisplay !important;
  font-weight: 800 !important;
}
body {
  font-family: 'TruenoRegular', Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
}
.text-primary {
  color: #ffb917;
}
.size-8px {
  font-size: 8px !important;
}
.size-9px {
  font-size: 9px !important;
}
.size-10px {
  font-size: 10px !important;
}
.size-11px {
  font-size: 11px !important;
}
.size-12px {
  font-size: 12px !important;
}
.size-13px {
  font-size: 13px !important;
}
.size-14px {
  font-size: 14px !important;
}
.size-15px {
  font-size: 15px !important;
}
.size-16px {
  font-size: 16px !important;
}
.size-17px {
  font-size: 17px !important;
}
.size-18px {
  font-size: 18px !important;
}
.size-20px {
  font-size: 20px !important;
}
.size-40px {
  font-size: 40px !important;
}
.size-21px {
  font-size: 21px !important;
}
.size-22px {
  font-size: 22px !important;
}
.size-24px {
  font-size: 24px !important;
}
.size-25px {
  font-size: 25px !important;
}
.size-28px {
  font-size: 28px !important;
}
.size-31px {
  font-size: 31px !important;
}
.size-32px {
  font-size: 32px !important;
}
.size-34px {
  font-size: 34px !important;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.color-yellow-font {
  color: #FFCB6F;
}
.most-popular {
  background: -webkit-linear-gradient(right, #f5cf79, #f7f7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ant-table-header-column {
  display: block !important;
  text-align: -webkit-center !important;
}
.spacing-05px {
  letter-spacing: 0.5px;
}
.spacing-1px {
  letter-spacing: 1px;
}
.leading-18px {
  line-height: 18px;
}
.leading-24px {
  line-height: 24px;
}
.leading-1 {
  line-height: 1;
}
.leading-23 {
  line-height: 2.33;
}
.uppercase {
  text-transform: uppercase;
}
.clickable {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.label-value {
  font-family: 'truenoextrabold';
  color: #000000;
  font-size: 12px;
}
.text-black {
  color: #000000;
}
.text-black-bis {
  color: #151515;
}
.text-black-ter {
  color: #3A3A3A;
}
.text-gray-darkest {
  color: #747474;
}
.text-gray-darker {
  color: #969696;
}
.text-gray-dark {
  color: #999999;
}
.text-gray {
  color: #A6A6A6;
}
.text-gray-light {
  color: #D8D8D8;
}
.text-gray-lighter {
  color: #D6D6D6;
}
.text-gray-lightest {
  color: #E8E8E8;
}
.text-white {
  color: #FFFFFF;
}
.text-orange {
  color: #FFB917;
}
.text-pigeon-dark {
  color: #5B6572;
}
.text-pigeon-light {
  color: #A9B0B9;
}
.text-pigeon {
  color: #DBDFE8;
}
.bg-black {
  background-color: #000000;
}
.bg-black-bis {
  background-color: #151515;
}
.bg-black-ter {
  background-color: #3A3A3A;
}
.bg-gray-darkest {
  background-color: #747474 !important;
}
.bg-gray-darker {
  background-color: #969696;
}
.bg-gray-dark {
  background-color: #999999;
}
.bg-gray {
  background-color: #A6A6A6;
}
.bg-gray-light {
  background-color: #D8D8D8;
}
.bg-gray-lighter {
  background-color: #D6D6D6;
}
.bg-gray-lightest {
  background-color: #E8E8E8;
}
.bg-white {
  background-color: #FFFFFF;
}
.bg-orange {
  background-color: #FFB917;
}
.bg-black {
  background-color: #000000;
}
.bg-white {
  background-color: #FFFFFF !important;
}
body {
  background-color: #000000 !important;
}
.ant-layout {
  background: #fff !important;
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .min-layout-content {
    width: 1080px;
  }
  .small {
    width: 580px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 15px;
  }
  .small {
    width: 580px;
  }
}
@media (max-width: 992px) {
  .logo {
    display: none;
  }
  .logo-mobile {
    display: inline-block;
  }
  .hidden-mobile {
    display: none !important;
  }
  .no-padding-mobile {
    padding: 0 !important;
  }
  .trend-menu-horizontal.ant-menu-horizontal .ant-menu-item {
    margin-right: 25px;
  }
  .campaign-menu,
  .trend-menu-inline {
    text-align: center;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: 0 !important;
  }
  .ant-menu-inline.secondary .ant-menu-item svg,
  .ant-menu-inline.settings svg {
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .content-credit-text {
    padding: 10px;
  }
  .side-small-title {
    font-size: 6px !important;
  }
  .credit-title {
    font-family: 'TruenoRegular', Arial, sans-serif;
    display: block;
    font-size: 8px;
    font-weight: 600;
    padding-top: 8px;
    font-size: 5px !important;
    line-height: 1.4;
  }
  .credit-title-block {
    line-height: 0.7;
  }
  .plan-title {
    font-size: 8px !important;
  }
  .package-title {
    margin: 0 !important;
    font-size: 12px !important;
  }
}
@media (max-width: 768px) {
  .hidden-xmobile {
    display: none !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .min-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-padding {
    padding: 5px;
  }
  .small {
    width: 100%;
  }
}
.ant-layout-sider .brand-image {
  height: 182px;
  background: #2f2f2f;
}
.ant-layout-sider .brand-image .ant-avatar-image {
  height: 100%;
}
.side-menu .menu-title {
  font-family: SFProDisplay;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.004em;
  color: #fff;
}
.side-menu .campaign-link {
  font-family: SFProDisplay;
  font-weight: normal;
  font-weight: 600;
}
#admin-layout .ant-layout-content {
  background: #f6f7fb;
}
#admin-layout .page-header {
  width: 100%;
  padding-top: 47px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
}
#admin-layout .page-header h1 {
  font-size: 26px;
  font-family: 'TruenoExtraBold';
}
#admin-layout .page-header .ant-menu.ant-menu-horizontal {
  border-bottom: 0px;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-selected svg .stroke {
  fill: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-selected svg g[fill-rule='evenodd'] {
  stroke: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active svg .stroke {
  fill: #fff;
}
.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active svg g[fill-rule='evenodd'] {
  stroke: #fff;
}
